<!--
@author: binchen
@desc：修改身份信息
@date: 2023-04-29 12:17:35
-->
<template>
  <a-modal title="修改身份信息" width="808px" :visible="visible" :confirm-loading="confirmLoading" @ok="handleOk" @cancel="handleCancel">
    <a-form :form="form" :label-col="{ span: 3 }" :wrapper-col="{ span: 21 }" labelAlign="left">
      <a-form-item label="身份信息" class="">
        <a-input placeholder="请输入" v-model="form.idAuthenticationParam.identityName" />
      </a-form-item>
      <a-form-item :colon="false">
        <div slot="label">
          <div>身份证明材料：</div>
          <div style="text-align: center; font-size: 12px;font-weight:normal">(可上传6张)</div>
        </div>
        <Upload :max="6" :showBtn="$refs.Upload && $refs.Upload.fileList.length< 6" class="shopEnvironment_modal" width="100%" height="100%" ref="Upload" name="file" :isMore="true" :multiple="true" :showUploadList="true" @success="uploadSuccess">
          <div slot="content">点击上传图片</div>
        </Upload>
      </a-form-item>
    </a-form>
    <div class="foot-tip">提交身份信息和身份证明的材料，会在介绍页好看鸭认证第一行展示您的身份</div>
  </a-modal>
</template>

<script>
import Upload from '@/components/Upload'
export default {
  name: 'IdentityInformationModal',
  components: { Upload },
  data() {
    return {
      visible: false,
      confirmLoading: false,
      form: {
        homepageInformation: null,
        shopEnvironment: null,
        shopHistoryInformation: null,
        brandInformation: null,
        shopAddressInformation: null,
        idAuthenticationParam: {
          identityName: '',
          idPics: [],
        },
        realAuthentication: null,
        backgroundPicInformation: null,
        experience: null,
        introductionInformation: null,
      },
    }
  },
  
  created() {},
  mounted() {},
  methods: {
    onShow(form) {
      if (form.idAuthenticationParam.auditState === 0) {
        return this.$message.info('信息审核中耐心等待')
      }
      this.form.idAuthenticationParam.identityName = form.idAuthenticationParam.identityName
      this.form.idAuthenticationParam.idPics = form.idAuthenticationParam.idPics || []
      this.visible = true
      this.$nextTick(() => {
        this.$refs.Upload.fileList =  this.form.idAuthenticationParam.idPics.map((item) => {
          return {
            uid: Math.random(),
            name: item.split('.')[1],
            status: 'done',
            url: this.ossUrl(item) ,
          }
        })
        console.log(this.$refs.Upload.fileList)
      })
    },
    handleOk() {
      this.form.idAuthenticationParam.idPics = this.$refs.Upload.fileList.map((item) => {
        return item.url.replace('/oss/', '')
      })
      this.confirmLoading = true
      this.$emit('submit', this.form, () => {
        this.confirmLoading = false
        this.visible = false
      })
    },
    handleCancel() {
      Object.assign(this.$data, this.$options.data.call(this))
      this.visible = false
    },
    uploadSuccess(e) {
      this.$refs.Upload.fileList.push({
        uid: Math.random(),
        name: e.split('.')[1],
        status: 'done',
        url: this.ossUrl(e),
      })
    },
  },
}
</script>

<style>
.shopEnvironment_modal .ant-upload-list-picture-card .ant-upload-list-item,
.shopEnvironment_modal .ant-upload-list-picture-card-container {
  width: 177px;
  height: 169px;
}
.shopEnvironment_modal .ant-upload.ant-upload-select-picture-card {
  width: 177px;
  height: 169px;
}
.foot-tip {
  text-align: center;
}
</style>
