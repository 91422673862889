<!-- //
店铺信息
@auth xuyd
@date 2022-09-10
//-->
<template>
  <div class="layout-vertical">
    <PageHeader></PageHeader>
    <ShopInfo class="p24" />

  </div>
</template> 
<script>
  import ShopInfo from "./shop-info";
  // import ShopAuth from "./shop-auth";

  export default {
    name: 'ShopInfoMgt',
    components: {ShopInfo},
    data() {
      return {
        activeTabKey: 'shopInfo',
      }
    },
    methods: {
      /**
       * 切换tab
       */
      handleChange(val) {
        this.activeTabKey = val
      },
    }
  }
</script>

<style lang="scss" scoped>
</style>
