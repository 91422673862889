<!-- //
表单项-直接修改空间
@auth xuyd
@date 2022-11-21
//-->
<template>
  <a-row :gutter="20" :span="24" class="form-row">
    <a-col class="label" :span="8" align="left" style="width: 100px">
      <span>{{title}}</span>
    </a-col>
    <a-col class="value" :span="10" align="left" style="width: 300px">
      <div v-if="isModify">
        <a-input v-if="type === 'input'" v-model="modifyValue"></a-input>
        <a-date-picker  v-else-if="type === 'date'" v-model="modifyValue" :locale="locale"
                        format="YYYY-MM-DD" valueFormat="YYYY-MM-DD"
                        style="width: 280px"
        />
        <a-range-picker v-else-if="type === 'dateRange'" v-model="modifyValue" :locale="locale"
                        :placeholder="['请选择','请选择']" format="YYYY-MM-DD" valueFormat="YYYY-MM-DD"/>
        <a-select v-else-if="type === 'select'" v-model="modifyValue">
          <a-select-option v-for="(item,index) in dictList" :value="item.value" :key="index">
            {{item.label}}
          </a-select-option>
        </a-select>
      </div>
      <div v-else>
        <dict-tag v-if="type === 'select'" :data="dictList" :options="{}" :value="detailValue"></dict-tag>
        <span v-else>{{detailValue}}</span>
      </div>
    </a-col>
    <a-col class="action" :span="6" align="left">
      <a-button type="link" v-if="isCancel || isConfirm" @click="handleModify()">修改</a-button>
      <a-button type="link" v-if="isModify" @click="handleCancel('cancel')">取消</a-button>
      <a-button type="link" v-if="isModify" @click="handleOk()">确认</a-button>
    </a-col>
  </a-row>
</template>

<script>
  import locale from 'ant-design-vue/es/date-picker/locale/zh_CN';

  export default {
    name: 'FormEmbedItem',
    components: {},
    dictTypes: [],
    data() {
      return {
        locale,
        modifyValue: this.value,
        command: 'confirm',
      }
    },
    model: {
      value: 'value',
      event: 'change',
    },
    props: {
      title: String,
      detailValue: {
        type: [String, Number],
      },
      value: {
        type: [String, Number],
      },
      type: {
        type: String,
        default: 'input',
      },
      dictList: {
        type: Array,
      },
    },
    computed: {
      isModify() {
        return this.command === 'modify';
      },
      isCancel() {
        return this.command === 'cancel';
      },
      isConfirm() {
        return this.command === 'confirm';
      },
    },
    watch: {
      value(val) {
        this.modifyValue = val
      },
      modifyValue(val) {
        this.$emit('change', val)
      },
    },
    methods: {
      handleModify() {
        this.modifyValue = null;
        this.command = 'modify';
      },
      handleCancel() {
        this.command = 'cancel';
        this.modifyValue = null;
      },
      handleOk() {
        if(this.$util.isEmpty(this.modifyValue)) return;
        this.command = 'confirm';
        this.$emit('handleOk')
      },
    }
  }
</script>
<style lang="scss" scoped>
  .form-row {
    margin-bottom: 20px;
  }

  .label {
    font-size: 14px;
    font-weight: 600;
  }

  .value {
    font-size: 14px;
    font-weight: 400;
  }
</style>
