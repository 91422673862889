<!--
@author: binchen
@desc：修改品牌故事
@date: 2023-04-29 12:17:35
-->
<template>
  <a-modal title="修改品牌故事信息" width="808px" :visible="visible" :confirm-loading="confirmLoading" @ok="handleOk" @cancel="handleCancel">
    <a-form :form="form" :label-col="{ span: 2 }" :wrapper-col="{ span: 22 }" labelAlign="left">
      <a-form-item label="品牌故事">
        <!-- <Editor v-model="form.shopHistoryInformation.shopHistory"></Editor> -->
        <div class="inputBox">
          <a-textarea class="textarea" v-model="form.shopHistoryInformation.shopHistory" placeholder="请输入" :rows="20" :maxLength="1000" />
          <div class="suffix">{{ form.shopHistoryInformation.shopHistory.length }} / 1000</div>
        </div>
      </a-form-item>
    </a-form>
  </a-modal>
</template>

<script>
// import Editor from '@/components/Editor/index'
export default {
  name: 'BrandStoryModal',
  props: {
    allowAuditState: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      visible: false,
      confirmLoading: false,
      form: {
        homepageInformation: null,
        shopEnvironment: null,
        shopHistoryInformation: {
          shopHistory: '',
        },
        brandInformation: null,
        shopAddressInformation: null,
        idAuthenticationParam: null,
        realAuthentication: null,
        backgroundPicInformation: null,
        experience: null,
        introductionInformation: null,
      },
    }
  },
  created() {},
  mounted() {},
  methods: {
    onShow(form) {
      if (form.shopHistoryInformation.auditState === 0 && this.allowAuditState) {
        return this.$message.info('信息审核中耐心等待')
      }
      if (form.shopHistoryInformation.shopHistory.length > 1000) {
        this.form.shopHistoryInformation.shopHistory = form.shopHistoryInformation.shopHistory.substring(0, 1000)
      } else {
        this.form.shopHistoryInformation.shopHistory = form.shopHistoryInformation.shopHistory || ''
      }
      this.visible = true
    },
    handleOk() {
      this.confirmLoading = true
      if (this.form.shopHistoryInformation.shopHistory.length > 1000) {
        return this.$message.info('品牌故事长度不能超过1000字！')
      }
      this.$emit('submit', this.form, () => {
        this.confirmLoading = false
        this.visible = false
      })
    },
    handleCancel() {
      Object.assign(this.$data, this.$options.data.call(this))
      this.visible = false
    },
  },
}
</script>
<style lang="scss" scoped>
.inputBox {
  position: relative;
  .textarea {
    padding-bottom: 20px;
  }
  .suffix {
    position: absolute;
    bottom: 5px;
    right: 15px;
    background-color: #ffffff;
  }
}
</style>
