<template>
  <a-modal v-model="visible" width="1100px" :keyboard="false" :footer="null" :maskClosable="false" :destroyOnClose="true">
    <div slot="title" class="modal-title">
      <div class="title">{{ type === 2 ? '编辑成员' : '新增成员' }}</div>
      <!-- <div class="hint">{{type===2?'':'确认员工账号'}}</div> -->
    </div>

    <a-spin wrapper-class-name="global-spin" :spinning="loading">
      <div class="dlg-platform-container">
        <a-table :pagination="false" :columns="columns" :data-source="list" :rowKey="(record) => record.userId">
          <span slot="avatar" slot-scope="text, record">
            <a-avatar slot="avatar" size="large" shape="square" :src="convertToPrefixPath(record.headImage)" style="width: 60px; height: 60px" />
          </span>
          <template slot="gender" slot-scope="text">
            <span>{{ dict.label.GENDER[text] }}</span>
          </template>
          <span slot="userTabs" slot-scope="text">
            {{ $util.toArray(text).join('、') }}
           
          </span>
          <span slot="createTime" slot-scope="text">
            <span class="table-normal">{{ $util.parseTime(text, '{y}-{m}-{d} {h}:{i}') }}</span>
          </span>
        </a-table>

        <!-- 表单 -->
        <a-form-model ref="refForm" :model="form" :rules="rules" layout="inline" :colon="false">
          <a-row class="form-row">
            <a-form-model-item label="姓名" prop="employeesName" style="height: 60px">
              <a-input v-model="form.employeesName" style="width: 250px" placeholder="请输入"></a-input>
            </a-form-model-item>
            <a-form-model-item label="岗位" prop="positionId">
              <a-select v-model="form.positionId" style="width: 250px" placeholder="请选择" allowClear>
                <a-select-option v-for="(item, index) in postList" :value="item.positionId" :key="index">
                  {{ item.positionName }}
                </a-select-option>
              </a-select>
            </a-form-model-item>
            <!-- <a-form-model-item label="选择平台" prop="businessUserIds" placeholder="请选择">
              <a-select v-model="form.businessUserIds" mode="multiple" :maxTagCount="1" allowClear
                        style="width: 250px;">
                <a-select-option v-for="(item, index) in businessList" :value="item.businessUserId" :key="index">
                  {{item.shopName}}
                </a-select-option>
              </a-select>
            </a-form-model-item> -->
          </a-row>
           <a-row class="form-row">
            <a-form-model-item label="座机" style="height: 60px">
              <a-input v-model="form.contactsLandline" style="width: 250px" placeholder="请输入"></a-input>
            </a-form-model-item>
            <a-form-model-item label="电话" >
              <a-input v-model="form.contactsPhone" style="width: 250px" placeholder="请输入"></a-input>
            </a-form-model-item>
          </a-row>
           <a-row class="form-row">
            <a-form-model-item label="微信"  style="height: 60px">
              <a-input v-model="form.wechatId" style="width: 250px" placeholder="请输入"></a-input>
            </a-form-model-item>
         
          </a-row>
        </a-form-model>
        <a-row :span="24" class="button-item" style="height: 50px">
          <a-button class="button2" style="margin-right: 20px" @click="handleCancel">取消</a-button>
          <a-button type="primary" class="button" @click="handleConfirm">确认</a-button>
        </a-row>
      </div>
    </a-spin>
  </a-modal>
</template>

<script>
import * as EmployeeApi from '@/api/shop/employee.api'
import * as PostApi from '@/api/shop/post.api'
import { mapGetters } from 'vuex'

const columns = [
  { title: '头像', dataIndex: 'headImage', width: 87, align: 'center', scopedSlots: { customRender: 'avatar' } },
  { title: '账号昵称', dataIndex: 'nickName', width: 90, align: 'center' },
  { title: '联系方式', dataIndex: 'phone', width: 110, align: 'center' },
  { title: '性别', dataIndex: 'sex', width: 90, align: 'center', scopedSlots: { customRender: 'gender' } },
  { title: 'Ta的兴趣', dataIndex: 'userTabs', width: 180, align: 'center', scopedSlots: { customRender: 'userTabs' } },
  // { title: '发图文', dataIndex: 'issueImageNum', width: 80, align: 'center' },
  // { title: '发视频', dataIndex: 'issueVideoNum', width: 80, align: 'center' },
  // { title: '发文章', dataIndex: 'issueArticleNum', width: 80, align: 'center' },
  // { title: '发作品', dataIndex: 'issueWorksNum', width: 80, align: 'center' },
  // {
  //   title: '创建时间',
  //   dataIndex: 'createdTime',
  //   width: 150,
  //   align: 'center',
  //   scopedSlots: { customRender: 'createTime' },
  //   sorter: (a, b) => {
  //     let ta = (a.createdTime && new Date(a.createdTime).getTime()) || 0
  //     let tb = (b.createdTime && new Date(b.createdTime).getTime()) || 0
  //     let ret = ta - tb
  //     return ret
  //   },
  // },
]

export default {
  name: 'EmployeeAddComp',
  dictTypes: [
    { dictType: 'GENDER', dataType: Number },
    { dictType: 'EmployeeStatus', dataType: Number },
  ],
  data() {
    return {
      labelCol: { span: 4 },
      wrapperCol: { span: 14 },

      visible: false,
      loading: false,
      columns: columns,
      postList: [],
      list: null,
      form: {
        employeesUserId: null,
        employeesName: null,
        positionId: null,
        wechatId:null,
        contactsPhone:null,
        contactsLandline:null,
        // businessUserIds: [],
      },
      rules: {
        employeesName: [{ required: true, message: '员工名称必须输入', trigger: 'blur' }],
        positionId: [{ required: true, message: '岗位必须选择', trigger: 'blur' }],
        // businessUserIds: [
        //   {required: true, message: '至少选择一个平台', trigger: 'blur'}
        // ],
      },
      type: 1,
      employeeForm: {},
    }
  },

  computed: {
    ...mapGetters(['businessList']),
  },
  created() {
    this.getInit()
  },
  methods: {
    async showModal(data, type) {
      this.type = type
      console.log('lsssssssssssss', data, type)
      if (type === 2) {
        this.employeeForm = data
        // this.form.employeesName = data.employeesName
        // this.form.wechatId = data.wechatId
        // this.form.contactsPhone = data.contactsPhone
        // this.form.contactsLandline = data.contactsLandline
        await this.getDetail(data.userId)
      }
      this.form.employeesUserId = data.userId
      this.list = [data]
      this.visible = true
    },
    getDetail(userId) {
      EmployeeApi.get(userId).then((data) => {
         this.form.employeesName = data.employeesName
        this.form.wechatId = data.wechatId
        this.form.contactsPhone = data.contactsPhone
        this.form.contactsLandline = data.contactsLandline
        this.form.positionId = data.positionId 
        this.form.employeesId = data.employeesId
        console.log(this.form)
      })
    },

    hideModal() {
      this.visible = false
    },
    handleCancel() {
      this.visible = false
    },

    getInit() {
      PostApi.selectPostList().then((data) => {
        this.postList = data?.list || []
      })
    },

    handleConfirm() {
      this.$refs['refForm'].validate((valid) => {
        if (valid) {
          // console.log("=====>", this.form)
          if (this.loading) return
          this.loading = true
          if (this.type === 2) {
            const form = {
              id: this.employeeForm.employeesId,
              employeesUserId: this.form.employeesUserId,
              employeesName: this.form.employeesName,
              positionId: this.form.positionId,
              contactsPhone: this.form.contactsPhone,
              contactsLandline: this.form.contactsLandline,
              wechatId: this.form.wechatId,
            }
            EmployeeApi.update(form).then((data) => {
              this.loading = false
              this.$modal.msg('编辑成功')
              this.$emit('handleOk', data)
              this.visible = false
            })
          } else {
            EmployeeApi.add(this.form)
              .then((data) => {
                this.loading = false
                this.$modal.msg('新增员工成功')
                this.$emit('handleOk', data)
                this.visible = false
              })
              .catch((err) => {
                this.loading = false
              })
          }
        }
      })
    },
  },
}
</script>

<style scoped lang="scss">
.modal-title {
  display: flex;

  .title {
    font-weight: 600;
    font-size: 14px;
  }

  .hint {
    margin-left: 10px;
    font-weight: 400;
    font-size: 12px;
    color: #0000006d;
  }
}

.dlg-platform-container {
  height: 100%;
  padding: 10px 15px;
  overflow: auto;

  .form-row {
    margin-top: 30px;
  }

  .button-item {
    width: 100%;
    /*position: absolute;*/
    bottom: 10px;
    margin-top: 50px;
    text-align: center;

    .button {
      font-size: 16px;
      width: 250px;
      height: 40px;
      line-height: 40px;
    }

    .button2 {
      font-size: 16px;
      width: 100px;
      height: 40px;
      line-height: 40px;
    }
  }
}
</style>
