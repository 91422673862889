<template>
  <div class="material-publishWorks-container">
    <production-publish ref="productionPublish" :content-type="3" :showConfirm="false">
      <PageHeader v-if="businessType === 5" slot="pageHeader" :show-title="false" :show-icon="false" :show-button="true">
        <div slot="content">
          <a-button type="primary" class="action" @click="handlePublish" :loading="loading">发布</a-button>
        </div>
      </PageHeader>
    </production-publish>
    <select-store ref="selectStore"></select-store>
  </div>
</template>
<script>
import ProductionPublish from '@/views/content/common/content-edit'
import SelectStore from '../store/selectStore.vue'
import { convertUploadToDb } from '@/utils/util'
import { mapGetters } from 'vuex'
export default {
  components: { ProductionPublish, SelectStore },
  data() {
    return {
      loading: false,
    }
  },
  computed: {
    ...mapGetters(['businessType']),
  },
  methods: {
    handlePublish() {
      this.$refs.productionPublish.$refs['refForm'].validate((valid) => {
        if (valid) {
          let obj = this.$refs.productionPublish.form
          let params = {
            content: obj.content,
            contentType: obj.contentType,
            coverUri: obj.coverUri,
            fileList: obj.uploadFileList.map((item) => convertUploadToDb(item)),
            productIds: obj.productIds,
            tabList: obj.tabList,
            trendStatus: 200,
            title: obj.title,
            storesType: obj.storesType,
          }
          if (obj.id) {
            params.id = obj.id
          }
          this.$refs.selectStore.onShow(params)
        }
      })
    },
  },
}
</script>
<style lang="scss" scoped>
.action {
  width: 96px;
  height: 40px;
  font-size: 16px;
}
</style>
