<template>
  <div class="HKImage">
    <div class="previewBox" v-if="src">
      <a-icon class="icon" type="zoom-in" @click="show= true" />
    </div>
    <img v-if="src" :src="src">
    <div v-else class="empty">
      {{ emptyText }}
    </div>
    <div class="previewZoom" v-if="src && show">
      <img :src="src">
      <a-icon class="closeIcon" type="close-circle" @click="show= false" />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    src: {
      type: String,
    },
    emptyText: {
      type: String,
      default: '图片不存在',
    },
  },
  data() {
    return {
      show: false,
    }
  },
  mounted() {},
  methods: {},
}
</script>

<style scoped lang="scss">
.HKImage {
  width: 247px;
  height: 180px;
  background: #e9e5e5;
  position: relative;
  text-align: center;
  @mixin common {
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.5s;
    background: #00000080;
  }

  .previewZoom {
    position: fixed;
    z-index: 9999;
    width: 100vw;
    height: 100vh;
    @include common;
    img {
      width: auto;
      height: auto;
      max-width: 100%;
      max-height: 100%;
    }
    .closeIcon {
      position: absolute;
      top: 50px;
      right: 50px;
      font-size: 30px;
      color: #fff;
      cursor: pointer;
    }
  }

  .previewBox {
    position: absolute;
    width: 100%;
    height: 100%;
    opacity: 0;
    @include common;

    .icon {
      color: #fff;
      font-size: 20px;
      cursor: pointer;
    }
  }

  img {
    width: 100%;
    height: 100%;
    max-width: 100%;
    max-height: 100%;
  }

  .empty {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.HKImage:hover {
  .previewBox {
    opacity: 1;
  }
}
</style>