<!-- //
文章作品编辑
@auth xuyd
@date 2022-11-01
//-->
<template>
  <div class="layout-vertical">
    <PageHeader :show-icon="showIcon"
                :show-button="true"
                :show-confirm="false"
                :button-list="buttonList"
                @publishClick="handleSave"
                @saveClick="handleCancel">
    </PageHeader>

    <div class="layout-result">
      <a-form-model ref="refForm" :model="form" :rules="rules" layout="vertical" class="layout-form p48">
        <a-form-model-item prop="title">
          <span slot="label" class="form-label">头像</span>
          <a-avatar size="large" shape="square" :src="convertToPrefixPath(form.headImage)" style="width: 86px;height: 86px"/>
        </a-form-model-item>

        <a-form-model-item>
          <span slot="label" class="form-label">账号昵称</span>
          <span>{{form.nickName}}</span>
        </a-form-model-item>

        <a-form-model-item>
          <span slot="label" class="form-label">联系方式</span>
          <span>{{form.phone}}</span>
        </a-form-model-item>

        <a-form-model-item>
          <span slot="label" class="form-label">性别</span>
          <span>{{dict.label.GENDER[form.sex]}}</span>
        </a-form-model-item>

        <a-form-model-item>
          <span slot="label" class="form-label">Ta的兴趣</span>
          <span>{{$util.toArray(form.userTabs).join("、")}}</span>
        </a-form-model-item>

        <a-form-model-item>
          <span slot="label" class="form-label">创建时间</span>
          <span>{{$util.parseTime(form.createdTime, '{y}-{m}-{d}')}}</span>
        </a-form-model-item>


        <a-form-model-item prop="employeesName">
          <span slot="label" class="form-label">员工姓名</span>
          <a-input v-model="form.employeesName" style="width: 250px;" placeholder="请输入"></a-input>
        </a-form-model-item>

        <a-form-model-item prop="positionId">
          <span slot="label" class="form-label">员工岗位</span>
          <a-select v-model="form.positionId" style="width: 250px;" placeholder="请选择">
            <a-select-option v-for="(item,index) in postList" :value="item.positionId" :key="index">
              {{item.positionName}}
            </a-select-option>
          </a-select>
        </a-form-model-item>

        <a-form-model-item prop="businessUserIds">
          <span slot="label" class="form-label">选择平台</span>
          <a-select v-model="form.businessUserIds" mode="multiple" :maxTagCount="1"
                    placeholder="请选择"
                    style="width: 250px;">
            <a-select-option v-for="(item, index) in businessList" :value="item.businessUserId" :key="index">
              {{item.shopName}}
            </a-select-option>
          </a-select>
        </a-form-model-item>

        <a-form-model-item prop="accountStatus">
          <span slot="label" class="form-label">员工状态</span>
          <a-radio-group v-model="form.accountStatus" style="width: 250px;">
            <a-radio v-for="(item, index) in dict.type.EmployeeStatus" :value="item.value" :key="index">
              {{item.label}}
            </a-radio>
          </a-radio-group>

        </a-form-model-item>


      </a-form-model>
    </div>
  </div>
</template>

<script>

  import * as EmployeeApi from '@/api/shop/employee.api'
  import * as PostApi from '@/api/shop/post.api'
  import {mapGetters} from "vuex";

  export default {
    name: 'EmployeeEdit',
    components: {},
    dictTypes: [
      {dictType: 'GENDER', dataType: Number},
      {dictType: 'EmployeeStatus', dataType: Number},
    ],
    data() {
      return {
        showIcon: false, // 从列表进来true，菜单进来false
        mode: null, // mode=[1(新增）|2（编辑）|3（详情）]
        buttonList: [
          {label: '确认', type: "primary"},
          {label: '取消', type: ""}
        ],
        postList: [],
        form: {
          userId: null,
          headImage: null,
          phone: null,
          nickName: null,
          sex: null,
          createdTime: null,
          userTabs: null,
          employeesName: null,
          positionId: [],
          businessUserIds: [],
          accountStatus: null, // 账号状态 1-启用 2-禁用
        },
        saveForm: {
          employeesUserId: null,
          employeesName: null,
          positionId: null,
          businessUserIds: null,
          accountStatus: null,
        },
        rules: {
          employeesName: [{required: true, message: '名称不能为空', trigger: 'blur'}],
          positionId: [{required: true, message: '岗位不能为空', trigger: 'change'}],
          businessUserIds: [{required: true, message: '平台不能为空', trigger: 'change'}],
          accountStatus: [{required: true, message: '员工状态不能为空', trigger: 'change'}],
        },
      };
    },
    props: {},
    computed: {
      isEditMode() {
        return this.mode == '2'
      },
      ...mapGetters([
        'businessList'
      ]),
    },
    created() {
      this.form.userId = this.$route.query.id
      this.mode = this.$route.query.mode
      this.showIcon = (!this.mode || this.mode == '1') ? false : true;
      this.getInit()
      this.getDetail()
    },
    methods: {
      getInit() {
        PostApi.selectPostList().then(data => {
          this.postList = data?.list
        })
      },

      getDetail() {
        EmployeeApi.get(this.form.userId).then(data => {
          // console.log("================>", data)
          let f = data || {};
          f.workShop = f.workShop || [];
          f.businessUserIds = f.workShop.map(item => item.businessUserId);
          this.form = f;
        })
      },

      handleSave() {
        this.$refs['refForm'].validate((valid) => {
          if (valid) {
            this.saveForm = {
              id: this.form.id,
              employeesUserId: this.form.userId,
              employeesName: this.form.employeesName,
              positionId: this.form.positionId ? this.form.positionId[0] : null,
              businessUserIds: this.form.businessUserIds,
              accountStatus: this.form.accountStatus,
            }
            EmployeeApi.update(this.saveForm).then(data => {
              this.$modal.msg("编辑成功")
              this.$router.replace({name: 'EmployeeList', query: {}})
            })
          }
        })
      },

      handleCancel() {
        this.$router.back()
      },
    }
  }
</script>
<style lang="scss" scoped>
  .form-label {
    font-size: 16px;
    font-weight: 600;
  }
</style>
