<!-- //
店铺管理-店铺认证
@auth xuyd
@date 2022-09-10
//-->
<template>
  <a-table class="shop-auth" :pagination='false' :columns="columns" :data-source="authList"
           :rowKey="(record) => record.type"
           :customRow="handleCustomRowCallback"
  >
    <span slot="type" slot-scope="text, record">
      <template>
        <span>{{dict.label.authType[record.type]}}</span>
      </template>
    </span>
    <span slot="state" slot-scope="text, record">
      <template>
        <dict-tag :data="dict.type.shopAuthState" :options="{}" :value="record.state"/>
      </template>
    </span>

    <!--  TODO 一期不做     -->
    <!-- <template slot="action" slot-scope="record">-->
<!--    <template slot="action">-->
      <!-- <a href="javascript:;" v-on:click="handleShopAuth({record})">{{actionName}}</a>-->
<!--    </template>-->
  </a-table>
</template>
<script>
  import * as ShopApi from '@/api/shop/shop.api'
  import {mapGetters} from "vuex";

  export default {
    name: 'ShopAuth',
    components: {},
    dictTypes: [
      {dictType: 'authType', dataType: String}, // 认证类型
      {dictType: 'shopAuthState', dataType: String}, // 身份认证状态
    ],
    data() {
      return {
        activeTabKey: 'shopInfo',

        columns: [
          {title: '认证名', dataIndex: 'type', width: 275, scopedSlots: {customRender: 'type'}},
          {title: '状态', dataIndex: 'state', width: 150, scopedSlots: {customRender: 'state'}},
          {title: '说明', dataIndex: 'desc', width: 500},
          // {title: '操作', dataIndex: 'action', scopedSlots: {customRender: 'action'}, width: 100,},
        ],
        authList: []
      };
    },
    computed: {
      ...mapGetters([
        'businessType',
      ]),
    },
    mounted() {
      this.getAuthList()
    },
    methods: {
      getAuthList() {
        ShopApi.getAuthList().then(data => {
          this.authList = data;
        })
      },

      /**
       * 店铺认证
       */
      handleShopAuth(object) {
        console.log(object)
        let {text, record, index} = object;
        this.$modal.msg("认证啦：" + record.authName)
      },
      handleCustomRowCallback(record) {
        console.log("record", record)
      },
    }
  }
</script>

<style lang="scss">
  .shop-auth{
    .ant-table-tbody > tr > td{
      padding: 30px 16px;
    }
  }
</style>
