<template>
  <div class="tag-container">
    <a-form-model ref="ruleForm" :model="form" :rules="rules"
                  :label-col="labelCol"
                  :wrapper-col="wrapperCol"
                  :colon="true">
      <a-form-model-item label="当前父级标签">
        <span>{{parentTab.label}}</span>
      </a-form-model-item>

      <a-form-model-item prop="inputVal" label="添加标签" >
        <a-input v-model="form.inputVal" class="input" placeholder="输入标签" :maxLength="20"></a-input>
      </a-form-model-item>

      <a-form-model-item :wrapper-col="{ span: 14, offset: 8 }">
        <a-button type="primary" @click="handleOk">确定</a-button>
      </a-form-model-item>
    </a-form-model>
  </div>
</template>
<script>
  export default {
    name: 'ModalInput',
    data() {
      return {
        labelCol: { span: 8 },
        wrapperCol: { span: 14},

        form: {},
        rules: {
          inputVal: [
            {required: true, message: '请输入标签内容', trigger: 'blur'},
          ],
        }
      };
    },
    props: {
      parentTab: {// 回传处理
        type: Object,
        required: true,
      },
    },
    methods: {
      handleOk() {
        this.$refs.ruleForm.validate(valid => {
          if (valid) {
            this.$emit("handleOk", {parentTab: this.parentTab, label: this.form.inputVal})
          } else {
            return false;
          }
        });
      }
    }
  };
</script>

<style scoped lang="scss">
  .tag-container{
    .label {
      text-align: right;
    }
    .value{
      text-align: left;
    }
  }
  .input {
    width: 200px;
    /*height: 40px;*/
    /*margin-right: 10px;*/
  }
</style>
