<template>
  <div class="material-publishImageText-container">
    <trend-publish ref="trendPublish" :showConfirm="false">
      <PageHeader ref="pageHeader" v-if="businessType===5" slot="pageHeader" :show-title="false" :show-icon="false" :show-button="true">
        <div slot="content">
          <a-button type="primary" class="action" @click="handlePublish" :loading="loading">发布</a-button>
        </div>

      </PageHeader>
    </trend-publish>
    <select-store ref="selectStore"></select-store>
  </div>
</template>
<script>
import TrendPublish from '@/views/content/trend/trend-publish.vue'
import SelectStore from '../store/selectStore.vue'
import { convertUploadToDb } from '@/utils/util'
import { mapGetters } from 'vuex'
export default {
  components: { TrendPublish, SelectStore },
  data() {
    return {
      loading: false,
    }
  },
  computed: {
    ...mapGetters(['businessType']),
  },

  methods: {
    handlePublish() {
      this.$refs.trendPublish.$refs['refForm'].validate((valid) => {
        if (valid) {
          let obj = this.$refs.trendPublish.form
          let params = {
            content: obj.content,
            contentType: obj.contentType,
            coverUri: null,
            fileList: obj.uploadFileList.map((item) => convertUploadToDb(item)),
            productIds: obj.productIds,
            tabList: obj.tabList,
            title: obj.title,
            trendStatus: 200,
            storesType: obj.storesType,
          }
          if (obj.id) {
            params.id = obj.id
          }
          params.coverUri = params.fileList[0].uri
          this.$refs.selectStore.onShow(params)
        }
      })
    },
  },
}
</script>
<style lang="scss" scoped>
.material-publishImageText-container {
  height: 100%;
}
.action {
  width: 96px;
  height: 40px;
  font-size: 16px;
}
</style>