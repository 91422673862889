<!-- //
店铺管理-店铺信息
@auth xuyd
@date 2022-09-10
//-->
<template>
  <a-layout id="layout-basic" style="padding-bottom: 60px">
    <div style="min-width: 650px; max-width: 920px; margin-right: 20px">
      <ContentItem :content="form.homepageInformation.homepageName">
        <div slot="placeholder">
          <span>添加品牌信息，展示主页头像</span>
          <a-button type="link" @click="$refs.modifyHomeGageModal.onShow(form)">修改主页名称</a-button>
        </div>
        <div slot="btn">
          <span v-if="form.brandInformation.auditState !== 1" :class="'auditState' + form.brandInformation.auditState">{{ form.brandInformation.auditState | AUDIT_STATE }}</span>
          <a-button type="link" @click="$refs.modifyLogoModal.onShow(form)">修改Logo</a-button>
        </div>
        <div slot="content" class="content">
          <div class="content-inner" v-if="form.homepageInformation.auditState === 0">
            添加品牌信息，展示主页头像
            <div :class="'auditState' + form.homepageInformation.auditState">{{ form.homepageInformation.auditState | AUDIT_STATE }}</div>
          </div>
          <div class="content-inner" v-if="form.homepageInformation.auditState === 1">
            {{ form.homepageInformation.homepageName }}
            <a-button type="link" @click="$refs.modifyHomeGageModal.onShow(form)">修改主页名称</a-button>
          </div>
          <div class="content-inner" v-if="form.homepageInformation.auditState === 2">
            添加品牌信息，展示主页头像
            <div :class="'auditState' + form.homepageInformation.auditState">{{ form.homepageInformation.auditState | AUDIT_STATE }}</div>
            <a-button type="link" @click="$refs.modifyHomeGageModal.onShow(form)">修改主页名称</a-button>
          </div>
        </div>
        <div class="logo" slot="title">
          <a-avatar v-if="form.brandInformation.auditState !== 1" slot="title" :size="66" style="background-color: #f0f0f0">logo</a-avatar>
          <a-avatar v-else slot="title" :size="66" style="background-color: #f0f0f0" :src="ossUrl(form.brandInformation.brandLogoPic)">logo</a-avatar>
        </div>
      </ContentItem>
      <ContentItem title="简介" placeholder="完善主页简介，展示经营理念" :content="form.introductionInformation.introduction">
        <div class="introduction" slot="content">
          <span v-if="form.introductionInformation.auditState === 0">完善主页简介，展示经营理念</span>
          <span style="word-break: break-all" v-if="form.introductionInformation.auditState === 1">{{ form.introductionInformation.introduction }}</span>
          <span v-if="form.introductionInformation.auditState === 2">完善主页简介，展示经营理念</span>
        </div>
        <div slot="btn">
          <span v-if="form.introductionInformation.auditState !== 1" :class="'auditState' + form.introductionInformation.auditState">{{ form.introductionInformation.auditState | AUDIT_STATE }}</span>
          <a-button type="link" @click="$refs.modifyIntroductionModal.onShow(form)">修改</a-button>
        </div>
      </ContentItem>

      <ContentItem title="主页背景图" :content="form.backgroundPicInformation.bgm || u20220" :alignItems="false">
        <div class="bg" slot="content">
          <img :src="ossUrl(form.backgroundPicInformation.bgm) || u20220" style="width: 100%; height: 100%" />
        </div>
        <div class="bgrightBtn" slot="btn">
          <Upload name="file" listType="text" @success="(e) => uploadSuccess(e, 'bgm')">
            <div slot="content">
              <a-button type="link">修改</a-button>
            </div>
          </Upload>
        </div>
      </ContentItem>

      <ContentItem v-if="!(businessType === 1 && businessTag === 1)" :content="form.shopAddressInformation.shopAdressDetail" title="地址信息" placeholder="添加地址信息，让顾客更快到店">
        <div slot="btn">
          <span v-if="form.shopAddressInformation.auditState !== 1" :class="'auditState' + form.shopAddressInformation.auditState">{{ form.shopAddressInformation.auditState | AUDIT_STATE }}</span>
          <a-button type="link" @click="$refs.modifyAddressModal.onShow(form)">修改</a-button>
        </div>
        <div slot="content">
          <span v-if="form.shopAddressInformation.auditState === 0">添加地址信息，让顾客更快到店</span>
          <span v-if="form.shopAddressInformation.auditState === 1">{{ form.shopAddressInformation.shopAdressDetail }}</span>
          <span v-if="form.shopAddressInformation.auditState === 2">添加地址信息，让顾客更快到店</span>
        </div>
      </ContentItem>
      <ContentItem v-if="!(businessType === 1 && businessTag === 1) && form.shopAddressInformation.shopAdressDetail" :showBtn="false" :content="1" title="营业时间">
        <div slot="content">
          <span v-if="form.shopAddressInformation.workDaysBegin && form.shopAddressInformation.workDaysEnd">
            {{ form.shopAddressInformation.workDaysBegin + '至' + form.shopAddressInformation.workDaysEnd }}
          </span>
          <span v-if="form.shopAddressInformation.workTimeBegin && form.shopAddressInformation.workTimeEnd">
            {{ form.shopAddressInformation.workTimeBegin + '至' + form.shopAddressInformation.workTimeEnd }}
          </span>
          <a-switch checked-children="营业中" un-checked-children="打烊" :checked="form.shopAddressInformation.operatingState === 1" @click="clickOperatingState" />
        </div>
      </ContentItem>

      <!-- 个人-创作者  没有店铺环境； 企业-创作者叫做办公环境，其它叫做店铺环境-->
      <ContentItem v-if="!(businessType === 1 && businessTag === 1)" :content="form.shopEnvironment.shopEnvironment.length" :title="businessType === 1 && businessTag === 2 ? '办公环境' : '店铺环境'" placeholder="添加办公环境，顾客直接查看信息">
        <div slot="content">
          <div v-if="form.shopEnvironment.auditState === 0">添加办公环境，顾客直接查看信息</div>
          <div class="shopEnvironmentImgBox" v-if="form.shopEnvironment.auditState === 1">
            <img :src="ossUrl(item)" v-for="(item, index) in form.shopEnvironment.shopEnvironment" :key="index" />
          </div>
          <div v-if="form.shopEnvironment.auditState === 2">添加办公环境，顾客直接查看信息</div>
        </div>
        <div slot="btn">
          <span v-if="form.shopEnvironment.auditState !== 1" :class="'auditState' + form.shopEnvironment.auditState">{{ form.shopEnvironment.auditState | AUDIT_STATE }}</span>
          <a-button type="link" @click="$refs.shopEnvironmentModal.onShow(form)">修改</a-button>
        </div>
      </ContentItem>

      <ContentItem v-if="!(businessType === 1 && businessTag === 1)" :title="businessType === 1 && businessTag === 2 ? '团队成员' : '店铺团队'" :content="form.teamInfo.length" placeholder="展示团队风貌，顾客更加信任" @onEdit="$router.push({ path: '/shop/employee/list' })">
        <div class="teamImgBox" slot="content">
          <div class="team-inner" v-for="(item, index) in form.teamInfo" :key="index">
            <img :src="ossUrl(item.headImage)" />
            <div class="name textoverflow" :title="item.employeesName">{{ item.employeesName }}</div>
          </div>
        </div>
      </ContentItem>

      <ContentItem title="身份信息" placeholder="添加身份信息，顾客更加信任" :content="form.idAuthenticationParam.identityName">
        <div slot="btn">
          <span v-if="form.idAuthenticationParam.auditState !== 1" :class="'auditState' + form.idAuthenticationParam.auditState">{{ form.idAuthenticationParam.auditState | AUDIT_STATE }}</span>
          <a-button type="link" @click="$refs.identityInformationModal.onShow(form)">修改</a-button>
        </div>
        <div class="introduction" slot="content">
          <span v-if="form.idAuthenticationParam.auditState === 0">添加身份信息，顾客更加信任</span>
          <span v-if="form.idAuthenticationParam.auditState === 1">{{ form.idAuthenticationParam.identityName }}</span>
          <span v-if="form.idAuthenticationParam.auditState === 2">添加身份信息，顾客更加信任</span>
        </div>
      </ContentItem>

      <ContentItem title="经历" placeholder="添加经历，例如：2018-2019   参与项目龙湖天地壹号" :alignItems="true" :content="form.experience.experienceContentList.length">
        <div slot="content">
          <span v-if="form.experience.auditState === 0">添加经历，例如：2018-2019 参与项目龙湖天地壹号</span>
          <span v-if="form.experience.auditState === 1">
            <p v-for="item in form.experience.experienceContentList" :key="item.id">{{ item.timePeriod }}&nbsp;&nbsp;{{ item.title }}</p>
          </span>
          <span v-if="form.experience.auditState === 2">添加经历，例如：2018-2019 参与项目龙湖天地壹号</span>
        </div>
        <div slot="btn">
          <span v-if="form.experience.auditState !== 1" :class="'auditState' + form.experience.auditState">{{ form.experience.auditState | AUDIT_STATE }}</span>
          <a-button type="link" @click="$refs.experienceModal.onShow(form, 12, form.experience.auditState)">修改</a-button>
        </div>
      </ContentItem>

      <ContentItem title="品牌故事" placeholder="添加品牌故事，让顾客更加了解您" :alignItems="true" :content="form.shopHistoryInformation.shopHistory" @onEdit="$refs.brandStoryModal.onShow(form)">
        <div slot="btn">
          <span v-if="form.shopHistoryInformation.auditState !== 1" :class="'auditState' + form.shopHistoryInformation.auditState">{{ form.shopHistoryInformation.auditState | AUDIT_STATE }}</span>
          <a-button type="link" @click="$refs.brandStoryModal.onShow(form)">修改</a-button>
        </div>
        <div slot="content">
          <span v-if="form.shopHistoryInformation.auditState === 0">添加品牌故事，让顾客更加了解您</span>
          <span v-if="form.shopHistoryInformation.auditState === 1">{{ form.shopHistoryInformation.shopHistory }}</span>
          <span v-if="form.shopHistoryInformation.auditState === 2">添加品牌故事，让顾客更加了解您</span>
        </div>
      </ContentItem>
      <!-- 企业店铺没有获奖项 -->

      <ContentItem v-if="businessType !== 3" title="获奖资料" placeholder="添加获奖资料，例如：室内设计大奖" :alignItems="true" :content="form.experienceAward.experienceContentList.length">
        <div slot="content">
          <span v-if="form.experienceAward.auditState === 0">添加获奖资料，例如：室内设计大奖</span>
          <span v-if="form.experienceAward.auditState === 1">
            <p v-for="item in form.experienceAward.experienceContentList" :key="item.id">{{ item.timePeriod }}&nbsp;&nbsp;{{ item.title }}</p>
          </span>
          <span v-if="form.experienceAward.auditState === 2">添加获奖资料，例如：室内设计大奖</span>
        </div>
        <div slot="btn">
          <span v-if="form.experienceAward.auditState !== 1" :class="'auditState' + form.experienceAward.auditState">{{ form.experienceAward.auditState | AUDIT_STATE }}</span>
          <a-button type="link" @click="$refs.experienceModal.onShow(form, 13, form.experienceAward.auditState)">修改</a-button>
        </div>
      </ContentItem>

      <ContentItem v-if="businessType !== 3" title="展览信息" placeholder="添加展览信息，例如：2010 龙美术馆 上海" :alignItems="true" :content="form.experiencexhibition.experienceContentList.length">
        <div slot="content">
          <span v-if="form.experiencexhibition.auditState === 0">添加展览信息，例如：2010 龙美术馆 上海</span>
          <span v-if="form.experiencexhibition.auditState === 1">
            <p v-for="item in form.experiencexhibition.experienceContentList" :key="item.id">{{ item.timePeriod }}&nbsp;&nbsp;{{ item.exhibitionHall }}&nbsp;&nbsp;{{ item.adress }}</p>
          </span>
          <span v-if="form.experiencexhibition.auditState === 2">添加展览信息，例如：2010 龙美术馆 上海</span>
        </div>
        <div slot="btn">
          <span v-if="form.experiencexhibition.auditState !== 1" :class="'auditState' + form.experiencexhibition.auditState">{{ form.experiencexhibition.auditState | AUDIT_STATE }}</span>
          <a-button type="link" @click="$refs.experienceModal.onShow(form, 11, form.experiencexhibition.auditState)">修改</a-button>
        </div>
      </ContentItem>
      <ContentItem :title="businessType === 1 && businessTag === 1 ? '实名认证' : '企业认证'" :placeholder="businessType === 1 && businessTag === 1 ? '认证个人的真实性' : '认证企业的真实性'" :content="businessType === 1 && businessTag === 1? form.personalRealInformation.realName:form.realAuthentication.enterpriseName">
          <div slot="btn"  v-if="businessType === 1 && businessTag === 1">
            <span v-if="form.personalRealInformation.auditState !== 1" :class="'auditState' + form.personalRealInformation.auditState">{{ form.personalRealInformation.auditState | AUDIT_STATE }}</span>
            <a-button type="link" @click="$refs.authenticationModal.onShow(form)">修改</a-button>
          </div>
           <div slot="btn" v-else>
            <span v-if="form.realAuthentication.auditState !== 1" :class="'auditState' + form.realAuthentication.auditState">{{ form.realAuthentication.auditState | AUDIT_STATE }}</span>
            <a-button type="link" @click="$refs.authenticationModal.onShow(form)">修改</a-button>
          </div>
          <div slot="content"  v-if="businessType === 1 && businessTag === 1">
            <span v-if="form.personalRealInformation.auditState === 0">{{ businessType === 1 && businessTag === 1 ? '认证个人的真实性' : '认证企业的真实性' }}</span>
            <span v-if="form.personalRealInformation.auditState === 1">{{ form.personalRealInformation.realName }}</span>
            <span v-if="form.personalRealInformation.auditState === 2">{{ businessType === 1 && businessTag === 1 ? '认证个人的真实性' : '认证企业的真实性' }}</span>
          </div>
          
          <div slot="content" v-else>
            <span v-if="form.realAuthentication.auditState === 0">{{ businessType === 1 && businessTag === 1 ? '认证个人的真实性' : '认证企业的真实性' }}</span>
            <span v-if="form.realAuthentication.auditState === 1">{{ form.realAuthentication.enterpriseName }}</span>
            <span v-if="form.realAuthentication.auditState === 2">{{ businessType === 1 && businessTag === 1 ? '认证个人的真实性' : '认证企业的真实性' }}</span>
          </div>
      </ContentItem>
    </div>

    <ModifyHomeGageModal ref="modifyHomeGageModal" @submit="submit" />
    <ModifyLogoModal ref="modifyLogoModal" @submit="submit" />
    <ModifyIntroductionModal ref="modifyIntroductionModal" @submit="submit" />
    <ModifyAddressModal ref="modifyAddressModal" @submit="submit" />
    <ShopEnvironmentModal ref="shopEnvironmentModal" @submit="submit" />
    <IdentityInformationModal ref="identityInformationModal" @submit="submit" />
    <ExperienceModal ref="experienceModal" @submit="submit" />
    <BrandStoryModal ref="brandStoryModal" @submit="submit" />
    <AuthenticationModal ref="authenticationModal" @submit="submit" />
    <a-layout-sider width="375px">
      <div style="color: #000000; font-size: 16px; font-weight: 700">主页展示效果（示意图）</div>

      <div style="width: 375px; height: 644px; position: relative">
        <img :src="shopBrandImg" style="width: 100%" />
      </div>
    </a-layout-sider>
  </a-layout>
</template>
<script>
import Upload from '@/components/Upload'
import * as ShopApi from '@/api/shop/shop.api'
import shopInfoImg1 from '@/assets/img/logo-shop-1.png'
import shopInfoImg2 from '@/assets/img/logo-shop-2.png'
import shopInfoImg3 from '@/assets/img/logo-shop-3.png'
import designerImg from '@/assets/img/designer.png'
import mechanismImg from '@/assets/img/mechanism.png'
import merchantImg from '@/assets/img/merchant.png'
import u20220 from '@/assets/img/u20220_new.png'
import u20221 from '@/assets/img/u20221.png'
import u20222 from '@/assets/img/u20222.png'
import ContentItem from './components/ContentItem'
import { mapGetters } from 'vuex'
import ModifyHomeGageModal from './components/modifyHomeGage_modal.vue'
import ModifyLogoModal from './components/modifyLogo_modal.vue'
import ModifyIntroductionModal from './components/modifyIntroduction_modal.vue'
import ModifyAddressModal from './components/modifyAddress_modal.vue'
import ShopEnvironmentModal from './components/shopEnvironmen_modal.vue'
import IdentityInformationModal from './components/identityInformation_modal.vue'
import ExperienceModal from './components/experience_modal.vue'
import BrandStoryModal from './components/brandStory_modal.vue'
import AuthenticationModal from './components/authentication_modal.vue'
/**businessTag
 * 1-个人
 * 2-企业
 */

/**businessType
 * 0，个人，1，创作者平台，2独立店，3商家
 */

export default {
  name: 'ShopInfo',
  components: { ContentItem, Upload, ModifyHomeGageModal, ModifyLogoModal, ModifyIntroductionModal, ModifyAddressModal, ShopEnvironmentModal, IdentityInformationModal, ExperienceModal, BrandStoryModal, AuthenticationModal },
  dictTypes: [
    { dictType: 'businessTag', dataType: String }, // 平台标记
    { dictType: 'businessType', dataType: String }, // 平台类型
    { dictType: 'operatingState', dataType: String }, // 经营状态
    { dictType: 'applyState', dataType: String }, // 入驻状态
  ],
  data() {
    return {
      activeTabKey: 'shopInfo',
      shopInfoImg1: shopInfoImg1,
      shopInfoImg2: shopInfoImg2,
      shopInfoImg3: shopInfoImg3,
      designerImg,
      mechanismImg,
      merchantImg,
      u20220: u20220,
      u20221: u20221,
      u20222: u20222,

      form: {
        brandInformation: {
          brandLogoPic: null,
          brandName: null,
          brandRegistPic: null,
          brandAuthorizePic: null,
          auditState: 0,
        },
        homepageInformation: {
          homepageName: null,
          auditState: 0,
        },
        shopAddressInformation: {
          latitude: 0,
          longitude: 0,
          shopAdress: null,
          shopAdressDetail: null,
          workDaysBegin: null,
          workDaysEnd: null,
          workTimeBegin: null,
          workTimeEnd: null,
          auditState: 0,
        },
        contactInformation: {
          contactsLandline: null,
          contactsPhone: null,
          auditState: 0,
        },
        introductionInformation: {
          introduction: null,
          auditState: 0,
        },
        backgroundPicInformation: {
          bgm: null,
          auditState: 0,
        },
        shopEnvironment: {
          shopEnvironment: [''],
          auditState: 0,
        },
        idAuthenticationParam: {
          identityName: null,
          idPics: [''],
          auditState: 0,
        },
        shopHistoryInformation: {
          shopHistory: null,
          auditState: 0,
        },
        realAuthentication: {
          enterpriseName: null,
          enterpriseState: 0,
          busiLicensePic: null,
          busiLicenseCode: null,
          busiLicenseAvailable: null,
          legalmanLicenceName: null,
          legalmanLicenceCode: null,
          legalmanLicencePic: null,
          legalmanLicenceBackpic: null,
          legalmanLicenceHandpic: null,
        },
        experienceAward: {
          type: 0,
          experienceContentList: [
            {
              timePeriod: null,
              title: null,
              exhibitionHall: null,
              adress: null,
              pics: [''],
              id: 0,
            },
          ],
        },
        experiencexhibition: {
          type: 0,
          experienceContentList: [
            {
              timePeriod: null,
              title: null,
              exhibitionHall: null,
              adress: null,
              pics: [''],
              id: 0,
            },
          ],
        },
        experience: {
          type: 0,
          experienceContentList: [
            {
              timePeriod: null,
              title: null,
              exhibitionHall: null,
              adress: null,
              pics: [''],
              id: 0,
            },
          ],
        },
        teamInfo: [
          {
            employeesName: null,
            headImage: null,
            position: null,
            sort: 0,
          },
        ],
      },
    }
  },
  computed: {
    ...mapGetters(['businessType', 'businessTag', 'businessUserId']),
    shopBrandImg() {
      if (this.businessType === 1) {
        return designerImg
      } else if (this.businessType === 2) {
        return mechanismImg
      } else {
        return merchantImg
      }
    },
  },
  mounted() {
    console.log(this.businessTag, 'businessTag')
    console.log(this.businessType, 'businessType')
    this.init()
  },
  methods: {
    init() {
      ShopApi.getInfo().then((data) => {
        this.form = data
      })
    },
    submit(form, cb) {
      ShopApi.editShopInfo(form)
        .then((res) => {
          this.$message.success('已提交，等待审核')
          this.init()
          cb()
        })
        .catch((err) => {
          cb()
        })
    },
    uploadSuccess(e, type) {
      if (type === 'bgm') {
        const form = {
          homepageInformation: null,
          shopEnvironment: null,
          shopHistoryInformation: null,
          brandInformation: null,
          shopAddressInformation: null,
          idAuthenticationParam: null,
          realAuthentication: null,
          backgroundPicInformation: {
            bgm: e,
          },
          experience: null,
          introductionInformation: null,
        }
        ShopApi.editShopInfo(form).then((res) => {
          this.form.backgroundPicInformation.bgm = e
        })
      }
    },
    clickOperatingState() {
      // if (this.form.shopAddressInformation.auditState === 0) {
      //   return this.$message.info('信息审核中耐心等待')
      // }

      if (this.form.shopAddressInformation.operatingState === 1) {
        this.form.shopAddressInformation.operatingState = 0
      } else {
        this.form.shopAddressInformation.operatingState = 1
      }
      ShopApi.editOpenStatus(this.form.shopAddressInformation.operatingState).then((res) => {
        console.log(res)
        if (res.code === '200') {
          this.init()
          this.$message.success('操作成功！')
        }
      })
      // this.$refs.modifyAddressModal.changeOperatingState(this.form, this.form.shopAddressInformation.operatingState)
    },
  },
}
</script>

<style lang="scss" scoped>
::v-deep(.ant-divider-horizontal) {
  margin: 10px 0;
}
#layout-basic {
  background-color: #ffffff;
}

#layout-basic .ant-layout-sider {
  background-color: #ffffff;
  color: #fff;
}
.bg {
  width: 314px;
  height: 227px;
  margin-bottom: 10px;
  img {
    border-radius: 30px;
  }
}
.content-inner {
  display: flex;
  align-items: center;
}

.shopEnvironmentImgBox {
  img {
    width: 90px;
    height: 65px;
    border-radius: 5px;
    margin: 5px;
  }
  img:last-child {
    margin-right: 0;
  }
}
.teamImgBox {
  .team-inner {
    float: left;
    margin-right: 20px;
    text-align: center;
  }
  .team-inner:last-child {
    margin-right: 0;
  }
  img {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: #cccccc;
    border: 1px solid #e7e7e7;
  }
  .name {
    width: 50px;
  }
}
</style>
