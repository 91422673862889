<template>
  <a-modal v-model="visible"
           width="590px"
           :bodyStyle="{'height': '334px'}"
           :keyboard="false"
           :footer="null"
           :maskClosable="false"
           :destroyOnClose="true">
    <div slot="title" class="modal-title">
      <div class="title">新增员工</div>
      <div class="hint">搜索您的员工账号</div>
    </div>
      <a-spin wrapper-class-name="global-spin" :spinning="loading">
        <div class="dlg-platform-container">
          <a-form-model ref="refForm"
                        class="layout-form"
                        :model="form"
                        :rules="rules"
                        :colon="false">
            <a-form-model-item prop="mobilePhone" class="input-item">
              <a-input v-model="form.mobilePhone" ref="refInput" placeholder="请输入员工手机号" class="input" :maxLength="11" @pressEnter="handlePhoneSearch"/>
            </a-form-model-item>
            <a-form-model-item class="button-item">
              <a-button type="primary" class="button" @click="handlePhoneSearch">查询</a-button>
            </a-form-model-item>
          </a-form-model>
        </div>
      </a-spin>
  </a-modal>
</template>

<script>
  import * as EmployeeApi from '@/api/shop/employee.api'
  import {validateUser} from "@/views/login/loginUtil";

  export default {
    name: 'EmployeeSearchComp',
    data() {
      return {
        visible: false,
        labelCol: {span: 4},
        wrapperCol: {span: 14},
        loading: false,
        form: {
          mobilePhone: null,
        },
        rules: {
          mobilePhone: [
            {validator: validateUser, trigger: 'blur'}
          ],
        }
      }
    },

    methods: {
      showModal() {
        this.visible = true;
        this.form.mobilePhone = null;
        this.$nextTick(() => {
          this.$refs['refInput'].focus()
        })
      },
      hideModal() {
        this.visible = false;
      },
      handleCancel() {
        this.visible = false
      },

      handlePhoneSearch() {
        this.$refs['refForm'].validate((valid) => {
          if (valid) {
            // console.log("=====>", this.form)
            if (this.loading) return
            this.loading = true
            EmployeeApi.searchDetail(this.form.mobilePhone).then(data => {
              this.loading = false
              this.$emit('handleOk', data)
              this.visible = false
            }).catch(err => {
              this.loading = false
            })
          }
        })
      }
    },
  };
</script>

<style scoped lang="scss">

  .modal-title {
    display: flex;

    .title {
      font-weight: 600;
      font-size: 14px;
    }

    .hint {
      margin-left: 10px;
      font-weight: 400;
      font-size: 12px;
      color: #0000006d;
    }
  }

  .dlg-platform-container {
    height: 100%;
    padding: 10px 15px;
    overflow: auto;


    .input-item {
      margin-top: 30px;
      margin-left: 40px;
    }

    .input {
      font-size: 16px;
      width: 442px;
      height: 50px;
      line-height: 50px;
    }

    .button-item {
      text-align: center;
    }

    .button {
      font-size: 16px;
      width: 250px;
      height: 40px;
      line-height: 40px;
      margin-top: 60px;
    }
  }

</style>
