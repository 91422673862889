<template>
  <div class="store-manage-detail">
    <PageHeader :show-button="false" showIcon :showConfirm="false"></PageHeader>
    <div class="brandDetail">
      <div class="box">
        <div class="card left">
          <div class="item align">
            <img class="logo" style="margin-right: 40px" :src="ossUrl(form.brandInformation?.brandLogoPic)">
            <span>{{ form.homepageInformation?.homepageName }}</span>
          </div>
          <div class="item">
            <span class="label">简介</span>
            <span class="value">{{form.introductionInformation?.introduction}}</span>
          </div>
          <div class="item">
            <span class="label">数据</span>
            <span class="value">
              <span style="margin-right: 30px"> 门店浏览次数 {{ form.browseCount }} </span>
              <span style="margin-right: 30px"> 门店分享次数 {{ form.shareCount }} </span>
              <span style="margin-right: 30px"> 点击联系次数 {{ form.contactCount }} </span>
              <span style="margin-right: 30px"> 得到联系方式次数 {{ form.contactSuccessCount }}</span>
            </span>
          </div>

        </div>
        <div class="card right">
          <h2>绑定管理员</h2>
          <div class="item align">
            <img class="logo mr10" :src="ossUrl(form.bindPersonInformation?.headPic)">
            <div>
              <div>{{ form.bindPersonInformation?.name }}</div>
              <div>{{ form.bindPersonInformation?.sex==0?'女':'男' }}</div>
              <div>{{ form.bindPersonInformation?.phone }}</div>
            </div>
          </div>

          <div class="item">
            <span class="label">最后登录时间</span>
            <span class="value">{{form.bindPersonInformation?.latestLoginTime}}</span>
          </div>
          <div class="item">
            <span class="label">注册时间</span>
            <span class="value">{{ form.bindPersonInformation?.signUpTime }}</span>
          </div>
        </div>
      </div>

      <div class="card card4 mt20">
        <h3 class="MB50" style="margin-bottom: 30px">门店信息</h3>
        <div class="item">
          <span class="label">门店联系电话</span>
          <span class="value">{{ form.contactsLandline }}</span>
        </div>
        <div class="item">
          <span class="label">门店联系手机号</span>
          <span class="value">{{ form.contactsPhone }}</span>
        </div>
        <div class="item">
          <span class="label">线下实体店地址</span>
          <span class="value">{{ form.shopAddressInformation?.shopAdressDetail }}</span>
        </div>
        <div class="item">
          <span class="label">工作时间</span>
          <span class="value">{{ form.shopAddressInformation?.workDaysBegin +'-'+form.shopAddressInformation?.workDaysEnd  }} {{form.shopAddressInformation?.workTimeBegin +'-'+form.shopAddressInformation?.workTimeEnd  }}</span>
        </div>
        <div class="item">
          <span class="label">企业名称</span>
          <span class="value">{{ form.realAuthentication?.enterpriseName }}</span>
        </div>

        <div class="item block">
          <span class="label">营业执照</span>
          <div class="value MT10">
            <HKImage emptyText="三证合一" :src="ossUrl(form.realAuthentication?.busiLicensePic)" />
          </div>
        </div>

        <div class="item">
          <span class="label">社会统一信用代码</span>
          <span class="value">{{ form.realAuthentication?.busiLicenseCode }}</span>
        </div>
        <div class="item">
          <span class="label">营业执照有效期</span>
          <span class="value">{{ form.realAuthentication?.busiLicenseAvailable }}</span>
        </div>
        <div class="item">
          <span class="label">法人姓名</span>
          <span class="value">{{ form.realAuthentication?.legalmanLicenceName }}</span>
        </div>
        <div class="item block">
          <span class="label">法人身份证</span>
          <div class="imageBox MT10">
            <HKImage emptyText="手持身份证照片" :src="ossUrl(form.realAuthentication?.legalmanLicenceHandpic)" />
            <HKImage class="ml20" emptyText="身份证正面照片" :src="ossUrl(form.realAuthentication?.legalmanLicencePic)" />
            <HKImage class="ml20" emptyText="身份证反面照片" :src="ossUrl(form.realAuthentication?.legalmanLicenceBackpic)" />
          </div>
        </div>
      </div>
    </div>

  </div>
</template>
<script>
import HKImage from '@/components/HKImage'
import StoreModel from '@/api/brandManage/store'
export default {
  components: { HKImage },
  data() {
    return {
      form: {
        browseCount: 0,
        shareCount: 0,
        contactSuccessCount: 0,
        contactCount: 0,
        bindPersonInformation: {
          headPic: '',
        },
        brandInformation: {
          auditState: 0,
          brandLogoPic: '',
          brandName: '',
          brandRegistPic: '',
          brandAuthorizePic: '',
        },
        homepageInformation: {
          auditState: 0,
          homepageName: '',
        },
        shopAddressInformation: {
          auditState: null,
          latitude: null,
          longitude: null,
          shopAdress: '',
          shopAdressDetail: '',
          workDaysBegin: '',
          workDaysEnd: '',
          workTimeBegin: '',
          workTimeEnd: '',
          operatingState: 1,
        },
        contactInformation: {},
        introductionInformation: {
          auditState: 0,
          introduction: '',
        },
        backgroundPicInformation: {
          auditState: null,
          bgm: '',
        },
        shopEnvironment: {
          auditState: 0,
          shopEnvironment: [],
        },
        idAuthenticationParam: {
          auditState: null,
          identityName: '',
          idPics: [],
        },
        shopHistoryInformation: {
          auditState: 0,
          shopHistory: '',
        },
        realAuthentication: {
          auditState: null,
          enterpriseName: '',
          enterpriseState: null,
          busiLicensePic: '',
          busiLicenseCode: '',
          busiLicenseAvailable: '',
          legalmanLicenceName: '',
          legalmanLicenceCode: '',
          legalmanLicencePic: '',
          legalmanLicenceBackpic: '',
          legalmanLicenceHandpic: '',
        },
        experienceAward: {
          auditState: null,
          type: null,
          experienceContentList: [],
        },
        experiencexhibition: {
          auditState: null,
          type: null,
          experienceContentList: [],
        },
        experience: {
          auditState: null,
          type: null,
          experienceContentList: [],
        },
        teamInfo: [],
        personalRealInformation: {},
        abilityCertification: [],
        contactsPhone: '',
        contactsLandline: '',
      },
    }
  },
  mounted() {
    console.log(this.$store.state, ' this.$store.state')
    this.form = { ...this.$route.query }
    this.initData()
  },
  methods: {
    initData() {
      // let businessUserId = this.$store.state.user.userSession.businessUserId
      StoreModel.detail(this.$route.query.businessUserId).then((data) => {
        this.form = data
        this.form.browseCount = this.$route.query.browseCount
        this.form.shareCount = this.$route.query.shareCount
        this.form.contactCount = this.$route.query.contactCount
        this.form.contactSuccessCount = this.$route.query.contactSuccessCount
        console.log(this.form)
      })
    },
  },
}
</script>
<style lang="scss" scoped>
.brandDetail {
  padding: 20px;
  background: #fff;

  .card {
    width: 100%;
    padding: 20px;
    border: 1px solid rgb(235, 237, 240);

    h3 {
      font-weight: bold;
    }

    .item {
      display: flex;

      margin-bottom: 20px;

      .logo {
        width: 60px;
        height: 60px;
      }

      .label {
        display: block;
        width: 100px;
        font-weight: 500;
      }

      .value {
        flex: 1;
        color: #00000085;
      }
    }
    .align {
      align-items: center;
    }
    .block {
      display: block;
    }
  }

  .box {
    display: flex;

    .left {
      flex: 2;
      margin-right: 20px;
    }

    .right {
      flex: 1;

      h2 {
        font-weight: bold;
      }

      .label {
        width: 140px;
      }
    }
  }

  .card2 {
    display: flex;

    .imageBox {
      margin-right: 20px;
    }
  }

  .card3 {
    .imageBox {
      display: flex;
    }
  }

  .card4 {
    .item {
      .label {
        width: 140px;
      }
    }
    .imageBox {
      display: flex;
      flex-wrap: wrap;
    }
  }
}
</style>
