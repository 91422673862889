<!-- //
作品管理
@auth xuyd
@date 2022-09-10
//-->
<template>
  <ContentList :content-type="3"/>
</template>
<script>
  import ContentList from '../common/content-list'
  export default {
    name: 'ProductionList',
    components: {ContentList},
    data() {
      return {};
    },
    methods: {}
  }
</script>

<style lang="scss" scoped>
</style>
