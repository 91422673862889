<!--
@author: binchen
@desc：index
@date: 2023-07-23 10:35:26
-->
<template>
  <div class="batchRelease">
    <div class="batchRelease-conteint">
      <a-steps :current="current">
        <a-step title="选择图片并上传" description="每次最多上传50张图片">
          <div class="step-icon" :class="current === 0 ? 'step-1' : 'step-2'" slot="icon">1</div>
        </a-step>
        <a-step title="填写图文动态的内容" description="标题为必填，损坏的图片不能上传">
          <div class="step-icon" :class="current === 1 ? 'step-1' : 'step-2'" slot="icon">2</div>
        </a-step>
        <a-step title="确认上传数据" description="交上传资料" />
      </a-steps>
      <div class="container">
        <div class="uploadBox" v-if="show">
          <input
            type="file"
            multiple="multiple"
            @dragenter="
              () => {
                return false
              }
            "
            @paste="
              () => {
                return false
              }
            "
            @change="handleChange"
            :accept="accept"
          />
          <div class="text">
            <img src="./icon.png" alt="" />
            <p class="ant-upload-text">点击上传或将图片拖拽到此区域进行上传</p>
            <p class="ant-upload-hint">每次最多上传50张图片，单张图片体积不能超过20MB</p>
            <p class="ant-upload-hint">支持格式：.jpeg .jpg .png .bmp</p>
          </div>
          <p class="bottom-text">*请您务必确保所上传的内容，未涉及任何侵权及违反互联网监管协议；您将承担所有法律责任，与此同时平台将保留向您追诉的法律权利</p>
        </div>
        <div class="tableBox" v-else>
          <div class="search" style="margin: 40px 0">
            <div class="label">批量设置内容</div>
            <div class="item">
              <a-input style="height: 38px" v-model="form.title" placeholder="请输入标题"></a-input>
            </div>
            <div class="item">
              <a-input style="height: 38px" v-model="form.desc" placeholder="请输入描述"></a-input>
            </div>
            <div class="item">
              <TagSelector :limit="6" mode="multiple" class="form-value" @change="(value) => handleTabChange(value, form)"></TagSelector>
            </div>

            <a-button type="link" @click="handleBatch"><span style="font-weight: bold">批量设置</span></a-button>
          </div>

          <div class="tableBoxIner" style="margin-top: 20px">
            <a-table :columns="columns" rowKey="id" :data-source="tabeldata" bordered :pagination="false" :scroll="{ y: 900 }">
              <template slot="customTitle">
                <i style="color: red">*</i>
                标题（30字以内）
              </template>
              <template slot="url" slot-scope="text, record">
                <div v-if="!record.err">
                  <a-upload
                    v-if="!record.url && !record.file"
                    name="file"
                    :accept="accept"
                    :showUploadList="false"
                    :multiple="false"
                    :beforeUpload="
                      (file) => {
                        return beforeUploadSingle(record, file)
                      }
                    "
                  >
                    <a-button type="link">
                      <a-icon type="upload" />
                      重新上传
                    </a-button>
                  </a-upload>
                  <div class="imgBox" v-else>
                    <div class="imgcontent">
                      <img :src="record.url.indexOf('data:image')!==-1? record.url : ossUrl(record.url)" alt="" @load="(e)=>onLoadImg(e,record)" @error="(e) => onerror(e, record)" />
                    </div>
                    <div class="mask" :class="{ maskT: record.percent == 100 }">
                      <div class="maskContent">
                        <a-icon class="icon" type="eye" @click="handlePreview(record)" />
                        <a-icon class="icon" type="delete" @click="handleDelImg(record)" />
                      </div>
                    </div>
                    <div class="progress" v-if="record.percent !== 100">
                      <div>文件上传中</div>
                      <a-progress :percent="record.percent" size="small" :showInfo="false" :strokeWidth="3" />
                    </div>
                  </div>
                </div>
                <div class="imgBox" style="border: 1px solid red;align-items: center;display: flex;" v-else>
                  <span class="err">{{ record.err }} <br>上传失败</span>
                </div>
              </template>
              <template slot="batchReleaseTitle" slot-scope="text, record">
                <a-textarea v-model="record.title" :maxLength="30" :rows="4" />
              </template>
              <template slot="desc" slot-scope="text, record">
                <a-textarea v-model="record.desc" :maxLength="1000" :rows="4" />
              </template>
              <template slot="tag" slot-scope="text, record">
                <div style="width: 100%">
                  <TagSelector v-model="record.tag" :limit="6" mode="multiple" @change="(value) => handleTabChange(value, record)" class="form-value"></TagSelector>
                </div>
              </template>
              <template slot="operation" slot-scope="text, record">
                <a-button type="link" @click="handleDel(record)">删除</a-button>
              </template>
            </a-table>
          </div>
        </div>
      </div>
      <div class="previewBox" v-if="showPreview">
        <div class="mask" @click="showPreview = false"></div>
        <a-icon class="closeIcon" type="close-circle" @click="showPreview = false" />
        <img :src="ossUrl(previewUrl)" alt="" />
      </div>
    </div>

    <div v-if="current === 1" class="footer">
      <a-button style="width: 200px" type="primary" @click="handleSubmit">确认上传</a-button>
    </div>
  </div>
</template>

<script>
const columns = [
  {
    title: '图片',
    dataIndex: 'url',
    width: 120,
    scopedSlots: { customRender: 'url' },
  },
  {
    dataIndex: 'title',
    slots: { title: 'customTitle' },
    scopedSlots: { customRender: 'batchReleaseTitle' },
  },
  {
    title: '描述（1000字以内）',
    dataIndex: 'desc',
    scopedSlots: { customRender: 'desc' },
  },
  {
    title: '添加标签（最多添加6个）',
    dataIndex: 'tag',
    scopedSlots: { customRender: 'tag' },
  },
  {
    title: '操作',
    dataIndex: 'operation',
    width: 80,
    scopedSlots: { customRender: 'operation' },
  },
]
function findTabByName(tabs, name) {
  for (let tab of tabs) {
    if (tab.tabName === name) {
      return [tab]
    } else if (tab.childTabs && tab.childTabs.length > 0) {
      let result = findTabByName(tab.childTabs, name)
      if (result) {
        return [tab, ...result]
      }
    }
  }
  return null
}
function removeDuplicates(arr) {
  // 创建一个空对象用于保存已经出现过的tabId
  let seenIds = {}
  // 创建一个新数组用于保存去重后的数据
  let uniqueArr = []
  // 遍历原始数组
  for (let i = 0; i < arr.length; i++) {
    let tab = arr[i]
    // 如果这个tabId还没有出现过，就将它添加到uniqueArr中，并将这个tabId添加到seenIds中
    if (!seenIds[tab.tabId]) {
      uniqueArr.push(tab)
      seenIds[tab.tabId] = true
    }
  }
  return uniqueArr
}
import { queryAllTab } from '@/api/content/tag.api.js'
import { batchPublish } from '@/api/content/trend.api.js'
import TagSelector from '@/components/TagSelector'
import * as OssClient from '@/components/AliOss/OssClient'
export default {
  name: 'BatchRelease',
  components: { TagSelector },
  data() {
    return {
      acceptArr: ['image/png', 'image/jpeg', 'image/bmp'],
      accept: '.jpeg,.jpg,.png,.bmp',
      current: 0,
      show: true,
      columns,
      tabeldata: [],
      form: {
        title: '',
        desc: '',
        tag: [],
      },
      previewUrl: null,
      showPreview: false,
      allTabList: [],
    }
  },
  created() {
    this.featchAllTabs()
  },
  mounted() {},
  methods: {
    featchAllTabs() {
      queryAllTab().then((res) => {
        console.log(res)

        this.allTabList = res
      })
    },
    handlePreview(row) {
      this.previewUrl = row.url
      this.showPreview = true
    },
    handleDelImg(row) {
      row.url = ''
      row.file = null
    },
    handleDel(row) {
      let index = this.tabeldata.findIndex((item) => item.id === row.id)
      this.tabeldata.splice(index, 1)
      if (!this.tabeldata.length) {
        this.show = true
        this.current = 0
      }
    },
    beforeUploadSingle(row, file) {
      console.log(file)
      let name = file.name.split('.')[0]
      let names = name.split('#')
      if (file.size / 1024 / 1024 > 20) {
        // this.$message.error(图片体积不能超过20MB：${file.name}`)
        let row = {
          id: file.uid + (Math.random() + ''),
          url: '',
          file: '',
          title: names[0],
          desc: names[0],
          tag: [],
          percent: 0,
          err: '图片体积不能超过20MB',
        }
        this.tabeldata.push(row)
        return
      }
      if (!this.acceptArr.includes(file.type)) {
        // this.$message.error(`不支持${file.type}格式图片：${file.name}`)
        let row = {
          id: file.uid + (Math.random() + ''),
          url: '',
          file: '',
          title: names[0],
          desc: names[0],
          tag: [],
          percent: 0,
          err: '图片体积不能超过20MB',
        }
        this.tabeldata.push(row)
        return
      }
      let reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onload = (res) => {
        let tags = []
        names.forEach((name) => {
          const arr = findTabByName(this.allTabList, name)
          if (arr) {
            tags = tags.concat(arr)
          }
        })

        let newTags = removeDuplicates(tags)
        ;(row.id = file.uid + (Math.random() + '')), (row.percent = 0)
        ;(row.url = res.currentTarget.result), (row.file = file)
        row.title = name
        row.desc = name
        row.tag = newTags
        this.sendFile(row)
      }

      return false
    },
    handleBatch() {
      console.log(this.form)
      this.tabeldata.forEach((row) => {
        row.title = this.form.title
        row.desc = this.form.desc
        row.tag = this.form.tag
      })
    },
    handleSubmit() {
      console.log(this.tabeldata)
      for (let i = 0; i < this.tabeldata.length; i++) {
        const item = this.tabeldata[i]
        if (!item.title) {
          return this.$message.info('标题不能为空！')
        }
      }
      const params = {
        trendList: [],
        trendStatus: 0,
        contentType: 1,
      }
      const trendList = this.tabeldata.map((item) => {
        let obj = {
          publicityVideo: '',
          fallsFileName: '',
          title: item.title,
          content: item.desc,
          tabList: item.tag,
          webFallsImage: '',
          trendStatus: 0,
          id: 0,
          appletFallsImage: '',
          contentType: 0,
          coverUri: item.url,
          fileList: [
            {
              originalName: '',
              fileName: '',
              flag: 1,
              webComprImage: '',
              appletComprImage: '',
              sort: 0,
              id: 0,
              type: 3,
              uri: item.url,
            },
          ],
          businessIds: [this.$store.getters.userSession.businessUserId],
        }

        return obj
      })

      params.trendList = trendList
      if (params.trendList[0].tabList.length) {
        params.trendList[0].tabList[0].childTabs = []
      }
      console.log(params)
      batchPublish(params).then((res) => {
        console.log(res)
        if (res.code === '200') {
          this.$message.success('发布成功！')
          this.$router.push('/content/trend/list')
        }
      })
      console.log(this.tabeldata)
    },
    handleTabChange(value, record) {
      let tabParentId = value[0].tabId
      record.tag = value.map((item) => {
        return {
          tabFlag: null,
          tabId: item.tabId,
          tabName: item.tabName,
          tabParentId: tabParentId,
        }
      })
    },
    onLoadImg(e, record){
       record.err = ''
    },
    onerror(e, record) {
      record.err = '图片错误或损坏'
    },
    sendFile(row) {
      this.handleUpload(row)

      // if (row.url) return row.url
      // let reader = new FileReader()
      // reader.readAsDataURL(row.file)
      // reader.onload = () => {
      //   row.url = this.result

      // }
    },
    handleUpload(row) {
      let file = row.file
      let timer = setInterval(function () {
        let a = (100 - row.percent) / 20
        row.percent += a //每次加20
        if (row.percent >= 100) {
          //如果宽度超过最终宽度
          clearInterval(timer) //清空定时器
        }
      }, 200)

      let retResult = OssClient.validateUploadFile({
        file: file,
        bizType: 'image',
      })
      if (!retResult.state) {
        console.log('上传处理失败：' + retResult.message)
        return false
      }

      // 开始设置上传文件
      let fileName = file.name
      OssClient.uploadFile({
        bizType: 'image',
        type: 'file',
        fileName: fileName,
        file: file,
      })
        .then((result) => {
          row.percent = 100
          row.url = result.path
          clearInterval(timer) //清空定时器
          // console.log('上传处理成功：', result)
        })
        .catch((err) => {
          console.log('上传处理失败：' + retResult.message)
        })
    },

    handleChange(e) {
      this.tabeldata = []
      for (let index = 0; index < e.target.files.length; index++) {
        const file = e.target.files[index]
        let name = file.name.split('.')[0]
        let names = name.split('#')
        if (file.size / 1024 / 1024 > 20) {
          // this.$message.error(`图片体积不能超过20MB：${file.name}`)
          let row = {
            id: file.uid + (Math.random() + '') + (index + ''),
            url: '',
            file: '',
            title: names[0],
            desc: names[0],
            tag: [],
            percent: 0,
            err: '图片体积不能超过20MB',
          }
          this.tabeldata.push(row)
          continue
        }
        if (!this.acceptArr.includes(file.type)) {
          let row = {
            id: file.uid + (Math.random() + '') + (index + ''),
            url: '',
            file: '',
            title: names[0],
            desc: names[0],
            tag: [],
            percent: 0,
            err: `不支持${file.type}格式图片`,
          }
          this.tabeldata.push(row)
          // this.$message.error(`不支持${file.type}格式图片：${file.name}`)
          continue
        }
        let reader = new FileReader()
        reader.readAsDataURL(file)
        reader.onload = (res) => {
          let tags = []
          names.forEach((name) => {
            const arr = findTabByName(this.allTabList, name)
            if (arr) {
              tags = tags.concat(arr)
            }
          })

          let newTags = removeDuplicates(tags)
          let row = {
            id: file.uid + (Math.random() + '') + (index + ''),
            url: res.currentTarget.result,
            file: file,
            title: names[0],
            desc: names[0],
            tag: newTags,
            percent: 0,
          }
          this.tabeldata.push(row)
          this.handleUpload(row)
        }
      }
      console.log(this.tabeldata)
      this.show = false
      this.current = 1
      return false
    },
  },
}
</script>

<style scoped lang="scss">
/deep/.ant-steps-horizontal:not(.ant-steps-label-vertical) .ant-steps-item-description {
  max-width: 100%;
}
.step-icon {
  width: 32px;
  height: 32px;

  line-height: 32px;
  text-align: center;
  color: #fff;
  font-size: 16px;
  border-radius: 50%;
}
.step-1 {
  background: #1890ef;
}
.step-2 {
  background-color: #fff;
  border-color: rgba(0, 0, 0, 0.25);
  background: none;
  border: 1px solid rgba(0, 0, 0, 0.25);
  color: rgba(0, 0, 0, 0.25);
}
.batchRelease {
  width: 100%;
  position: relative;
  height: 100%;
  .batchRelease-conteint {
    padding: 40px;
  }
}
.search {
  display: flex;
  align-items: center;
  justify-content: space-around;
  .label {
    width: 100px;
    font-weight: bold;
  }
  .item {
    flex: 1;
    margin: 0 20px;
  }
}
.container {
  .uploadBox {
    margin-top: 40px;
    width: 100%;
    height: 628px;
    position: relative;
    border: 1px dashed #ccc;
    input {
      position: absolute;
      width: 100%;
      height: 100%;
      z-index: 12;
      cursor: pointer;
      opacity: 0;
    }
    .bottom-text {
      width: 100%;
      position: absolute;
      bottom: 20px;
      font-size: 14px;
      color: #00000072;
      text-align: center;
      left: 50%;
      transform: translateX(-50%);
    }
    .ant-upload-text {
      color: #000000ef;
      font-size: 20px;
    }
    .text {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      z-index: 11;
      text-align: center;
      font-size: 18px;
      img {
        width: 80px;
        height: auto;
        margin-bottom: 30px;
      }
    }
  }
  .uploadBox:hover {
    border: 1px dashed #1890ef;
  }
  .tableBox {
    .imgBox {
      width: 84px;
      height: 84px;
      padding: 6.5px;
      border: 1px solid #000;
      border-radius: 5px;
      position: relative;
      font-size: 12px;
      text-align: center;
      cursor: pointer;
      .err {
        font-size: 12px;
      }
      .imgcontent {
        width: 100%;
        height: 100%;
      }

      img {
        width: 100%;
        height: 100%;
      }
      .progress {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 100%;
        z-index: 10;
        font-size: 12px;
        color: #fff;
        text-align: center;
      }
      .mask {
        display: block;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.5);
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: 9;
        .maskContent {
          display: none;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          width: 100%;
          text-align: center;
          .icon {
            color: #ffffff;
            margin: 0 5px;
          }
        }
      }
      .maskT {
        opacity: 0;
        transition: all 0.5s;
        .maskContent {
          display: block;
        }
      }
    }
    .imgBox:hover {
      .mask {
        opacity: 1;
      }
    }
  }
}
.tableBoxIner {
  // height: 800px;
  // overflow-y: scroll;
}
.previewBox {
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  overflow: hidden;
  position: fixed;

  z-index: 999999;
  .mask {
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 1000;
    background-color: rgba(0, 0, 0, 0.5);
  }
  .closeIcon {
    position: absolute;
    top: 20px;
    right: 20px;
    font-size: 30px;
    color: #ffffff;
    z-index: 10001;
  }
  img {
    width: 80%;
    max-width: 100%;
    max-height: 100%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 10001;
  }
}
.footer {
  background: #fff;
  width: 100%;
  position: fixed;
  bottom: 0;
  text-align: center;
  height: 70px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid rgb(235, 237, 240);
}
</style>
