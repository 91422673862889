<template>
  <a-modal v-model="visible"
           width="904px"
           :bodyStyle="{'height': '550px'}"
           :keyboard="false"
           :footer="null"
           :maskClosable="false"
           :destroyOnClose="true"
           @cancel="handleCancel"
        >
    <div slot="title" class="modal-title">
      <div class="title">微信扫码登录</div>
    </div>

    <div id="wxQr" class="dlg-platform-container">
      <div class="js_status js_wx_default_tip" id="wx_default_tip">
        <div class="web_qrcode_tips">
          <svg-icon icon="icon-wechat" class="web_qrcode_tips_logo"/>
          使用微信扫一扫登录
        </div>
        <div class="web_qrcode_app_wrp">
          「<strong class="web_qrcode_app">好看鸭LOOKyah</strong>」
        </div>
        <div id="wxLoginContainer">
        </div>
      </div>
    </div>
  </a-modal>
</template>

<script>

  import $ from 'jquery'
  import * as LoginApi from '@/api/auth.api.js'

  export default {
    name: 'WechatLogin',
    components: {},
    data() {
      return {
        visible: false,
        loading: false,
        timer: null,
        uuid: null,
      }
    },
    methods: {
      showModal(data) {
        this.form = data;
        this.visible = true;

        this.$nextTick(() => {
          this.getInit();

          // 执行轮询
          this.timer = setInterval(() => {
            if(this.uuid) {
              // 需要登录校验接口
              LoginApi.loginByWechatQr({sid: this.uuid}).then(data => {
                this.$emit('handleOk', data)
                this.hideModal();
              })
            }
          }, 3000)
        })
      },
      hideModal() {
        this.visible = false;
        clearInterval(this.timer)
        this.timer = null;
      },
      handleCancel() {
        this.visible = false
        clearInterval(this.timer)
        this.timer = null;
      },
      /**
       * 获取微信二维码链接
       *
       * @param opt
       * @return {string}
       */
      getWxQrUrl(opt) {
        // let url = "https://open.weixin.qq.com/connect/qrconnect?appid=" + opt.appid + "&scope=" + opt.scope + "&redirect_uri=" + opt.redirect_uri + "&state=" + opt.state + "&login_type=jssdk&self_redirect=default" + '&styletype=' + (opt.styletype || '') + '&sizetype=' + (opt.sizetype || '') + '&bgcolor=' + (opt.bgcolor || '') + '&rst=' + (opt.rst || '');
        let url = "/openWx/connect/qrconnect?appid=" + opt.appid + "&scope=" + opt.scope + "&redirect_uri=" + opt.redirect_uri + "&state=" + opt.state + "&login_type=jssdk&self_redirect=default" + '&styletype=' + (opt.styletype || '') + '&sizetype=' + (opt.sizetype || '') + '&bgcolor=' + (opt.bgcolor || '') + '&rst=' + (opt.rst || '');
        return url;
      },

      async getInit() {
        this.uuid = this.$util.UUID();
        let wechatQrUrl = this.getWxQrUrl({
          appid: "wx7fbbdb5f6629b42b",
          scope: "snsapi_login",
          redirect_uri: "https://yikaishop.com/login",
          state: this.uuid,
          style: "",
          href: ""
        });

        await $('#wxLoginContainer').load(wechatQrUrl + ' .web_qrcode_img', (res) => {
          // console.log('=====load callback======>',res);
          // res = res.replaceAll('https://res.wx.qq.com', '/openWxRes')
          // .replaceAll('//res.wx.qq.com', '/openWxRes')
          // .replaceAll('/connect/qrcode/', '/openWx/connect/qrcode/')
          // console.log('=====handle after======>',res);

          let src = $('#wxLoginContainer .web_qrcode_img').attr('src');
          console.log('=====src======>', src);
          src = '/openWx' + src;
          $('#wxLoginContainer .web_qrcode_img').attr('src', src);
        });

      },

    },
  };
</script>

<style>
  .web_qrcode_img {
    width: 200px;
    height: 200px;
    border-radius: 4px;
  }
</style>

<style scoped lang="scss">
  .modal-title {
    display: flex;

    .title {
      font-weight: 600;
      font-size: 14px;
    }

    .hint {
      margin-left: 10px;
      font-weight: 400;
      font-size: 12px;
      color: #0000006d;
    }
  }

  .dlg-platform-container {
    height: 100%;
    padding: 10px 15px;
    overflow: auto;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .web_qrcode_tips {
    margin-right: 10px;
    font-size: 17px;
    font-weight: 400;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;

    .web_qrcode_tips_logo {
      display: inline-block;
      vertical-align: bottom;
      font-size: 24px;
      width: 1em;
      height: 1em;
      margin-right: 8px;
      background-size: cover;
      background-repeat: no-repeat;
    }
  }

  .web_qrcode_app_wrp {
    font-size: 22px;
    margin-top: 24px;
    margin-bottom: 16px;
    font-weight: 500;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    letter-spacing: 1px;
  }

</style>
