<!-- //
团队管理
@auth xuyd
@date 2022-11-01
//-->
<template>
  <div class="layout-vertical" style="font-size: 14px; font-weight: 300">
    <PageHeader :show-button="false" subTitle="可拖动团队成员修改展示顺序"></PageHeader>
    <EmployeeSearch ref="refEmpSearch" @handleOk="handleEmpSearch" />
    <EmployeeAdd ref="refEmpAdd" @handleOk="() => this.getList()" />
    <div class="container">
      <div class="container-left">
        <vuedraggable tag="div" class="wrapper" v-model="list" @end="onEnd">
          <transition-group tag="div">
            <div v-for="(item, index) in list" :key="item.userId" class="item">
              <img class="headImage" :src="ossUrl(item.headImage) + '?' + +new Date()" />
              <div class="positionName">{{ item.positionName }}</div>
              <div class="employeesName">{{ item.employeesName }}</div>
              <div class="phone">{{ item.phone }}</div>
              <div class="optionsBtns">
                <a-icon class="deleteIcon icon" type="delete" @click="handleDelete(item, index)" />
                <a-icon class="editIcon icon" type="edit" @click="handleEdit(item)" />
              </div>
            </div>
          </transition-group>
        </vuedraggable>
        <div class="wrapper">
          <div class="item" @click="handleAdd">
            <div class="item-inner">
              <a-icon class="addIcon" type="plus" />
              <div class="addText">添加成员</div>
            </div>
          </div>
        </div>
      </div>
      <div class="container-right">
        <h2>小程序展示效果（示意图）</h2>
        <p>1.添加成员后，该成员将展示在小程序端</p>
        <p>2.成员可以登录后台发布内容</p>
        <p>3.成员在后台的任何操作，操作记录都会展示</p>
        <div class="imgBox" v-if="businessType == 5">
          <img src="~@/assets/img/brandbg_01.png" alt="" />
          <img src="~@/assets/img/iPhone-13-mini-117-brand.png" alt="" />
        </div>
        <div class="imgBox" v-else>
          <img src="~@/assets/img/iPhone-13-mini-117.png" alt="" />
          <img src="~@/assets/img/iPhone-13-mini-150.png" alt="" />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import locale from 'ant-design-vue/es/date-picker/locale/zh_CN'
import * as EmployeeApi from '@/api/shop/employee.api'
import * as PostApi from '@/api/shop/post.api'
import vuedraggable from 'vuedraggable'
import EmployeeSearch from '@/components/EmployeeSearch/index'
import EmployeeAdd from '@/components/EmployeeAdd/index'
import img2 from '@/assets/img/iPhone-13-mini-117.png'
import img1 from '@/assets/img/iPhone-13-mini-150.png'
export default {
  name: 'EmployeeList',
  components: { EmployeeSearch, EmployeeAdd, vuedraggable },
  dictTypes: [
    { dictType: 'GENDER', dataType: Number },
    { dictType: 'EmployeeStatus', dataType: Number },
  ],
  data () {
    return {
      img1,
      img2,
      locale,
      loading: false,
      list: [],
      queryParams: {
        positionId: null,
        employeesName: null,
        sex: null,
        dateRange: [], //['2022-01-09','2022-11-03'],
        createStart: null,
        createEnd: null,
        page: 1,
        pageSize: 10000,
      },
    }
  },
  props: {
    contentType: Number,
  },
  computed: {
    ...mapGetters(['businessType']),
  },
  created () {
    this.getInit()
    this.getList()
  },
  methods: {
    getInit () {
      PostApi.selectPostList().then((data) => {
        this.postList = data?.list
      })
    },

    // 列表API获取
    getList () {
      this.queryParams.createStart = this.queryParams.dateRange[0] || null
      this.queryParams.createEnd = this.queryParams.dateRange[1] || null
      EmployeeApi.search(this.queryParams)
        .then((data) => {
          this.list = data.list || []
          this.total = data.total || 0
          this.loading = false
        })
        .catch((err) => {
          this.loading = false
        })
    },
    /**
     * 新增 mode=[1(新增）|2（编辑）|3（详情）]
     */
    handleAdd () {
      this.$refs.refEmpSearch.showModal()
    },
    handleEdit (item) {
      this.$refs.refEmpAdd.showModal(item, 2)
    },
    handleDelete (item, index) {
      this.$confirm({
        title: '确认删除该团队成员吗？',
        content: '删除后，该成员不能登录，在店铺介绍页也不会显示',
        okText: '确认',
        cancelText: '取消',
        onOk: () => {
          EmployeeApi.del(item.employeesId).then((res) => {
            if (res.code == 200) {
              this.list.splice(index, 1)
              this.$message.success(res.message)
            } else {
              this.$message.error(res.message)
            }
          })
        },
      })
    },
    handleEmpSearch (item) {
      // console.log("-------------->", item)
      this.$refs.refEmpAdd.showModal(item, 1)
    },
    onEnd (e) {
      const list = this.list.map((item, index) => {
        return {
          sort: index,
          employeesId: item.employeesId,
        }
      })
      EmployeeApi.sort(list).then((res) => {
        // console.log(res)
        // if (res.code === 200) {
        //   this.$message.success(res.message)
        // } else {
        //   this.$message.error(res.message)
        // }
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.container {
  display: flex;
  justify-content: space-between;
  padding: 20px;

  .container-left {
    flex: 1;

    .wrapper {
      float: left;
      margin-right: 10px;
      display: flex;
      align-items: center;
      justify-content: space-around;

      .item {
        padding: 20px;
        width: 160px;
        height: 200px;
        border: 1px solid #e7e7e7;
        text-align: center;
        cursor: pointer;
        float: left;
        position: relative;
        margin-right: 10px;
        margin-bottom: 10px;

        .item-inner {
          width: 100%;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }

        .optionsBtns {
          position: absolute;
          top: 0;
          right: 0;

          .deleteIcon {
            margin-right: 5px;
          }

          .icon {
            transition: all 0.3s;
          }

          .icon:hover {
            font-size: 20px;
          }
        }

        .headImage {
          width: 60px;
          height: 60px;
          border-radius: 50%;
          border: 1px solid #e7e7e7;
          margin-bottom: 10px;
        }

        .positionName {
          margin-bottom: 10px;
        }

        .employeesName {
          margin-bottom: 10px;
          font-weight: bold;
        }

        .phone {
          font-weight: bold;
        }

        .addIcon {
          font-size: 30px;
        }

        .addText {
          font-size: 20px;
        }
      }

      .item:last-child {
        margin-right: 0;
      }
    }

    .wrapper:last-child {
      margin-right: 0;
    }
  }

  .container-right {
    width: 772px;
    border: 1px solid #e7e7e7;
    padding: 10px;

    .imgBox {
      display: flex;
      align-items: flex-start;
    }

    img {
      width: 375px;
      height: auto;
    }
  }
}
</style>
