<!-- //
内容-视频发布-前言页面
@auth xuyd
@date 2022-09-10
//-->
<template>
  <div class="layout-vertical">
    <PageHeader :show-icon="showIcon  || !!$route.query.contentType" :show-button="false" />
    <div class="layout-result">
      <a-form-model ref="refForm" :model="form" :rules="rules" layout="vertical" class="layout-form p48">
        <a-form-model-item prop="uploadFileList">
          <span slot="label" class="form-label">上传视频</span>
          <div class="upload-file">
            <a-spin :spinning="uploading" :tip="uploadingText">
              <div class="upload-dragger">
                <a-upload-dragger name="file" accept=".mp4,.webm,.mov" :multiple="false" :show-upload-list="false" :file-list="form.uploadFileList" :before-upload="handleBeforeUpload" @change="handleChange">
                  <p class="upload-drag-icon">
                    <a-icon type="inbox" />
                  </p>
                  <p class="upload-text">点击上传或将视频拖拽到此区域进行上传</p>
                  <p class="upload-hint">仅支持单次上传</p>
                  <p class="upload-hint">*请您务必确保所上传的内容，未涉及任何侵权及违反互联网监管协议；您将承担所有法律责任，与此同时平台将保留向您追诉的法律权利</p>
                </a-upload-dragger>
              </div>
            </a-spin>
            <div class="upload-tip">
              <SimpleCard text="推荐尺寸" hint="16:9、4:3、9:16、3:4的视频"></SimpleCard>
              <SimpleCard text="视频格式" hint="支持mp4、webm、mov" style="margin: 0 24px"></SimpleCard>
              <SimpleCard text="视频大小" hint="支持时长30分钟以内，最大支持1GB视频文件"></SimpleCard>
            </div>
          </div>
        </a-form-model-item>
        <a-form-model-item v-show="false">
          <span slot="label" class="form-label">视频预览</span>

          <video ref="refVideo" width="320" height="240" controls autoplay muted crossOrigin="anonymous" v-if="true">
            <source :src="form.videoUrl" type="video/mp4" />
            您的浏览器不支持 HTML5 video 标签。
          </video>
        </a-form-model-item>

        <a-form-model-item v-show="false">
          <span slot="label" class="form-label">
            <span>封面</span>
          </span>
          <EmptyImg class="preview-img" :src="form.coverUri">
            <p slot="text">视频封面</p>
          </EmptyImg>
        </a-form-model-item>
      </a-form-model>
    </div>
  </div>
</template>

<script>
import * as OssClient from '@/components/AliOss/OssClient.js'
import SimpleCard from '@/components/SimpleCard'
import EmptyImg from '@/components/Empty'
import * as VideoUtil from '@/utils/VideoUtil'
import u20220 from '@/assets/img/u20220.png'
export default {
  name: 'VideoPreface',
  components: { SimpleCard, EmptyImg },
  props: {
    pushType: {
      type: String,
    },
  },
  data() {
    return {
      u20220: u20220,
      uploading: false,
      uploadingText: null,
      mode: null, // mode=[1(新增）|2（编辑）|3（详情）]
      showIcon: false, // 从列表进来true，菜单进来false
      form: {
        id: null,
        fileName: null, // 视频文件名称
        coverUri: null, // 视频封面地址
        videoUrl: null, // 视频地址
        shortVideoUrl: null, // 短视频地址
        uploadFileList: [], // 上传图片列表
      },
      fileUploadLimit: 1, // 文件上传限制个数
      rules: {
        uploadFileList: [{ required: true, message: '视频不能为空', trigger: 'change' }],
      },
      // TODO 无法分开两个服务，则在app启动加载
      ffmpeg: this.$ffmpeg,
      // ffmpeg: this.VideoUtil.initFFmpeg(),
      ffmpegLoading: false,
    }
  },

  created() {
    this.mode = this.$route.query.mode
    this.showIcon = !this.mode || this.mode == '1' ? false : true
    // this.loadFFmpeg()

    this.uploading = true
    this.addLoadedListener()
  },

  methods: {
    addLoadedListener() {
      let timer = setInterval(() => {
        if (!this.ffmpeg.isLoaded()) {
          this.ffmpegLoading = true
          this.uploading = true
        } else {
          this.ffmpegLoading = false
          this.uploading = false
          clearInterval(timer)
        }
      }, 100)
    },

    // 加载ffmpeg
    async loadFFmpeg() {
      if (!this.ffmpeg.isLoaded()) {
        this.ffmpegLoading = true
        console.log('加载ffmpeg开始~')
        await this.ffmpeg.load()
        console.log('加载ffmpeg完成~')
        this.ffmpegLoading = false
      }
    },

    /**
     * 处理视频进度
     * @param message
     */
    handleVideoProcessEvent(message) {
      console.log('process=========>', message)
    },

    /**
     * 上传前处理
     * true =>file.status = 'uploading' 进入onchange
     * false =>file.status = undefined' 进入onchange，执行一次
     * reject() 则停止进入 onchange
     * resolve可以继续onchange
     */
    handleBeforeUpload(file) {
      console.log('handleBeforeUpload', file)
      // if (this.ffmpegLoading) {
      if (!this.ffmpeg.isLoaded()) {
        this.$modal.alertWarning('美好的事物值得等待，请稍后再试！')
        return false
      }
      let retResult = OssClient.validateUploadFile({ file: file, bizType: 'video' })
      if (!retResult.state) {
        this.$modal.alertError('上传处理失败：' + retResult.message)
        return false
      }
      this.form.coverUri = null
      this.form.publicityVideo = null
      this.form.videoUrl = null
      this.form.shortVideoUrl = null

      // before方法中一定要设置，否则不会change验证
      this.form.uploadFileList = [file]
      this.form.fileName = file.name

      this.uploading = true
      console.log(this.ffmpeg, file)
      VideoUtil.handleVideo(this.ffmpeg, file)
        .then((jsonData) => {
          this.uploading = false
          console.log('【发视频】跳转到第二步开始', jsonData)
          if (this.pushType && this.pushType === 'brand') {
            this.$emit('toPush', jsonData)
          } else {
            this.$router.push({ name: 'VideoEdit', query: jsonData })
          }
        })
        .catch((err) => {
          this.uploading = false
          this.$modal.alertError('上传处理失败：' + err.msg)
        })

      return false
    },

    /**
     * 文件上传处理
     */
    handleChange({ file, fileList }) {
      console.log('handleChange', file)
      if (!file.status) {
        return
      }
      if (file.status === 'uploading') {
        this.$message.success(`${file.name} 正在上传`)
      } else if (file.status === 'done') {
        this.$message.success(`${file.name} 上传成功`)
      } else if (file.status === 'error') {
        this.$message.error(`${file.name} 文件上传失败`)
      }
    },

    // /**
    //  * 移除视频文件
    //  */
    // handleRemove(file) {
    //   const index = this.uploadFileList.indexOf(file);
    //   const newFileList = this.uploadFileList.slice();
    //   newFileList.splice(index, 1);
    //   this.uploadFileList = newFileList;
    //   console.log("====>handleRemove====>", this.uploadFileList)
    // },
  },

  destroyed() {
    if (this.ffmpeg) {
      try {
        // this.ffmpeg.exit()
      } catch (e) {
        // console.error(e)
      }
    }
  },
}
</script>
<style lang="scss" scoped>
.form-label {
  font-size: 16px;
  font-weight: 600;

  .tip {
    font-size: 12px;
    font-weight: 300;
  }
}

.form-value {
  min-width: 375px;
  /*max-width: 700px;*/
}

.layout-form {
  display: flex;
  flex-direction: column;

  .upload-file {
    display: flex;
    flex-direction: column;
    margin-top: 8px;

    .upload-dragger {
      height: 378px;
      width: 100%;
      /*max-width: 1126px;*/

      .upload-drag-icon {
        color: #000000a5;
        font-size: 90px;
      }

      .upload-text {
        font-size: 20px;
        color: #000000;
      }

      .upload-hint {
        font-size: 18px;
        color: #00000073;
      }
    }

    .upload-tip {
      margin-top: 24px;
      /*max-width: 1126px;*/
      display: flex;
      justify-content: space-between;
    }
  }

  .preview-img {
    width: 235px;
    height: 294px;
  }
}
</style>
