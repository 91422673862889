<template>
  <div class="material-store-container">
    <PageHeader :show-button="false"></PageHeader>
    <div class="content">
      <a-tabs v-model="tabActive" @change="changeTab">
        <a-tab-pane :tab="tab.label" v-for="tab in tabList" :key="tab.key"></a-tab-pane>
      </a-tabs>
      <a-card>
        <div class="card1">
          <a-row :gutter="20">
            <a-col :span="12">
              <a-button class=" btn-default w112" v-if="businessType == 5" type="primary" @click="handleToPush">{{ btnText }}</a-button>
            </a-col>
            <a-col :span="12">
              <a-input-search style="width: 340px" v-model="searchForm.keyword" placeholder="请输入标题/标签" class="input-search fr" @search="onSearch">
                <a-button slot="enterButton" type="primary" icon="search" class="btn-default w96">查询</a-button>
              </a-input-search>
            </a-col>
          </a-row>

        </div>
      </a-card>
      <a-card>
        <a-table :pagination="{
        total: total,
        current:searchForm.page,
        pageSize: searchForm.pageSize, // 每页中显示10条数据
        showSizeChanger: true,
        pageSizeOptions: ['10', '20', '50', '100'], // 每页中显示的数据
        showTotal: (total) => `共有 ${total} 条数据`, // 分页中显示总的数据
      }" :columns="columns" :data-source="tableData" :rowKey="(record) => record.id" @change="handleTableChange" :loading="loading">
          <template #action="text, record">
            <div v-if="businessType === 5">
              <a-button type="link" @click="handleEdit(record)">编辑</a-button>
              <a-button type="link" @click="handlePublish(record)">发布</a-button>
              <a-button type="link" @click="handleDelete(record)">删除</a-button>
            </div>
            <div v-if="businessType === 3">
              <a-button type="link" @click="handleEdit(record, 1)">预览</a-button>
              <a-button type="link" @click="handleEdit(record)">转发</a-button>
            </div>
          </template>
          <template #storeTypes="text, record">
            <div class="iconText">已共享{{ record.shareCount}}个门店</div>
            <a-button type="link" @click="viewShareStore(record)">查看共享门店</a-button>
          </template>
          <template #alreadyTranspond="text, record">
            <span>{{ record.alreadyTranspond?"是":'否' }}</span>
          </template>
          <template #coverUri="text, record">
            <img class="coverUri" :src="ossUrl(record.coverUri)" />
          </template>
        </a-table>
      </a-card>
    </div>
    <share-store ref="shareStore"></share-store>
    <select-store ref="selectStore" :submitFunction="publishFn"></select-store>
  </div>
</template>
<script>
import MaterialModel from '@/api/brandManage/material'
import ShareStore from './shareStore.vue'
import SelectStore from './selectStore.vue'
import { mapGetters } from 'vuex'
import { resolve } from 'mathjs'

export default {
  components: { ShareStore, SelectStore },
  data() {
    return {
      tabList: [
        { key: 1, label: '图文(共0条)', btnText: '发图文', path: '/material/materialPublishImageText' },
        { key: 2, label: '视频(共0条)', btnText: '发视频', path: '/material/materialPublishVideo' },
        { key: 4, label: '文章(共0条)', btnText: '发文章', path: '/material/materialPublishArticle' },
        { key: 3, label: '作品(共0条)', btnText: '发作品', path: '/material/materialpublishWorks' },
      ],
      tabActive: 1,

      loading: false,
      // 查询参数
      searchForm: {
        page: 1,
        pageSize: 10,
        keyword: '',
        contentType: 1,
        userId: '',
      },
      total: 0,
      tableData: [],
    }
  },
  computed: {
    ...mapGetters(['businessType']),
    btnText: function () {
      let obj = this.tabList.find((item) => item.key == this.tabActive)
      return obj ? obj.btnText : ''
    },
    columns: function () {
      let arr1 = [
        {
          title: '封面',
          dataIndex: 'coverUri',
          scopedSlots: { customRender: 'coverUri' },
        },
        {
          title: '标题',
          dataIndex: 'title',
          ellipsis: true,
        },
        {
          title: '标签',
          dataIndex: 'tabNames',
          ellipsis: true,
        },
      ]
      let arr2 = [
        {
          title: '创建时间',
          dataIndex: 'createTime',
          scopedSlots: { customRender: 'createTime' },
          ellipsis: true,
        },

        {
          title: '操作',
          dataIndex: 'action',
          fixed: 'right',
          scopedSlots: { customRender: 'action' },
          width: 240,
        },
      ]
      if (this.businessType == 5) {
        return arr1.concat(
          [
            {
              title: '共享素材到门店',
              dataIndex: 'storeTypes',
              scopedSlots: { customRender: 'storeTypes' },
              align: 'center',
            },
          ],
          arr2
        )
      } else {
        return arr1.concat(
          [
            {
              title: '是否转发',
              dataIndex: 'alreadyTranspond',
              scopedSlots: { customRender: 'alreadyTranspond' },
              align: 'center',
              ellipsis: true,
            },
          ],
          arr2
        )
      }
    },
  },
  mounted() {
    if (this.businessType === 3) {
      this.tabList = [
        { key: 1, label: '图文(共0条)', btnText: '发图文', path: '/content/trendPublish/edit' },
        { key: 2, label: '视频(共0条)', btnText: '发视频', path: '/content/videoPublish/edit' },
        { key: 4, label: '文章(共0条)', btnText: '发文章', path: '/content/articlePublish/edit' },
        { key: 3, label: '作品(共0条)', btnText: '发作品', path: '/content/productionPublish/edit' },
      ]
    }
    this.searchForm.userId = this.$store.state.user.userSession.userId
    let query = this.$route.query
    if (query.contentType) {
      this.tabActive = +query.contentType
      this.searchForm.contentType = +query.contentType
    }
    this.getSummary()
    this.initData()
  },
  methods: {
    getSummary() {
      MaterialModel.summary().then((data) => {
        this.tabList[0].label = `图文(共${data.trendType1Count || 0}条)`
        this.tabList[1].label = `视频(共${data.trendType2Count || 0}条)`
        this.tabList[2].label = `文章(共${data.trendType4Count || 0}条)`
        this.tabList[3].label = `作品(共${data.trendType3Count || 0}条)`
      })
    },
    initData() {
      MaterialModel.list(this.searchForm).then((res) => {
        this.tableData = res.list
        this.total = res.total
      })
    },
    changeTab(e) {
      this.searchForm = {
        page: 1,
        pageSize: 10,
        keyword: '',
        contentType: e,
        userId: '',
      }
      this.tabActive = e
      this.initData()
    },
    onSearch() {
      this.searchForm.page = 1
      this.searchForm.pageSize = 10
      this.initData()
    },
    handleTableChange(pagination, filters, sorter) {
      this.searchForm.page = pagination.current
      this.searchForm.pageSize = pagination.pageSize
      this.initData()
    },
    viewShareStore(record) {
      this.$refs.shareStore.onShow(record)
    },
    handlePublish(record) {
      let params = {
        materialId: record.id,
        storesType: record.storesType,
      }
      this.$refs.selectStore.onShow(params)
    },
    publishFn(form) {
      return new Promise((resolve, reject) => {
        MaterialModel.reReleaseMaterial(form)
          .then((res) => {
            this.initData()
            this.getSummary()
            resolve()
          })
          .catch(() => {
            reject()
          })
      })
    },
    handleDelete(record) {
      this.$confirm({
        title: '是否删除？',
        content: '删除会其他门店在素材库无法查询到该动态，请注意！',
        cancelText: '取消',
        okText: '确定',
        onOk: () => {
          MaterialModel.del(record.id).then(() => {
            this.$message.success('删除成功')
            this.initData()
            this.getSummary()
          })
        },
      })
    },
    handleToPush() {
      let obj = this.tabList.find((item) => item.key === this.tabActive)
      if (obj) {
        this.$router.push({
          path: obj.path,
          query: {
            contentType: this.searchForm.contentType,
          },
        })
      }
    },
    handleEdit(record = null, isPreview = 0) {
      let obj = this.tabList.find((item) => item.key === this.tabActive)
      if (obj) {
        this.$router.push({
          path: obj.key == 2 ? '/material/materialPublishVideo_push' : obj.path,
          query: {
            mode: this.businessType == 3 ? '999' : '2',
            id: record.id,
            pageNum: 1,
            queryType: 2,
            contentType: this.searchForm.contentType,
            isPreview: isPreview,
          },
        })
      }
    },
  },
}
</script>
<style lang="scss" scoped>
.content {
  padding: 20px;
  .card {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .coverUri {
    width: 98px;
    height: 98px;
  }
  .iconText {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .iconText::before {
    content: '';
    display: inline-block;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background: green;
    margin-right: 8px;
  }
}
</style>
