<template>
  <a-modal v-model="visible" @ok="handleSubmit" :confirmLoading="loading">
    <div class="title" slot="title">
      <h4>发布动态</h4>
      <span>选择可预览的门店类型</span>
    </div>
    <div class="content">
      <h3 class="mb20">请选择可预览的门店类型</h3>
      <a-select v-model="storesType" style="width: 300px" placeholder="请选择门店类型" mode="multiple" @change="changeSelect" option-label-prop="label">
        <a-select-option :value="item.dictValue" v-for="item in $dictMap.STORETYPE" :key="item.dictValue" :label="item.dictText">
          <a-checkbox :checked="storesType.includes(item.dictValue)">{{ item.dictText }}({{ item.count }})</a-checkbox>
        </a-select-option>
      </a-select>
    </div>
  </a-modal>
</template>
<script>
import StoreModel from '@/api/brandManage/store'
import materialModel from '@/api/brandManage/material'
export default {
  props: {
    submitFunction: Function,
  },
  data() {
    return {
      visible: false,
      storesType: [],
      form: null,
      loading: false,
      storesData: [],
    }
  },
  created() {
    StoreModel.bindStoresCount().then((res) => {
      this.$dictMap.STORETYPE[0].count = res.zydCount
      this.$dictMap.STORETYPE[1].count = res.fxsCount
      this.$dictMap.STORETYPE[2].count = res.jxsCount
      this.$dictMap.STORETYPE[3].count = res.other
    })
  },
  methods: {
    onShow(form) {
      this.form = form
      this.storesType = form.storesType || []
      this.visible = true
    },
    handleSubmit() {
      this.loading = true
      this.form.storesType = this.storesType
      if (this.submitFunction) {
        return this.submitFunction(this.form).then(() => {
          this.cancel()
        })
      }
      materialModel[this.form.id ? 'editMaterial' : 'addTrend'](this.form).then(() => {
        this.cancel()
      })
    },
    cancel() {
      this.$message.success('发布成功！')
      this.visible = false
      this.loading = false
      this.$router.push({
        path: '/material/materialStore',
        query: { contentType: this.$route.query.contentType || this.form.contentType },
      })
    },
    changeSelect() {
      console.log(this.storesType)
    },
  },
}
</script>
<style lang="scss" scoped>
.title {
  display: flex;
  align-items: center;
  h4 {
    font-weight: bold;
    margin: 0 20px 0 0;
  }
  span {
    font-size: 12px;
  }
}
.content {
  text-align: center;
}
</style>
