<!--
@author: binchen
@desc：修改认证信息
@date: 2023-04-29 12:17:35
-->
<template>
  <a-modal :title="businessType === 1 && businessTag === 1 ? '修改实名认证' : '修改企业认证信息'" width="698px" :visible="visible" :confirm-loading="confirmLoading" @ok="handleOk" @cancel="handleCancel">
    <a-form v-if="businessType === 1 && businessTag === 1" :form="form" :label-col="{ span: 3 }" :wrapper-col="{ span: 21 }" labelAlign="left">
      <a-form-item label="真实姓名">
        <a-input style="width: 280px" placeholder="请输入" v-model="form.personalRealInformation.realName" />
      </a-form-item>
      <a-form-item style="margin-left: 80px" :wrapper-col="{ span: 24 }">
        <div class="authenticationModalUploadBox">
          <Upload width="251px" height="177px" class="upload" @success="(e) => uploadSuccess(e, 'handIdentityCard')">
            <div class="content" slot="content" style="width: 251px; height: 177px">
              <img class="img" v-if="form.personalRealInformation.handIdentityCard" :src="ossUrl(form.personalRealInformation.handIdentityCard)" />
              <span v-else>点击上传手持身份证图片</span>
            </div>
          </Upload>
          <Upload width="251px" height="177px" class="upload" @success="(e) => uploadSuccess(e, 'frontIdentityCard')">
            <div class="content" slot="content" style="width: 251px; height: 177px">
              <img class="img" v-if="form.personalRealInformation.frontIdentityCard" :src="ossUrl(form.personalRealInformation.frontIdentityCard)" />
              <span v-else>点击上传身份证人像面</span>
            </div>
          </Upload>
          <Upload width="251px" height="177px" class="upload" @success="(e) => uploadSuccess(e, 'backIdentityCard')">
            <div class="content" slot="content" style="width: 251px; height: 177px">
              <img class="img" v-if="form.personalRealInformation.backIdentityCard" :src="ossUrl(form.personalRealInformation.backIdentityCard)" />
              <span v-else>点击上传身份证国徽面</span>
            </div>
          </Upload>
        </div>
      </a-form-item>
    </a-form>
    <a-form v-else :form="form" :label-col="{ span: 3 }" :wrapper-col="{ span: 21 }" labelAlign="left">
      <a-form-item label="企业名称">
        <a-input style="width: 280px" placeholder="请输入" v-model="form.realAuthentication.enterpriseName" />
      </a-form-item>
      <a-form-item label="营业执照">
        <Upload width="226px" height="279px" ref="upload" @success="(e) => uploadSuccess(e, 'busiLicensePic')">
          <div class="content" slot="content" style="width: 226px; height: 279px">
            <img class="img" v-if="form.realAuthentication.busiLicensePic" :src="ossUrl(form.realAuthentication.busiLicensePic)" />
            <span v-else>点击上传图片</span>
          </div>
        </Upload>
        <a-input style="width: 280px; margin-bottom: 24px" placeholder="请输入社会统一信用代码" v-model="form.realAuthentication.busiLicenseCode" />
        <br />
        <a-input style="width: 280px" placeholder="请输入营业执照有效期" v-model="form.realAuthentication.busiLicenseAvailable" />
      </a-form-item>
      <a-form-item label="法人信息">
        <a-input style="width: 280px" placeholder="请输入法人名称" v-model="form.realAuthentication.legalmanLicenceName" />
        <div class="authenticationModalUploadBox">
          <Upload width="251px" height="177px" class="upload" @success="(e) => uploadSuccess(e, 'legalmanLicenceHandpic')">
            <div class="content" slot="content" style="width: 251px; height: 177px">
              <img class="img" v-if="form.realAuthentication.legalmanLicenceHandpic" :src="ossUrl(form.realAuthentication.legalmanLicenceHandpic)" />
              <span v-else>点击上传手持身份证图片</span>
            </div>
          </Upload>
          <Upload width="251px" height="177px" class="upload" @success="(e) => uploadSuccess(e, 'legalmanLicencePic')">
            <div class="content" slot="content" style="width: 251px; height: 177px">
              <img class="img" v-if="form.realAuthentication.legalmanLicencePic" :src="ossUrl(form.realAuthentication.legalmanLicencePic)" />
              <span v-else>点击上传身份证人像面</span>
            </div>
          </Upload>
          <Upload width="251px" height="177px" class="upload" @success="(e) => uploadSuccess(e, 'legalmanLicenceBackpic')">
            <div class="content" slot="content" style="width: 251px; height: 177px">
              <img class="img" v-if="form.realAuthentication.legalmanLicenceBackpic" :src="ossUrl(form.realAuthentication.legalmanLicenceBackpic)" />
              <span v-else>点击上传身份证国徽面</span>
            </div>
          </Upload>
        </div>
      </a-form-item>
    </a-form>
  </a-modal>
</template>

<script>
import { mapGetters } from 'vuex'
import Upload from '@/components/Upload'
export default {
  name: 'AuthenticationModal',
  components: { Upload },
  data() {
    return {
      visible: false,
      confirmLoading: false,
      form: {
        homepageInformation: null,
        shopEnvironment: null,
        shopHistoryInformation: null,
        brandInformation: null,
        shopAddressInformation: null,
        idAuthenticationParam: null,
        realAuthentication: {
          legalmanLicenceName: '', // 企业真实性认证-法人名称
          legalmanLicenceBackpic: '', // 企业真实性认证-法人身份证背面图片
          busiLicenseCode: '', // 企业真实性认证-社会统一信用代码
          legalmanLicenceHandpic: '', // 企业真实性认证-法人手持身份证图片
          busiLicensePic: '', // 企业真实性认证-营业执照
          busiLicenseAvailable: '', // 企业真实性认证-营业执照有效期
          legalmanLicenceCode: '', // 企业真实性认证-法人身份证号码
          legalmanLicencePic: '', // 企业真实性认证-法人身份证正面图片
          enterpriseName: '', // 企业真实性认证-企业名称
          enterpriseState: 0,
        },
        personalRealInformation: {
          realName: '',
          backIdentityCard: '',
          frontIdentityCard: '',
          handIdentityCard: '',
        },
        backgroundPicInformation: null,
        experience: null,
        introductionInformation: null,
      },
    }
  },
  computed: {
    ...mapGetters(['businessType', 'businessTag', 'businessUserId']),
  },
  created() {},
  mounted() {},
  methods: {
    onShow(form) {
      if (this.businessType === 1 && this.businessTag === 1) {
        if (form.personalRealInformation.auditState === 0) {
          return this.$message.info('信息审核中耐心等待')
        }
        this.form.personalRealInformation = form.personalRealInformation
      } else {
        if (form.realAuthentication.auditState === 0) {
          return this.$message.info('信息审核中耐心等待')
        }
        this.form.realAuthentication = form.realAuthentication
      }

      this.visible = true
    },
    handleOk() {
      this.confirmLoading = true
      this.$emit('submit', this.form, () => {
        this.confirmLoading = false
        this.handleCancel()
      })
    },
    handleCancel() {
      Object.assign(this.$data, this.$options.data.call(this))
      this.visible = false
    },
    uploadSuccess(e, filed) {
      if (this.businessType === 1 && this.businessTag === 1) {
        this.form.personalRealInformation[filed] = e
      } else {
        this.form.realAuthentication[filed] = e
      }
    },
  },
}
</script>
<style></style>
<style lang="scss" scoped>
.inputBox {
  position: relative;
  .textarea {
    padding-bottom: 20px;
  }
  .suffix {
    position: absolute;
    bottom: 5px;
    right: 15px;
    background-color: #ffffff;
  }
}
.content {
  display: flex;
  align-items: center;
  justify-content: center;
}
.authenticationModalUploadBox {
  margin-top: 20px;

  .upload {
    float: left;
    margin-right: 20px;
    margin-bottom: 20px;
  }
}
.img {
  max-width: 100%;
  max-height: 100%;
}
</style>
