<!-- //
岗位管理
@auth xuyd
@date 2022-11-01
//-->
<template>
  <div class="layout-vertical" style="font-size: 14px; font-weight: 300;">
    <PageHeader :show-button="false"></PageHeader>
    <div class="layout-search p24-bottom-half">
      <div style="border: 1px solid #efefef" class="">
        <a-form-model ref="refForm"
                      :model="queryParams"
                      layout="inline"
                      class="layout-form p24" :colon="false">
          <a-row class="form-row">
            <a-form-model-item>
              <a-button type="primary" @click="handleAdd">新增岗位</a-button>
            </a-form-model-item>
          </a-row>
          <a-row class="form-row" v-if="false">
            <a-form-model-item label="员工姓名" prop="userName">
              <a-input v-model="queryParams.userName" style="width: 250px;" :maxLength="20"></a-input>
            </a-form-model-item>
            <a-form-model-item label="联系方式" prop="phone">
              <a-input v-model="queryParams.phone" style="width: 250px;" :maxLength="11"></a-input>
            </a-form-model-item>
            <a-form-model-item label="操作模块" prop="operatorModule">
              <a-select v-model="queryParams.operatorModule" style="width: 250px;">
                <a-select-option v-for="(item,index) in moduleList" :value="item.moduleId" :key="index">
                  {{item.moduleName}}
                </a-select-option>
              </a-select>
            </a-form-model-item>
          </a-row>
          <a-row class="form-row-end" v-if="false">
            <a-form-model-item label="创建时间" prop="dateRange">
              <a-range-picker v-model="queryParams.dateRange" :locale="locale" format="YYYY-MM-DD"/>
            </a-form-model-item>
            <a-form-model-item>
              <a-button type="primary" :ghost="true" @click="handleQuery">查询</a-button>
              <a-button style="margin-left: 20px" @click="handleReset">重置</a-button>
            </a-form-model-item>
          </a-row>
        </a-form-model>
      </div>
    </div>

    <div class="layout-result p24-top-half">
      <a-table :pagination='false'
               :columns="columns"
               :data-source="list"
               :scroll="{ x: '1000px' }"
               :rowKey="(record) => record.positionId"
      >
        <span slot="action" slot-scope="text, record">
          <template>
            <a @click="handleEdit(record)" class="action">编辑</a>
            <a @click="handleDelete(record)" class="action">删除</a>
          </template>
        </span>
      </a-table>
      <pagination :total="total" :pageNum.sync="queryParams.page" :pageSize.sync="queryParams.pageSize" @pagination="getList"/>

    </div>
    <BackTop/>
  </div>
</template>
<script>
  import locale from 'ant-design-vue/es/date-picker/locale/zh_CN';
  import * as PostApi from '@/api/shop/post.api'

  const columns = [
    {title: '岗位名称', dataIndex: 'positionName', width: 110, align: 'center',},
    {title: '岗位人数', dataIndex: 'employeesNum', width: 110, align: 'center',},
    {title: '操作', dataIndex: 'action', width: 150, align: 'center', fixed: 'right', scopedSlots: {customRender: 'action'}},
  ]

  export default {
    name: 'PostList',
    components: {},
    dictTypes: [
    ],
    data() {
      return {
        locale,
        loading: false,
        queryParams: {
          // userName: null,
          // phone: null,
          // operatorModule: null,
          // dateRange: [], //['2022-01-09','2022-11-03'],
          // operatorStart: null,
          // operatorEnd: null,
          page: 1,
          pageSize: 10,
        },
        moduleList: [],

        columns: columns,
        list: [],
        total: 0,
      };
    },
    created() {
      this.getInit();
      this.getList();
    },
    methods: {
      getInit() {
      },

      // 列表API获取
      getList() {
        // this.queryParams.createDateBegin = this.queryParams.dateRange[0] || null
        // this.queryParams.createDateEnd = this.queryParams.dateRange[1] || null
        PostApi.search(this.queryParams).then(data => {
          // console.log(data)
          this.list = data.list || []
          this.total = data.total || 0
          this.loading = false
        }).catch(err => {
          this.loading = false
        })
      },

      /**
       * 搜索查询
       */
      handleQuery() {
        this.loading = true
        this.queryParams.page = 1;
        this.getList()
      },

      handleReset() {
        this.$refs['refForm'].resetFields()
      },

      /**
       * 新增 mode=[1(新增）|2（编辑）|3（详情）]
       */
      handleAdd() {
        this.$router.push({name: 'PostEdit', query: {mode: '1', id: null}})
      },
      handleEdit(record) {
        this.$router.push({name: 'PostEdit', query: {mode: '2', id: record.positionId}})
      },
      // 删除
      handleDelete(record) {
        this.$modal.confirm(`删除会将关联该岗位的员工取消关联，请注意！`, '是否删除该岗位？', {iconType: 'exclamation-circle'}).then(() => {
          PostApi.del(record.positionId).then(data => {
            this.$modal.msg("删除成功")
            this.getList();
          }).catch(err => {
            console.log(err);
          })
        })
      },

    }
  }
</script>

<style lang="scss" scoped>

  .form-row {
    margin-bottom: 15px;
  }

  .error {
    color: #FF0000
  }

  .primary {
    color: #1890ff;
  }

  .form-row-end {
    margin-bottom: 0;
  }

  .favorite {
    i {
      font-size: 18px;
      width: 18px;
      height: 18px;
    }

    .text {
      margin-left: 5px;
    }
  }

  .action {
    margin: 0 5px;
  }

  .modal-title {
    display: flex;

    .title {
      font-weight: 600;
      font-size: 14px;
    }

    .hint {
      margin-left: 10px;
      font-weight: 400;
      font-size: 12px;
      color: #0000006d;
    }
  }
</style>
