<template>
  <a-modal v-model="visible" class="mymodal" width="1200px" @cancel="handleCancel" @ok="handleSubmit">
    <div class="title" slot="title">
      <h4>新增门店</h4>
      <span> 创建新门店,创建后门店即可登录</span>
    </div>
    <div class="content">
      <div class="content-left">
        <a-form-model :model="form" ref="form" :rules="rules" :label-col="{ span: 4 }" :wrapper-col="{ span: 18 }">
          <a-form-model-item label="绑定手机号" prop="bindPhone">
            <a-input type="number" style="width: 250px" v-model="form.bindPhone" placeholder="请输入" />
          </a-form-model-item>
          <a-form-model-item label="登录密码" prop="password">
            <a-input v-model="form.password" style="width: 250px" placeholder="请输入" />
          </a-form-model-item>
          <a-form-model-item label="选择门店类型" prop="storesType">
            <a-select v-model="form.storesType " style="width: 250px;">
              <a-select-option :value="item.dictValue" v-for="item in $dictMap.STORETYPE" :key="item.dictValue">
                {{ item.dictText }}
              </a-select-option>

            </a-select>
          </a-form-model-item>
          <a-form-model-item label="门店Logo" prop="brandLogoPic">
            <Upload ref="brandLogoPic" isRound width="66px" height="66px" @success="(e) => uploadSuccess(e, 'brandLogoPic')" />
          </a-form-model-item>
          <a-form-model-item label="品牌名" prop="brandName">
            <a-input v-model="form.brandName" style="width: 250px;" placeholder="请输入" />
          </a-form-model-item>
          <a-form-model-item label="线下实体店地址">
            <olMap ref="olMap" label="线下实体店地址" @move="move" @select="selectLocation" />
          </a-form-model-item>
          <a-form-model-item label="工作时间">
            <div class="timepicker">
              <a-select style="width: 250px;" placeholder="开始日期" v-model="form.shopAddressInformation.workDaysBegin">
                <a-select-option v-for="week in weekOptions" :key="week.label">
                  {{ week.label }}
                </a-select-option>
              </a-select>

              <span style="margin: 0 20px">至</span>
              <a-select style="width: 250px" placeholder="结束日期" v-model="form.shopAddressInformation.workDaysEnd">
                <a-select-option v-for="week in weekOptions" :key="week.label">
                  {{ week.label }}
                </a-select-option>
              </a-select>
            </div>
            <div class="timepicker">
              <a-time-picker style="width: 250px" format="HH:mm" placeholder="开始时间" v-model="form.shopAddressInformation.workTimeBegin" />
              <span style="margin: 0 20px">至</span>
              <a-time-picker style="width: 250px" format="HH:mm" placeholder="结束时间" v-model="form.shopAddressInformation.workTimeEnd" />
            </div>
          </a-form-model-item>
          <a-form-model-item label="门店联系电话" prop="contactsLandline">
            <a-input v-model="form.contactsLandline" style="width: 250px" placeholder="请输入" />
          </a-form-model-item>
          <a-form-model-item label="门店联系手机号" prop="contactsPhone">
            <a-input v-model="form.contactsPhone" style="width: 250px" placeholder="请输入" />
          </a-form-model-item>
          <a-row>
            <a-col :span="6">
              <a-form-model-item label="商标注册证" prop="brandRegistPic" :label-col="{ span: 16 }" :wrapper-col="{ span: 8 }">
                <Upload ref="brandRegistPic" width="66px" height="66px" @success="(e) => uploadSuccess(e, 'brandRegistPic')" />
              </a-form-model-item>
            </a-col>
            <a-col :span="12">
              <a-form-model-item label="商标授权书" prop="brandAuthorizePic" :label-col="{ span: 12 }" :wrapper-col="{ span: 10 }">
                <Upload ref="brandAuthorizePic" width="66px" height="66px" @success="(e) => uploadSuccess(e, 'brandAuthorizePic')" />
              </a-form-model-item>
            </a-col>
          </a-row>

          <a-form-model-item :colon="false">
            <div slot="label">
              <div>主页背景图：</div>
              <div class="labelTip">（不填写使用默认背景）</div>
            </div>
            <Upload ref="bgm" width="66px" height="66px" @success="(e) => uploadSuccess(e, 'bgm')" />
          </a-form-model-item>
          <a-form-model-item label="主页名称" prop="shopName">
            <a-input v-model="form.shopName" style="width: 250px" placeholder="请输入，可跟品牌名称一致" />
          </a-form-model-item>
          <a-form-model-item label="企业名称">
            <a-input v-model="form.enterpriseName" style="width: 250px" placeholder="请输入,品牌的企业名称" />
            <Upload ref="busiLicensePic" style="margin-top: 20px" width="153px" height="86px" uploadText="点击上传营业执照三证合一" @success="(e) => uploadSuccess(e, 'busiLicensePic')" />
          </a-form-model-item>
          <a-form-model-item label="社会统一信用代码">
            <a-input v-model="form.busiLicenseCode" style="width: 250px" placeholder="请输入，社会统一信用代码" />
          </a-form-model-item>
          <a-form-model-item label="营业执照有效期">
            <a-input v-model="form.busiLicenseAvailable" style="width: 250px" placeholder="请输入" />
          </a-form-model-item>
          <a-form-model-item label="法人姓名">
            <a-input v-model="form.legalmanLicenceName" style="width: 250px" placeholder="请输入" />
            <div class="box" style="margin-top: 20px">
              <Upload ref="handIdentityCard" class="item" width="153px" height="86px" :showIcon="false" uploadText="点击上传手持身份证图片" @success="(e) => uploadSuccess(e, 'handIdentityCard')" />
              <Upload ref="frontIdentityCard" class="item" width="153px" height="86px" :showIcon="false" uploadText="点击上传身份证正面图片" @success="(e) => uploadSuccess(e, 'frontIdentityCard')" />
              <Upload ref="backIdentityCard" class="item" width="153px" height="86px" :showIcon="false" uploadText="点击上传身份证反面图片" @success="(e) => uploadSuccess(e, 'backIdentityCard')" />
            </div>
          </a-form-model-item>
          <a-form-model-item label="简介" prop="intro">
            <div class="textareaBox">
              <textarea v-model="form.intro" placeholder="简介认证不超过100字符，含标点和空格" id="myTextarea" maxlength="100" rows="4" @input="limitInput"></textarea>
              <div class="tip">{{ form.intro.length }} / 100</div>
            </div>
          </a-form-model-item>
        </a-form-model>

      </div>
      <div class="content-right">
        <img src="@/assets/img/brandbg_01.png">
      </div>
    </div>

  </a-modal>
</template>
<script>
import olMap from '@/components/olMap/olMapByInput.vue'
import Upload from '@/components/Upload'
import StoreModel from '@/api/brandManage/store'
import { cloneDeep } from 'lodash'
function getLocation() {
  return new Promise((resolve, reject) => {
    console.log(navigator)
    if ('geolocation' in navigator) {
      navigator.geolocation.getCurrentPosition(
        function (position) {
          console.log(position)
          var latitude = position.coords.latitude
          var longitude = position.coords.longitude
          resolve({ latitude, longitude })
        },
        function (error) {
          console.error('获取位置失败: ' + error.message)
          reject(null)
        }
      )
    } else {
      console.error('Geolocation 不受支持')
      reject(null)
    }
  })
}

import moment from 'moment'
export default {
  components: { Upload, olMap },
  data() {
    let validatePass2 = (rule, value, callback) => {
      if (!this.form.legalmanLicenceName) {
        callback(new Error('请输入真实姓名'))
      } else if (!this.form.handIdentityCard) {
        callback(new Error('请上传手持身份证图'))
      } else if (!this.form.frontIdentityCard) {
        callback(new Error('请上传身份证正面'))
      } else if (!this.form.backIdentityCard) {
        callback(new Error('请上传身份证反面'))
      } else {
        callback()
      }
    }
    let validatePass1 = (rule, value, callback) => {
      if (!value) {
        callback('请输入密码')
      } else if (value.length < 8) {
        callback(new Error('密码长度至少为8位数'))
      } else {
        callback()
      }
    }
    let validatePass3 = (rule, value, callback) => {
      let reg = /^1[3456789]\d{9}$/
      if (!value) {
        callback('请输入手机号')
      } else if (!reg.test(value)) {
        callback(new Error('手机号格式不正确'))
      } else {
        callback()
      }
    }
    return {
      visible: false,
      phone: '',
      weekOptions: [
        { label: '周一', value: '1' },
        { label: '周二', value: '2' },
        { label: '周三', value: '3' },
        { label: '周四', value: '4' },
        { label: '周五', value: '5' },
        { label: '周六', value: '6' },
        { label: '周日', value: '7' },
      ],
      form: {
        intro: '',
        shopAddressInformation: {
          workTimeBegin: moment('08:00', 'HH:mm'),
          shopAdressDetail: null,
          shopAdress: '',
          latitude: 0,
          workTimeEnd: moment('17:30', 'HH:mm'),
          workDaysBegin: '周一',
          workDaysEnd: '周五',
          longitude: 0,
        },
      },
      rules: {
        bindPhone: [
          { required: true, message: '请输入手机号', trigger: 'blur' },
          { required: true, validator: validatePass3, trigger: 'change' },
        ],
        password: [{ required: true, validator: validatePass1, trigger: 'change' }],
        storesType: [{ required: true, message: '请上选择门店类型', trigger: 'change' }],
        brandLogoPic: [{ required: true, message: '请上传门店logo', trigger: 'change' }],
        backIdentityCard: [{ required: true, message: '请上传身份证反面', trigger: 'change' }],

        brandName: [{ required: true, message: '请输入品牌名称', trigger: 'blur' }],
        contactsLandline: [{ required: true, message: '请输入门店联系电话', trigger: 'blur' }],
        contactsPhone: [{ required: true, message: '请输入门店手机号', trigger: 'blur' }],

        intro: [{ required: true, message: '请输入简介', trigger: 'blur' }],
        brandRegistPic: [{ required: true, message: '请上传商标注册证', trigger: 'change' }],
        brandAuthorizePic: [{ required: true, message: '请上传商标授权书', trigger: 'change' }],
        shopName: [{ required: true, message: '请输入主页名称', trigger: 'blur' }],
      },
    }
  },
  methods: {
    onShow(phone) {
      // 1829799364
      this.visible = true
      this.form.bindPhone = phone
      this.$nextTick(() => {
        this.$refs.olMap.setCenter(121.64380073547363, 31.25814757731578)
        getLocation()
          .then((res) => {
            console.log('当前位置：', res)
            this.$refs.olMap.location.lat = res.latitude
            this.$refs.olMap.location.lng = res.longitude
            this.$refs.olMap.setCenter(res.longitude, res.latitude)
          })
          .catch((err) => {
            console.log('获取当前位置失败，请韵如浏览器获取您的当前位置')
          })
      })
    },
    handleCancel() {
      for (const key in this.$refs) {
        if (Object.hasOwnProperty.call(this.$refs, key)) {
          const ref = this.$refs[key]
          if (ref.fileList) {
            ref.fileList = []
            ref.imageUrl = ''
          }
        }
      }
      this.form = {
        intro: '',
        shopAddressInformation: {
          workTimeBegin: moment('08:00', 'HH:mm'),
          shopAdressDetail: null,
          shopAdress: '',
          latitude: 0,
          workTimeEnd: moment('17:30', 'HH:mm'),
          workDaysBegin: '周一',
          workDaysEnd: '周五',
          longitude: 0,
        },
      }
      this.$refs.olMap.reset()
      this.$refs.form.resetFields()
      this.visible = false
    },
    handleSubmit() {
      let params = cloneDeep(this.form)
      params.shopAddressInformation.workTimeBegin = moment(params.shopAddressInformation.workTimeBegin).format('HH:mm')
      params.shopAddressInformation.workTimeEnd = moment(params.shopAddressInformation.workTimeEnd).format('HH:mm')
      console.log(params)
      this.$refs.form.validate((valid) => {
        if (valid) {
          StoreModel.create(params).then((res) => {
            this.$message.success('创建成功！')
            this.handleCancel()
            this.$emit('success')
          })
        }
      })
    },
    uploadSuccess(e, type) {
      this.form[type] = e
    },
    move(e) {
      console.log(e)
      this.selectLocation(e)
    },
    selectLocation(e) {
      console.log(e)
      this.form.shopAddressInformation.longitude = e.lng
      this.form.shopAddressInformation.latitude = e.lat
      this.form.shopAddressInformation.shopAdress = e.address || e.adress
      this.form.shopAddressInformation.shopAdressDetail = e.address || e.adress
    },
    limitInput(textarea) {
      // 限制字符数为 100
      const maxLength = 100
      if (textarea.target.value.length > maxLength) {
        textarea.value = textarea.target.value.slice(0, maxLength)
      }

      // 限制行数为 4
      const maxRows = 4
      const rows = textarea.target.value.split('\n').length
      if (rows > maxRows) {
        const lastIndex = textarea.target.value.lastIndexOf('\n')
        textarea.target.value = textarea.target.value.slice(0, lastIndex)
      }
    },
  },
}
</script>
<style lang="scss" scoped>
.title {
  display: flex;
  align-items: center;

  h4 {
    font-weight: bold;
    margin: 0 20px 0 0;
  }

  span {
    font-size: 12px;
  }
}

.content {
  height: 800px;
  overflow: auto;
  display: flex;
  .content-left {
    flex: 1;
    margin-right: 20px;
    .labelTip {
      font-size: 12px;
      color: #746e6e;
      font-weight: normal;
      line-height: 12px;
    }
    #myTextarea {
      width: 100%;
      padding-bottom: 10px;
    }
    .textareaBox {
      position: relative;
      .tip {
        position: absolute;
        bottom: 5px;
        right: 15px;
      }
    }
    .box {
      display: flex;
      justify-content: left;
      .item {
        margin-right: 20px;
      }
    }
  }
  .content-right {
    width: 360px;
    img {
      width: 100%;
    }
  }
}
</style>
