<template>
  <div class="message">
    <PageHeader show-icon :show-button="false" :showConfirm="false"></PageHeader>
    <div class="message-container">
      <a-tabs v-model="activeKey" type="card" hideAdd @change="changeTab">
        <div slot="tabBarExtraContent">
          <a-button type="link" @click="handleRead()">全部已读</a-button>
        </div>
        <a-tab-pane v-for="pane in paneList" :key="pane.key">
          <span slot="tab">
            {{ pane.title }}
            <span>{{ pane.total }}</span>
          </span>
        </a-tab-pane>
      </a-tabs>
      <a-list item-layout="horizontal" :data-source="messageList">
        <a-list-item slot="renderItem" slot-scope="item">
          <a-list-item-meta>
            <div class="item" slot="title">
              <img :src="ossUrl(item.sendHeadshot)" />

              <h4 class="title text">
                <a-tooltip :title="item.title">{{ item.title }}</a-tooltip>
              </h4>
              <div class="content text">
                <a-tooltip :title="item.content">{{ item.contentText }}</a-tooltip>
              </div>
              <div class="time">{{moment(item.readTime).format('YYYY-MM-DD HH:mm:ss')}}</div>
              <div style="width: 240px;text-align: right;">
                <span v-if="item.msgType==5">
                  <a-button type="link" @click="handleApplyState(1,item.id)" v-if="item.applyStatus==0">确认</a-button>
                  <a-button type="link" @click="handleApplyState(2,item.id)" v-if=" item.applyStatus==0">取消</a-button>
                  <a-button type="link" disabled v-if="item.applyStatus!=0" :style="{color: item.applyStatus!=1?'red':''}">{{ item.applyStatus==1?"已通过":"已拒绝" }}</a-button>
                </span>

                <a-button type="link" @click="handleRead(item)" v-if="item.state===1">设为已读</a-button>
              </div>
            </div>
          </a-list-item-meta>
        </a-list-item>
      </a-list>
      <a-pagination v-if="messageList.length" show-size-changer :show-total="total => `共 ${total}条数据`" @change="changePage" :current="form.page" :pageSize="form.pageSize" :total="total" class="fr" />
    </div>
  </div>
</template>

<script>
import messageModel from '@/api/brandManage/message'
import moment from 'moment'

export default {
  data() {
    return {
      activeKey: 999,
      paneList: [
        { title: '全部', key: 999, total: 0 },
        { title: '未读', key: 1, total: 0 },
      ],
      messageList: [],

      total: 0,
      form: {
        page: 1,
        pageSize: 10,
        userId: this.$store.state.user.userSession.userId,
      },
    }
  },

  mounted() {
    this.changeTab()
    this.initCount()
  },
  methods: {
    moment,
    initData() {
      messageModel.msgList(this.form).then((res) => {
        this.messageList = res.list
        this.total = res.total
      })
    },
    initCount() {
      this.$store.dispatch('getMessgeCount').then((res) => {
        this.paneList[0].total = res.notReadCount + res.alreadyReadCount
        this.paneList[1].total = res.notReadCount
      })
    },
    changeTab(e) {
      if (e === 999) {
        this.form.status = undefined
      } else {
        this.form.status = e
      }

      this.initData()
    },
    changePage(e) {
      this.form.page = e
      this.initData()
    },
    handleApplyState(type, id) {
      let params = {
        msgId: id,
        applyState: type,
      }
      messageModel.applyState(params).then((res) => {
        this.$message.success('操作成功！')
        this.initData()
        this.initCount()
      })
    },
    handleRead(e) {
      let params = {
        allRead: false,
        msgId: null,
      }
      if (!e) {
        params.allRead = true
      } else {
        params.msgId = e.id
      }
      messageModel.read(params).then((res) => {
        this.$message.success('操作成功！')
        this.initData()
        this.initCount()
      })
    },
  },
}
</script>

<style scoped lang="scss">
.message-container {
  background: #fff;
  padding: 20px;
  .text {
    overflow: hidden;
    text-overflow: ellipsis;
    word-break: break-all;
    white-space: nowrap;
  }
  .item {
    display: flex;
    align-items: center;
    img {
      width: 40px;
      height: 40px;
      overflow: hidden;
      margin-right: 20px;
      border-radius: 4px;
    }
    .title {
      max-width: 300px;
      margin-right: 40px;
      font-weight: bold;
    }
    .content {
      flex: 1;
      margin-right: 20px;
    }
    .time {
      max-width: 150px;
      margin-right: 20px;
    }
    .options {
      max-width: 200px;
    }
  }
}
</style>
