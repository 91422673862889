<!-- //
动态编辑
@auth xuyd
@date 2022-09-10
//-->
<template>
  <div class="layout-vertical">
    <PageHeader :show-icon="showIcon || !!$route.query.contentType" :show-button="false"
      :showConfirm="showConfirm && mode != 999" :data="{ state: form.trendStatus }"></PageHeader>
    <div class="layout-result">
      <a-form-model ref="refForm" :model="form" :rules="rules" layout="vertical" class="layout-form p48">
        <div class="form-left">
          <a-form-model-item prop="uploadFileList">
            <span slot="label" class="form-label">上传图片</span>

            <a-upload name="avatar" class="uploadBox" accept=".jpeg,.jpg,.png,.gif,.bmp" list-type="picture-card"
              :show-upload-list="false" :file-list="form.uploadFileList" :before-upload="handleBeforeUpload"
              @change="handleChange">
              <div v-if="!form.uploadFileList.length">
                <p class="upload-icon">
                  <i aria-label="icon: inbox" class="icon">
                    <svg viewBox="0 0 1024 1024" data-icon="inbox" width="1em" height="1em" fill="currentColor"
                      aria-hidden="true" focusable="false" class="">
                      <path
                        d="M885.2 446.3l-.2-.8-112.2-285.1c-5-16.1-19.9-27.2-36.8-27.2H281.2c-17 0-32.1 11.3-36.9 27.6L139.4 443l-.3.7-.2.8c-1.3 4.9-1.7 9.9-1 14.8-.1 1.6-.2 3.2-.2 4.8V830a60.9 60.9 0 0 0 60.8 60.8h627.2c33.5 0 60.8-27.3 60.9-60.8V464.1c0-1.3 0-2.6-.1-3.7.4-4.9 0-9.6-1.3-14.1zm-295.8-43l-.3 15.7c-.8 44.9-31.8 75.1-77.1 75.1-22.1 0-41.1-7.1-54.8-20.6S436 441.2 435.6 419l-.3-15.7H229.5L309 210h399.2l81.7 193.3H589.4zm-375 76.8h157.3c24.3 57.1 76 90.8 140.4 90.8 33.7 0 65-9.4 90.3-27.2 22.2-15.6 39.5-37.4 50.7-63.6h156.5V814H214.4V480.1z">
                      </path>
                    </svg>
                  </i>
                </p>
                <p class="upload-text">上传1张不低于300kb的图片</p>
                <p class="upload-hint-format">支持格式：.jpeg .jpg .png .gif .bmp</p>
                <p class="upload-hint">*请您务必确保所上传的内容，未涉及任何侵权及违反互联网监管协议；您将承担所有法律责任，与此同时平台将保留向您追诉的法律权利</p>
              </div>
              <div class="uploadBox" v-else>
                <a-spin :spinning="imgloading" class="imgBox">
                  <img :src="convertToPrefixPath(form.uploadFileList[0].uri)" @load="onImageLoad"
                    @error="onImageError" />
                  <div class="mask" id="mask">
                    <a-icon class="deleteIcon" type="delete" @click.stop="handleRemove(form.uploadFileList[0])" />
                  </div>
                </a-spin>
              </div>
            </a-upload>
          </a-form-model-item>
        </div>
        <div class="form-right">
          <a-form-model-item prop="title">
            <span slot="label" class="form-label">标题</span>
            <a-input v-model="form.title" class="form-value" placeholder="请输入标题，简要突出内容重点" :maxLength="titleMaxLength"
              :suffix="titleWordLimit" size="large"></a-input>
          </a-form-model-item>

          <a-form-model-item prop="content">
            <span slot="label" class="form-label">描述</span>
            <TextAreaEx v-model="form.content" class="form-value" style="height: 250px"
              :placeholder="`描述不超过${contentMaxLength}字，含标点和空格`" :maxLength="contentMaxLength" />
          </a-form-model-item>

          <a-form-model-item prop="tabList">
            <span slot="label" class="form-label">
              添加标签
              <span class="tip">标签最多添加6个</span>
            </span>
            <TagSelector v-model="form.tabList" :limit="6" mode="multiple" @change="handleTabChange" class="form-value">
            </TagSelector>
          </a-form-model-item>
        </div>
      </a-form-model>
    </div>
    <slot name="pageHeader">
      <PageHeader v-if="$route.query.isPreview != 1" :show-title="false" :show-icon="showIcon_bottom" :show-button="true"
        :data="{ state: form.trendStatus }" @publishClick="handlePublish" @saveClick="handleSave"></PageHeader>
    </slot>

    <!-- 选择登录平台dialog -->
    <a-modal v-model="showPlatformChoose" width="590px" :bodyStyle="{ height: '284px' }"
      :confirmLoading="publishLoading" :keyboard="false" :maskClosable="false" @ok="handlePlatformChoose"
      :destroyOnClose="true">
      <div slot="title" class="modal-title">
        <div class="title">发布动态</div>
        <div class="hint">选择您要发布的平台账号</div>
      </div>
      <a-spin wrapper-class-name="global-spin" :spinning="publishLoading">
        <PlatformChoose v-model="selectBusinessList" :data="platformData" />
      </a-spin>
      <!-- <div slot="cancelText">取消</div>
      <div slot="okText"  @click="handlePlatformChoose" :loading="publishLoading">确定1</div> -->
    </a-modal>
  </div>
</template>

<script>
import * as TrendApi from '@/api/content/trend.api.js'
// import EmptyImg from "@/components/Empty";
import TagSelector from '@/components/TagSelector'
import PlatformChoose from '@/components/PlatformChoose/index'
import * as OssClient from '@/components/AliOss/OssClient'
import { mapGetters } from 'vuex'
/**
 * 获取文件字节码
 *
 * @param file
 * @returns {Promise<unknown>}
 */
function getBase64 (file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => resolve(reader.result)
    reader.onerror = (error) => reject(error)
  })
}

export default {
  name: 'TrendEdit',
  components: { TagSelector, PlatformChoose },
  props: {
    showConfirm: {
      type: Boolean,
      default: true,
    },
  },
  data () {
    let checkUploadFileList = (rule, value, callback) => {
      if (!this.form.uploadFileList.length) {
        callback(new Error('图片不能为空'))
      } else {
        callback()
      }
    }
    return {
      imgloading: true,
      publishLoading: false,
      mode: null, // mode=[1(新增）|2（编辑）|3（详情）]
      showPlatformChoose: false, // 选择发布平台显示
      selectBusinessList: [], // 选择发布平台数据
      // selectPlatformData: {id:0},   // 选择发布平台的当前参数
      pageNum: '1', // 从列表进来，返回后保存页码
      showIcon: false, // 从列表进来true，菜单进来false
      showIcon_bottom: false,
      form: {
        id: null,
        contentType: 1, // 动态类型 1动态 2视频 3作品 4文章
        title: null, // 动态的标题
        trendStatus: 0, // 动态状态 0草稿 100未发布 200已发布
        content: null, // 动态内容
        coverUri: null, // 封面目录地址
        uploadFileList: [
          // 上传组件文件列表 { uid: '-1', name: 'image.png', status: 'done', url: '',},
          // {
          //   uid: '-1',
          //   status: 'done',
          //   name: '1664593344602_u20222.png',
          //   url: 'image/202210/1664593344602_u20222.png'
          // }
        ],
        fileList: [], // 接口返回的文件列表 视频文件 {uri,sort,type,appletComprImage，webComprImage, id(动态：内容id是1对多), flag :操作标记(1:新增 2:删除)
        tabList: [], // 动态的兴趣标签 {tabId, tabName}
        productIds: [], // 动态的关联商品
      },
      deleteList: [], // 存储删除的文件，最终和uploadFileList一样merge到fileList发送给后端
      // previewVisible: false,// 切换显示empty
      previewImage: '', // 预览图片内容

      fileUploadLimit: 1, // 文件上传限制个数
      titleMaxLength: 30, // title最大len
      contentMaxLength: 1000, // 描述最大len
      allTabList: [], // 标签列表
      rules: {
        // title: [{required: true, message: '标题不能为空', trigger: 'blur'}],
        content: [{ required: true, message: '描述不能为空', trigger: 'blur' }],
        // uploadFileList: [{ required: true, message: '图片不能为空', trigger: 'change' }],
        uploadFileList: [{ required: true, validator: checkUploadFileList, trigger: 'blur' }],
        // tabList: [{required: true, message: '标签不能为空', trigger: 'change'}],
      },
    }
  },
  computed: {
    ...mapGetters(['businessType']),
    isAddMode () {
      return this.mode == '1'
    },
    isEditMode () {
      return this.mode == '2' || this.mode == 'preview' || this.mode == '999'
    },
    isDetailMode () {
      return this.mode == '3'
    },
    titleWordLimit () {
      let len = this.form.title ? this.form.title.length : 0
      return len + '/' + this.titleMaxLength
    },
    platformData () {
      return this.form.id
    },
  },

  created () {
    this.form.id = this.$route.query.id
    this.pageNum = this.$route.query.pageNum
    this.mode = this.$route.query.mode
    this.isPreview = this.$route.query.isPreview
    this.showIcon = !this.mode || this.mode == '1' ? false : true
    this.showIcon_bottom = !this.mode || this.mode == '1' || this.mode == '999' ? false : true
  },
  mounted () {
    if (this.isEditMode) {
      this.getDetail()
    }
  },

  methods: {
    getDetail () {
      let id = this.form.id
      let params = {
        trendId: id,
        queryType: this.$route.query.queryType || 1,
      }
      TrendApi.get(params).then((data) => {
        console.log(data, '====>')
        this.form = { ...data, uploadFileList: [] }
        this.form.tabList = this.form.tabList || []
        this.form.id = this.form.id || id
        this.previewImage = this.ossUrl(this.form.coverUri)
        // 上传组件需要处理图片列表对象
        this.imgloading = true
        let uploadFileList = []
        if (this.$util.isNotEmptyArray(this.form.fileList)) {
          this.form.fileList.forEach((item, index) => {
            if (item.uri) {
              // 处理服务端返回空值的数组
              uploadFileList.push(this.convertDbToUpload(item))
            }
          })
          this.form.uploadFileList = uploadFileList
        }
        console.log(this.form);
      })
    },

    /**
     * 把接口返回结果对象转换成upload组件用的对象结构
     * @param dbFileResult api返回结构 {uri,sort:type, type, appletComprImage, webComprImage,id}
     * @return upload组件文件对象
     */
    convertDbToUpload (dbFileResult) {
      let uploadFileObj = {
        uid: dbFileResult.uri, // upload组件记录唯一区分
        url: this.ossUrl(dbFileResult.uri),
        status: 'done',
        name: dbFileResult.fileName ? dbFileResult.fileName : dbFileResult.uri, // 文件名

        uri: dbFileResult.uri,
        fileName: dbFileResult.fileName || dbFileResult.originalName || dbFileResult.uri,
        tempType: dbFileResult.type, // 临时转换存储用
        type: dbFileResult.type == '3' ? 'image/png' : '', // 分类 0其他1文本2视频3图片
        id: dbFileResult.id, // 修改时候的内容id
        flag: this.mode == '999' ? '1' : '3', // db获取出来的默认是3 // flag :操作标记(1:新增 2:删除 3:无需处理)
      }
      return uploadFileObj
    },

    /**
     * 把oss上传的结果对象转换成upload组件用的对象结构
     * @param ossFileResult oss返回结构 {fileName,name:相对路径, url, res:{}}
     */
    convertOssToUpload (ossFileResult) {
      let uploadResult = {
        uid: ossFileResult.path, // upload组件记录唯一区分
        // url: ossFileResult.url, // TODO 这里两种都可以，但是采用同源后，需要使用ossUrl处理下
        url: this.ossUrl(ossFileResult.path),
        status: 'done',
        name: ossFileResult.name, // 上传时候传入的fileName

        fileName: ossFileResult.name,
        uri: ossFileResult.path, // 上传之后的url的相对路径
        sort: 0, // 排序字段(默认就是数字下标排序)
        id: null, // id(动态：内容id是1对多),
        flag: 1, // flag :操作标记(1:新增 2:删除 3:无需处理)
      }
      return uploadResult
    },

    /**
     * 把upload组件用的对象结构转换成db结果对象
     * @param uploadFileResult
     */
    convertUploadToDb (uploadFileResult) {
      let dbResult = {
        id: uploadFileResult.id,
        uri: uploadFileResult.uri,
        originalName: uploadFileResult.fileName, // 上传时候传入的fileName
        fileName: uploadFileResult.fileName, // 上传时候传入的fileName
        sort: 0, // 排序字段(默认就是数字下标排序)
        flag: uploadFileResult.flag, // flag :操作标记(1:新增 2:删除 3:无需处理)
        type: 3, // 分类：0其他1文本2视频3图片
      }
      return dbResult
    },

    /**
     * 点击预览
     * @param file
     * @returns {Promise<void>}
     */
    async handlePreview (file) {
      if (!file.url && !file.preview) {
        file.preview = await getBase64(file.originFileObj)
      }
      this.previewImage = file.url || file.preview
      // this.previewVisible = true;
      console.log(this.previewImage)
    },
    onImageLoad () {
      this.imgloading = false // 当图片加载完成时，设置loading为false，停止加载动画
    },
    onImageError () {
      this.imgloading = false // 当图片加载失败时，设置loading为false，停止加载动画
      console.error('Image loading failed.')
    },
    /**
     * 上传前处理
     * true =>file.status = 'uploading' 进入onchange
     * false =>file.status = undefined' 进入onchange，执行一次
     * reject() 则停止进入 onchange
     * resolve可以继续onchange
     */
    handleBeforeUpload (file) {
      console.log('handleBeforeUpload', file)
      let retResult = OssClient.validateUploadFile({
        file: file,
        bizType: 'image',
      })
      if (!retResult.state) {
        this.$modal.alertError('上传处理失败：' + retResult.message)
        return false
      }
      // 开始设置上传文件
      let fileName = file.name
      console.log('【发动态】1.上传图片开始...', file)
      OssClient.uploadFile({
        bizType: 'image',
        type: 'file',
        fileName: fileName,
        file: file,
      })
        .then((result) => {
          console.log('【发动态】2.上传图片完成...', result)
          this.handleRemove()
          this.imgloading = true
          let fileResult = this.convertOssToUpload(result)
          this.form.uploadFileList = [fileResult]
          // 预览框显示
          // this.handlePreview(fileResult)
          console.log('【发动态】2.上传组件图片墙=====>', this.form.uploadFileList)
          this.$refs.refForm.validateField('uploadFileList')
        })
        .catch((err) => {
          console.log('上传处理失败：', err)
          this.$modal.alertError('上传处理失败：' + err)
        })
      return false
    },
    hideMask () {
      // document.getElementById('mask').style.display = 'none'
    },
    /**
     * 移除文件
     */
    handleRemove (file) {
      file = this.previewImage
      console.log('handleRemove', file)
      let index = this.form.uploadFileList.indexOf(file)
      let newFileList = this.form.uploadFileList.slice()
      let removedFileList = newFileList.splice(index, 1)
      this.form.uploadFileList = newFileList
      if (this.$util.isNotEmptyArray(removedFileList)) {
        removedFileList.forEach((item) => {
          if (item.flag == 3) {
            // 只有服务端返回的记录才去删除
            item.flag = 2
            this.deleteList.push(item)
          } else if (item.flag === 1) {
            // 调用oss接口直接删除原始文件
            console.log('删除图片：', item)
            this.$modal.msg('删除图片：' + item.fileName)
          }
        })
      }
      console.log('------->', this.form.uploadFileList)
      // 如果有
      if (!this.form.uploadFileList || this.form.uploadFileList.length <= 0) {
        this.previewImage = null
      }
    },

    /**
     * 文件变更时间，进行上传处理
     */
    async handleChange ({ file, uploadFileList }) {
      console.log(file)
      if (!file.status) {
        return
      }
      if (file.status === 'uploading') {
        this.$message.success(`${file.name} 正在上传`)
      } else if (file.status === 'done') {
        this.$message.success(`${file.name} 上传成功`)
        console.log('uploadFileList')
        this.$refs.refForm.validateField('uploadFileList')
      } else if (file.status === 'error') {
        this.$message.error(`${file.name} 文件上传失败`)
      }
    },

    /**
     * tab选择
     */
    handleTabChange (items) {
      console.log('=====>tagSelector', items)
      this.form.tabList = items
    },

    handlePublish () {
      this.$refs['refForm'].validate((valid) => {
        if (valid) {
          if (this.$store.getters.userSession.userPlatType == 9 || this.businessType == 5) {
            // 员工或者品牌后台时候不用选择平台直接发布
            this.selectBusinessList = [this.$store.getters.userSession.businessUserId]
            this.handlePlatformChoose()
          } else {
            if (this.mode == 999) {
              // 需求是点击转发按钮进入转发页面，接着点击发布不弹出选平台框直接发布当前平台
              this.selectBusinessList = [this.$store.getters.userSession.businessUserId]
              this.handlePlatformChoose()
            } else {
              // 普通发图文
              // 打开dialog
              if (this.$store.getters.userSession.businessList.length > 1) {
                this.showPlatformChoose = true
              } else {
                this.selectBusinessList = [this.$store.getters.userSession.businessList[0].businessUserId]
                this.handlePlatformChoose()
              }
            }
          }
        } else {
          this.$modal.msgError('请补全信息')
        }
      })
    },

    handleSave (data, callback) {
      this.form.trendStatus = 0
      this.handleSaveContent()
        .then((data) => {
          this.$modal.msg('保存草稿成功')
          this.$router.replace({
            name: 'TrendList',
            query: { pageNum: this.pageNum },
          })
        })
        .catch((err) => {
          if (!this.$util.isValidFalse(err)) {
            callback && callback()
          }
        })
    },

    // 弹框显示选择平台
    handlePlatformChoose () {
      console.log(this.form, ' this.form')
      if (!this.selectBusinessList || this.selectBusinessList.length <= 0) {
        this.$modal.msgError('请选择一个平台')
        return
      }

      if (this.publishLoading) return
      this.publishLoading = true
      this.form.businessIds = this.selectBusinessList
      this.form.trendStatus = 200
      this.handleSaveContent()
        .then((data) => {
          this.$modal.msg('发布成功')
          this.publishLoading = false
          this.showPlatformChoose = false
          this.$router.replace({
            name: 'TrendList',
            query: { pageNum: this.pageNum },
          })
        })
        .catch((err) => {
          this.publishLoading = false
        })
    },

    /**
     * 保存到处理
     */
    handleSaveContent () {
      return new Promise((resolve, reject) => {
        let jsonData = { ...this.form }
        if (this.$util.isNotEmptyArray(jsonData.uploadFileList)) {
          // uploadFileList对象转成fileList
          jsonData.fileList = jsonData.uploadFileList.map((item) => this.convertUploadToDb(item))
          delete jsonData.uploadFileList
          // 设置封面，默认第一张
          jsonData.coverUri = jsonData.fileList[0].uri
        }
        if (this.$util.isNotEmptyArray(this.deleteList)) {
          // 处理删除的记录
          jsonData.fileList = jsonData.fileList.concat(this.deleteList.map((item) => this.convertUploadToDb(item)))
        }

        console.log('表单参数：', jsonData)
        TrendApi.save(jsonData)
          .then((data) => {
            data && (this.form.id = data)
            resolve(data)
          })
          .catch((err) => {
            reject(err)
          })
      })
    },
  },
}
</script>
<style>
.uploadBox .ant-upload.ant-upload-select-picture-card {
  width: 375px;
  min-height: 469px;
  background-color: transparent;
}

.uploadBox .ant-upload.ant-upload-select-picture-card>.ant-upload {
  padding: 0;
}
</style>
<style lang="scss" scoped>
.uploadBox {
  width: 375px;
  min-height: 469px;

  .upload-icon {
    .icon {
      font-size: 90px;
    }
  }

  .upload-text {
    margin: 30px auto 20px auto;
    color: #000000;
    box-sizing: content-box;
    color: #000000a5;
  }

  .upload-hint-format {
    margin: 20px 0;
    box-sizing: content-box;
    color: #00000072;
  }

  .upload-hint {
    color: #00000072;
  }

  .imgBox {
    width: 100%;
    height: 100%;
    border-radius: 4px;
    min-height: 469px;
    background-color: #404040;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      width: 375px;
    }

    .mask {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 100%;
      height: 100%;
      min-height: 469px;
      display: block;
      background-color: #000000a6;
      opacity: 0;
      z-index: 1;
      transition: opacity 0.5s;

      .deleteIcon {
        color: #ffffff;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        font-size: 20px;
      }
    }
  }

  .imgBox:hover .mask {
    opacity: 1;
  }
}

.form-label {
  font-size: 16px;
  font-weight: 600;
  font-family: '思源黑体 CN Normal', '思源黑体 CN Regular', '思源黑体 CN', sans-serif;
  color: #333333;
  text-align: left;
  line-height: 24px;

  .tip {
    font-size: 12px;
    font-weight: 300;
  }
}

.form-value {
  min-width: 375px;
  /*max-width: 700px;*/
}

.layout-form {
  display: flex;

  .form-left {
    width: 375px;
  }

  // .img-preview {
  //   width: 375px;
  //   height: 465px;

  //   .upload-hint,
  //   .upload-text {
  //     font-size: 14px;
  //     font-weight: 400;
  //   }

  //   .upload-text {
  //     color: #000000a5;
  //   }

  //   .upload-hint {
  //     color: #00000072;
  //     margin-bottom: 100px;
  //   }
  // }

  // .img-upload {
  //   margin-top: 8px;
  // }

  .form-right {
    flex: 1;
    margin-left: 48px;
  }
}

.modal-title {
  display: flex;

  .title {
    font-weight: 600;
    font-size: 14px;
  }

  .hint {
    margin-left: 10px;
    font-weight: 400;
    font-size: 12px;
    color: #0000006d;
  }
}
</style>
