<template>
  <div class="set-brandInfo-container">
    <PageHeader :show-button="false"></PageHeader>
    <div class="content-box">
      <a-card style="width: 1400px">
        <div class="content">
          <div class="box">
            <div class="item">
              <div class="logo">
                <img v-if="form.brandInformation.brandLogoPic" :src="ossUrl(form.brandInformation.brandLogoPic)" />
                <span v-else>logo</span>
              </div>
              <div class="value">{{ form.brandInformation.brandName || '添加品牌信息，展示主页头像' }}</div>
              <a-button type="link" @click="$refs.modifyLogoModal.onShow(form)">修改logo与品牌信息</a-button>
            </div>
            <ContentItem title="简介" placeholder="完善主页简介，展示经营理念" :content="form.introductionInformation.introduction">
              <div slot="btn">
                <a-button type="link" @click="$refs.modifyIntroductionModal.onShow(form)">修改</a-button>
              </div>
            </ContentItem>
            <ContentItem title="主页背景图" :content="form.backgroundPicInformation.bgm || u20220" :alignItems="false">
              <div class="bg" slot="content">
                <img :src="ossUrl(form.backgroundPicInformation.bgm) || u20220" style="width: 100%; height: 100%" />
              </div>
              <div class="bgrightBtn" slot="btn">
                <Upload name="file" listType="text" @success="(e) => uploadSuccess(e, 'bgm')">
                  <div slot="content">
                    <a-button type="link">修改</a-button>
                  </div>
                </Upload>
              </div>
            </ContentItem>

            <ContentItem title="品牌主营" placeholder="添加品牌主营内容" :content="form.brandMerito">
              <div slot="btn">
                <a-button type="link" @click="$refs.arandMeritoModal.onShow(form)">修改</a-button>
              </div>
            </ContentItem>

            <ContentItem :content="form.abilityCertification.length" title="资质实力" placeholder="添加品牌资质实力">
              <div slot="content">
                <div class="shopEnvironmentImgBox" v-if="form.abilityCertification.length">
                  <img :src="ossUrl(item)" v-for="(item, index) in form.abilityCertification" :key="index" />
                </div>
                <div v-else>添加品牌资质实力</div>
              </div>
              <div slot="btn">
                <a-button type="link" @click="$refs.abilityCertificationModal.onShow(form)">修改</a-button>
              </div>
            </ContentItem>
            <ContentItem title="品牌故事" placeholder="添加品牌故事，让顾客更加了解您" :alignItems="true" :content="form.shopHistoryInformation.shopHistory">
              <div slot="btn">
                <a-button type="link" @click="$refs.brandStoryModal.onShow(form)">修改</a-button>
              </div>
            </ContentItem>
          </div>

          <div class="right">
            <h3>主页展示效果（示意图）</h3>
            <img src="@/assets/img/brandbg_03.png" alt="" srcset="" />
          </div>
        </div>
      </a-card>
      <ModifyLogoModal :allowAuditState="false" ref="modifyLogoModal" @submit="submit" />
      <ModifyIntroductionModal :allowAuditState="false" ref="modifyIntroductionModal" @submit="submit" />
      <BrandStoryModal ref="brandStoryModal" :allowAuditState="false" @submit="submit" />
      <AbilityCertificationModal ref="abilityCertificationModal" :allowAuditState="false" @submit="submit" />
      <BrandMeritoModal ref="arandMeritoModal" :allowAuditState="false" @submit="submit" />
    </div>
  </div>
</template>
<script>
import ModifyLogoModal from '@/views/shop/shop/components/modifyLogo_modal.vue'
import ModifyIntroductionModal from '@/views/shop/shop/components/modifyIntroduction_modal.vue'
import BrandStoryModal from '@/views/shop/shop/components/brandStory_modal.vue'
import AbilityCertificationModal from './components/abilityCertification_modal.vue'
import BrandMeritoModal from './components/brandMerito_modal.vue'
import Upload from '@/components/Upload'
import u20220 from '@/assets/img/u20220_new.png'
import ContentItem from '@/views/shop/shop/components/ContentItem'
import * as ShopApi from '@/api/shop/shop.api'
import { mapGetters } from 'vuex'
export default {
  components: {
    ContentItem,
    Upload,
    ModifyLogoModal,
    ModifyIntroductionModal,
    BrandStoryModal,
    AbilityCertificationModal,
    BrandMeritoModal,
  },
  data() {
    return {
      u20220,

      form: {
        abilityCertification: [],
        brandMerito: '',
        brandInformation: {
          brandLogoPic: null,
          brandName: null,
          brandRegistPic: null,
          brandAuthorizePic: null,
          auditState: 0,
        },
        homepageInformation: {
          homepageName: null,
          auditState: 0,
        },
        shopAddressInformation: {
          latitude: 0,
          longitude: 0,
          shopAdress: null,
          shopAdressDetail: null,
          workDaysBegin: null,
          workDaysEnd: null,
          workTimeBegin: null,
          workTimeEnd: null,
          auditState: 0,
        },
        contactInformation: {
          contactsLandline: null,
          contactsPhone: null,
          auditState: 0,
        },
        introductionInformation: {
          introduction: '',
          auditState: 0,
        },
        backgroundPicInformation: {
          bgm: null,
          auditState: 0,
        },
        shopEnvironment: {
          shopEnvironment: [''],
          auditState: 0,
        },
        idAuthenticationParam: {
          identityName: null,
          idPics: [''],
          auditState: 0,
        },
        shopHistoryInformation: {
          shopHistory: null,
          auditState: 0,
        },
        realAuthentication: {
          enterpriseName: null,
          enterpriseState: 0,
          busiLicensePic: null,
          busiLicenseCode: null,
          busiLicenseAvailable: null,
          legalmanLicenceName: null,
          legalmanLicenceCode: null,
          legalmanLicencePic: null,
          legalmanLicenceBackpic: null,
          legalmanLicenceHandpic: null,
        },
        experienceAward: {
          type: 0,
          experienceContentList: [
            {
              timePeriod: null,
              title: null,
              exhibitionHall: null,
              adress: null,
              pics: [''],
              id: 0,
            },
          ],
        },
        experiencexhibition: {
          type: 0,
          experienceContentList: [
            {
              timePeriod: null,
              title: null,
              exhibitionHall: null,
              adress: null,
              pics: [''],
              id: 0,
            },
          ],
        },
        experience: {
          type: 0,
          experienceContentList: [
            {
              timePeriod: null,
              title: null,
              exhibitionHall: null,
              adress: null,
              pics: [''],
              id: 0,
            },
          ],
        },
        teamInfo: [
          {
            employeesName: null,
            headImage: null,
            position: null,
            sort: 0,
          },
        ],
      },
    }
  },
  computed: {
    ...mapGetters(['businessType', 'businessTag', 'businessUserId']),
  },
  mounted() {
    console.log(this.businessTag, 'businessTag')
    console.log(this.businessType, 'businessType')
    this.init()
  },
  methods: {
    init() {
      ShopApi.getInfo().then((data) => {
        this.form = data
      })
    },
    uploadSuccess(e, type) {
      if (type === 'bgm') {
        const form = {
          homepageInformation: null,
          shopEnvironment: null,
          shopHistoryInformation: null,
          brandInformation: null,
          shopAddressInformation: null,
          idAuthenticationParam: null,
          realAuthentication: null,
          backgroundPicInformation: {
            bgm: e,
          },
          experience: null,
          introductionInformation: null,
        }
        ShopApi.editShopInfo(form).then((res) => {
          this.form.backgroundPicInformation.bgm = e
        })
      }
    },
    submit(form, cb) {
      ShopApi.editShopInfo(form)
        .then((res) => {
          this.$message.success('修改成功')
          this.init()
          cb()
        })
        .catch((err) => {
          cb()
        })
    },
  },
}
</script>
<style lang="scss" scoped>
.set-brandInfo-container {
  .content-box {
    padding: 20px;
  }
  .content {
    display: flex;
    .box {
      flex: 1;

      .bg {
        width: 314px;
        height: 227px;
        margin-bottom: 10px;
        img {
          border-radius: 30px;
        }
      }
      .content-inner {
        display: flex;
        align-items: center;
      }

      .shopEnvironmentImgBox {
        img {
          width: 90px;
          height: 65px;
          border-radius: 5px;
          margin: 5px;
        }
        img:last-child {
          margin-right: 0;
        }
      }
      .teamImgBox {
        .team-inner {
          float: left;
          margin-right: 20px;
          text-align: center;
        }
        .team-inner:last-child {
          margin-right: 0;
        }
        img {
          width: 40px;
          height: 40px;
          border-radius: 50%;
          background-color: #cccccc;
          border: 1px solid #e7e7e7;
        }
        .name {
          width: 50px;
        }
      }
    }
    .item {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .logo {
        width: 66px;
        height: 66px;
        background-color: #ccc;
        border-radius: 50%;
        line-height: 66px;
        text-align: center;
        color: #fff;
        margin-right: 20px;
        img {
          width: 100%;
          height: 100%;
          border-radius: 50%;
        }
      }
      .label {
        width: 100px;
        margin-right: 20px;
      }
      .value {
        flex: 1;
        text-align: left;
      }
    }
    .right {
      width: 334px;
      margin-left: 20px;
      img {
        width: 100%;
      }
    }
  }
}
</style>
