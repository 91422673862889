<!-- //
文章作品编辑
@auth xuyd
@date 2022-11-01
//-->
<template>
  <div class="layout-vertical">
    <PageHeader></PageHeader>
    <div class="layout-result">
      <a-form ref="refForm" :form="form" :rules="rules" :label-col="{ span: 2 }" :wrapper-col="{ span: 20 }" class="layout-form p48">
        <a-row :gutter="20" :span="24" class="form-row">
          <a-col class="label" :span="4" align="left" style="width: 100px">
            <span>头像</span>
          </a-col>
          <a-col class="value" :span="4" align="left" style="width: 300px;">
            <div style="width: 68px;height: 68px; margin-top: -20px; overflow: hidden; border-radius: 50%">
              <img style="width: 100%;height: 100%;" :src="form.headImage"/>
            </div>
          </a-col>
          <a-col class="action" :span="4" align="left">
            <a-upload
                accept=".jpeg,.jpg,.png,.gif,.bmp"
                :show-upload-list="false"
                :before-upload="handleBeforeUpload"
                >
              <a-button type="link">修改</a-button>
            </a-upload>
          </a-col>
        </a-row>

        <FormEmbedItem title="昵称" :detail-value="form.name"
                     v-model="modifyForm.nickName"
                     @handleOk="handleCommand('nickName')"/>

        <FormEmbedItem title="性别"
                     :detail-value="form.sex"
                     v-model="modifyForm.sex"
                     type="select"
                     :dict-list="dict.type.GENDER"
                     @handleOk="handleCommand('sex')"/>

        <FormEmbedItem title="生日"
                     :detail-value="form.birthday"
                     v-model="modifyForm.birthday"
                     type="date"
                     @handleOk="handleCommand('birthday')"/>

        <a-row :gutter="20" :span="24" class="form-row">
          <a-col class="label" :span="4" align="left" style="width: 100px">
            <span>登录密码</span>
          </a-col>
          <a-col class="value" :span="4" align="left" style="width: 300px">
            <span>******</span>
          </a-col>
          <a-col class="action" :span="4" align="left">
            <a-button type="link" @click="handlePassword('password')">修改</a-button>
          </a-col>
        </a-row>
      </a-form>
    </div>

    <!-- dialog -->
    <ModifyPassword ref="refModifyPassword"/>
  </div>
</template>

<script>

  import * as AuthApi from '@/api/auth.api'
  import FormEmbedItem from '@/components/FormEmbedItem/index'
  import ModifyPassword from './ModifyPassword'
  import * as OssClient from "@/components/AliOss/OssClient";

  export default {
    name: 'UserProfile',
    components: {FormEmbedItem, ModifyPassword},
    dictTypes: [
      {dictType: 'GENDER', dataType: Number},
    ],
    data() {
      return {
        layout: 'horizontal',
        form: {
          id: null,
          headImage: null,
          name: null,
          nickname: null,
          sex: null,
          birthday: null,
          password: null,
          passwordNew: null,
          passwordConfirm: null,
        },
        modifyForm: {
          id: null,
          headImage: null,
          nickName: null,
          sex: null,
          birthday: null,
        },
        rules: {},
      }
    },
    props: {},
    computed: {},
    created() {
      this.getDetail()
    },
    methods: {
      getDetail() {
        AuthApi.getProfile().then(data => {
          let f = data;
          f.name = data.wechatName
          f.sex = data.sex
          f.birthday = data.birthday
          f.headImage = OssClient.convertToPrefixPath(data.headImage)
          this.form = f;
        })
      },

      /**
       * 直接上传
       * @param file
       * @return {boolean}
       */
      handleBeforeUpload(file) {
        let retResult = OssClient.validateUploadFile({file: file, bizType: 'image'})
        if (!retResult.state) {
          this.$modal.alertError("上传处理失败：" + retResult.message)
          return false;
        }
        // 开始设置上传文件
        let fileName = file.name
        OssClient.uploadFile({
          bizType: 'image',
          type: 'file',
          fileName: fileName,
          file: file
        }).then(result => {
          this.modifyForm.headImage = OssClient.convertToOssPrefixPath(result.path)
          this.handleCommand('headImage')
        }).catch(err => {
          console.log("上传处理失败：", err)
          this.$modal.alertError("上传处理失败：" + err)
        })
        return false;
      },

      handleCommand(cmd) {
        let form = {};
        switch (cmd) {
          case 'headImage':
            form[cmd] = OssClient.convertToOssFullUrl(this.modifyForm[cmd])
            break;
          case 'nickName':
            form[cmd] = this.modifyForm[cmd]
            break;
          case 'sex':
            form[cmd] = this.modifyForm[cmd]
            break;
          case 'birthday':
            form[cmd] = this.modifyForm[cmd]
            break;
        }
        console.log('---AuthApi-', form)
        AuthApi.modifyUserProfile(form).then(data => {
          this.$modal.msgSuccess('修改成功');
          this.getDetail()
        })
      },

      /**
       * 显示修改密码
       */
      handlePassword() {
        this.$refs.refModifyPassword.showModal()
      },
    }
  }
</script>
<style lang="scss" scoped>
  .layout-form {
    .form-row {
      margin-bottom: 20px;
    }
    .label {
      font-size: 14px;
      font-weight: 600;
    }

    .value {
      font-size: 14px;
      font-weight: 400;
    }
  }
</style>
