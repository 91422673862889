<template>
  <div class="material-publishArticle-container">
    <article-publish ref="articlePublish" :content-type="4" :showConfirm="false">
      <PageHeader v-if="businessType === 5" slot="pageHeader" :show-title="false" :show-icon="false" :show-button="true">
        <div slot="content">
          <a-button type="primary" class="action" @click="handlePublish" :loading="loading">发布</a-button>
        </div>
      </PageHeader>
    </article-publish>
    <select-store ref="selectStore"></select-store>
  </div>
</template>
<script>
import ArticlePublish from '@/views/content/common/content-edit'
import SelectStore from '../store/selectStore.vue'
import { mapGetters } from 'vuex'
import { convertUploadToDb } from '@/utils/util'
export default {
  components: { ArticlePublish, SelectStore },
  data() {
    return {
      loading: false,
    }
  },
  computed: {
    ...mapGetters(['businessType']),
  },
  methods: {
    handlePublish() {
      this.$refs.articlePublish.$refs['refForm'].validate((valid) => {
        if (valid) {
          let obj = this.$refs.articlePublish.form
          let params = {
            content: obj.content,
            contentType: obj.contentType,
            coverUri: obj.coverUri,
            fileList: obj.uploadFileList.map((item) => convertUploadToDb(item)),
            productIds: obj.productIds,
            tabList: obj.tabList,
            trendStatus: 200,
            title: obj.title,
            storesType: obj.storesType,
          }

          console.log(obj)
          this.$refs.selectStore.onShow(params)
        }
      })
    },
  },
}
</script>
<style lang="scss" scoped>
.action {
  width: 96px;
  height: 40px;
  font-size: 16px;
}
</style>
