<!-- //
作品管理-发作品
@auth xuyd
@date 2022-09-10
//-->
<template>
  <ContentEdit :content-type="3"></ContentEdit>
</template>
<script>
import ContentEdit from '../common/content-edit'
export default {
  name: 'ProductionEdit',
  components: { ContentEdit },
  data() {
    return {}
  },
  methods: {},
}
</script>

<style lang="scss" scoped></style>
