<!--
@author: binchen
@desc：修改主页名称
@date: 2023-04-25 21:56:35
-->
<template>
  <a-modal title="修改品牌主营" :visible="visible" :confirm-loading="confirmLoading" @ok="handleOk" @cancel="handleCancel">
    <a-form :form="form" :label-col="{ span: 5 }" :wrapper-col="{ span: 19 }" labelAlign="left">
      <a-form-item label="主营分类">
        <a-input style="height: 40px;line-height: 40px;width: 100%" v-model="form.brandMerito" placeholder="请输入" />
        <h4>例如：卫浴、橱柜</h4>
      </a-form-item>
    </a-form>
  </a-modal>
</template>

<script>
export default {
  name: 'BrandMeritoModal',
  data() {
    return {
      visible: false,
      confirmLoading: false,
      form: {
        brandMerito: '',
        homepageInformation: {
          homepageName: null,
        },
        shopEnvironment: null,
        shopHistoryInformation: null,
        brandInformation: null,
        shopAddressInformation: null,
        idAuthenticationParam: null,
        realAuthentication: null,
        backgroundPicInformation: null,
        experience: null,
        introductionInformation: null,
      },
    }
  },
  created() {},
  mounted() {},
  methods: {
    onShow(form) {
      this.form.brandMerito = form.brandMerito
      this.visible = true
    },
    handleOk() {
      this.confirmLoading = true
      this.$emit('submit', this.form, () => {
        this.confirmLoading = false
        this.handleCancel()
      })
    },
    handleCancel() {
      Object.assign(this.$data, this.$options.data.call(this))
      this.visible = false
    },
  },
}
</script>

<style scoped lang="scss"></style>
