<template>
  <a-modal v-model="visible"
           width="904px"
           :bodyStyle="{'height': '550px'}"
           :keyboard="false"
           :footer="null"
           :maskClosable="false"
           :destroyOnClose="true">
    <div slot="title" class="modal-title">
      <div class="title">微信扫码登录</div>
    </div>
    <div id="tmp" style="width: 100%; height: 100%" v-if="false"></div>
    <div id="wxLoginContainer" class="dlg-platform-container"></div>
  </a-modal>
</template>

<script>

  import $ from 'jquery'

  export default {
    name: 'WechatLogin',
    components: {},
    data() {
      return {
        visible: false,
        loading: false,
        // wechatQrUrl: 'https://open.weixin.qq.com/connect/qrconnect?appid=wx7fbbdb5f6629b42b&redirect_uri=https://yikaishop.com%2Flogin&response_type=code&scope=snsapi_login&state=coderkun#wechat_redirect',
        wechatQrUrl: '/openWx/connect/qrconnect?appid=wx7fbbdb5f6629b42b&redirect_uri=https://yikaishop.com%2Flogin&response_type=code&scope=snsapi_login&state=coderkun#wechat_redirect',
      }
    },
    methods: {
      showModal(data) {
        this.form = data;
        this.visible = true;
        this.$nextTick(() => {
          this.getInit()
        })
      },
      hideModal() {
        this.visible = false;
      },
      handleCancel() {
        this.visible = false
      },

      async handleLoad(event) {
        $('#wxIframe').contents().find('link').each((index, item) => {
          console.log('=======link======', index)
          item.href = item.href
            .replaceAll('https://res.wx.qq.com', '/wxRes')
            .replaceAll('http://res.wx.qq.com', '/wxRes')
            .replaceAll('//res.wx.qq.com', '/wxRes')
            .replaceAll('https://lp.open.weixin.qq.com', '/lpOpenWx')
        })

        $('#wxIframe').contents().find('script').each((index, item) => {
          console.log('=======script======', index)
          item.src = item.src
            .replaceAll('https://res.wx.qq.com', '/wxRes')
            .replaceAll('http://res.wx.qq.com', '/wxRes')
            .replaceAll('//res.wx.qq.com', '/wxRes')
            .replaceAll('https://lp.open.weixin.qq.com', '/lpOpenWx')
        })
        console.log('=====handleLoad======>', $('#wxIframe').contents());
      },

      /**
       * 不使用wxlogin.js则自己仿照写一个都用iframe加载
       * （不支持同源）
       * @param opt
       */
      wxLogin(opt) {
        let iframe = document.createElement("iframe");
        // let src = "https://open.weixin.qq.com/connect/qrconnect?appid=" + opt.appid + "&scope=" + opt.scope + "&redirect_uri=" + opt.redirect_uri + "&state=" + opt.state + "&login_type=jssdk&self_redirect=default" + '&styletype=' + (opt.styletype || '') + '&sizetype=' + (opt.sizetype || '') + '&bgcolor=' + (opt.bgcolor || '') + '&rst=' + (opt.rst || '');
        let src = "/openWx/connect/qrconnect?appid=" + opt.appid + "&scope=" + opt.scope + "&redirect_uri=" + opt.redirect_uri + "&state=" + opt.state + "&login_type=jssdk&self_redirect=default" + '&styletype=' + (opt.styletype || '') + '&sizetype=' + (opt.sizetype || '') + '&bgcolor=' + (opt.bgcolor || '') + '&rst=' + (opt.rst || '');
        src += opt.style ? "&style=" + opt.style : "";
        src += opt.href ? "&href=" + opt.href : "";
        iframe.id = 'wxIframe';
        iframe.src = src;
        iframe.frameBorder = "0";
        iframe.allowTransparency = "true";
        iframe.scrolling = "no";
        iframe.width = "300px";
        iframe.height = "400px";
        iframe.onload = this.handleLoad;
        let container = document.getElementById(opt.id);
        if (container) {
          container.innerHTML = "";
          container.appendChild(iframe)
        }
      },

      async getInit() {
        this.wxLogin({
          self_redirect: 'false',
          id:"wxLoginContainer",
          appid: "wx7fbbdb5f6629b42b",
          scope: "snsapi_login",
          redirect_uri: "https://yikaishop.com/login",
          state: "coderkun",
          style: "",
          href: ""
        });

        // await $('#tmp').load(this.wechatQrUrl + ' #tpl_for_page', (res) => {
        //   res = res.replaceAll('https://res.wx.qq.com', '/wxRes')
        //     .replaceAll('http://res.wx.qq.com', '/wxRes')
        //     .replaceAll('/connect/qrcode/', '/openWx/connect/qrcode/')
        //
        //   console.log('===========>',res);
        //   console.log('wxLoginContainer',$("#wxIframe").find('html'))
        //   let h = $('#wxIframe').contents().find('html').html();
        //
        //   console.log('=====handle after======>', h);
        // });
      },

    },
  };
</script>

<style scoped lang="scss">
  .modal-title {
    display: flex;

    .title {
      font-weight: 600;
      font-size: 14px;
    }

    .hint {
      margin-left: 10px;
      font-weight: 400;
      font-size: 12px;
      color: #0000006d;
    }
  }

  .dlg-platform-container {
    height: 100%;
    padding: 10px 15px;
    overflow: auto;
    display: flex;
    justify-content: center;
    align-items: center;


    .form-row {
      margin-top: 30px;
    }

    .button-item {
      width: 100%;
      position: absolute;
      bottom: 10px;
      /*margin-top: 50px;*/
      text-align: center;

      .button {
        font-size: 16px;
        width: 250px;
        height: 40px;
        line-height: 40px;
      }

      .button2 {
        font-size: 16px;
        width: 100px;
        height: 40px;
        line-height: 40px;
      }
    }
  }

</style>
