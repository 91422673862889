<template>
  <div class="layout-vertical">
    <PageHeader></PageHeader>
    <!-- 搜索区域 -->
    <div class="layout-search">
      <div><br></div>
      <div><br></div>
      <div><br></div>
      <div><br></div>
      <div><br></div>
      <div><br></div>
    </div>
    <!-- 功能按钮区域 -->
    <div class="layout-function">
    </div>
    <!-- 结果区域 -->
    <div class="layout-result">
      <div><br></div>
      <div><br></div>
      <div><br></div>
      <!-- 分页区域 -->
    </div>
    <!-- hidden等dialog区域 -->
    <!-- 添加或修改对话框 -->

  </div>
</template>
<script>
  export default {
    name: 'Home',
    components: {},
    data() {
      return {};
    },
    mounted() {
    },
    methods: {}
  }
</script>

<style lang="scss" scoped>
</style>
