<!--
@author: binchen
@desc：修改地址信息
@date: 2023-04-25 21:56:35
-->
<template>
  <a-modal title="修改地址信息" width="800px" :visible="visible" :confirm-loading="confirmLoading" @ok="handleOk"
    @cancel="handleCancel">
    <a-form :form="form" :label-col="{ span: 2 }" :wrapper-col="{ span: 22 }" labelAlign="left">
      <a-form-item label="营业时间">
        <div class="timepicker">
          <a-select style="width: 150px" placeholder="开始日期" v-model="form.shopAddressInformation.workDaysBegin">
            <a-select-option v-for="week in weekOptions" :key="week.label">
              {{ week.label }}
            </a-select-option>
          </a-select>

          <span style="margin: 0 20px">至</span>
          <a-select style="width: 150px" placeholder="结束日期" v-model="form.shopAddressInformation.workDaysEnd">
            <a-select-option v-for="week in weekOptions" :key="week.label">
              {{ week.label }}
            </a-select-option>
          </a-select>
        </div>
        <div class="timepicker">
          <a-time-picker style="width: 150px" format="HH:mm" placeholder="开始时间"
            v-model="form.shopAddressInformation.workTimeBegin" />
          <span style="margin: 0 20px">至</span>
          <a-time-picker style="width: 150px" format="HH:mm" placeholder="结束时间"
            v-model="form.shopAddressInformation.workTimeEnd" />
        </div>
      </a-form-item>
      <a-form-item label="地址信息">
        <a-input placeholder="请输入" v-model="form.shopAddressInformation.shopAdressDetail" />
      </a-form-item>
      <a-form-item label="地图定位">
        <div class="positionBox">
          <a-input placeholder="请输入" v-model="form.shopAddressInformation.shopAdress.addr" @input="changeLocation"
            @focus="focus" @blur="blur" />
          <div class="posBox" v-show="showPosBox">
            <div class="pos-item" v-for="(item, index) in pois" :key="index" @click="selectPositionByUser(item)">{{
    item.district + item.name }}</div>
          </div>
        </div>
        <olMap ref="aMapLocationPicker" :location="location" @move="move" />
      </a-form-item>
    </a-form>
  </a-modal>
</template>

<script>
import moment from 'moment'
// import AMapLocationPicker from '@/components/AMapLocationPicker'
import olMap from '@/components/olMap'
import * as ShopApi from '@/api/shop/shop.api'
export default {
  name: 'ModifyAddressModal',
  components: {
    // AMapLocationPicker,
    olMap,
  },
  props: {},
  data () {
    return {
      showPosBox: false,
      pois: [],
      weekOptions: [
        { label: '周一', value: '1' },
        { label: '周二', value: '2' },
        { label: '周三', value: '3' },
        { label: '周四', value: '4' },
        { label: '周五', value: '5' },
        { label: '周六', value: '6' },
        { label: '周日', value: '7' },
      ],
      visible: false,
      confirmLoading: false,
      form: {
        homepageInformation: null,
        shopEnvironment: null,
        shopHistoryInformation: null,
        brandInformation: null,
        shopAddressInformation: {
          workTimeBegin: '08:00',
          shopAdressDetail: null,
          shopAdress: {
            addr: '',
            lat: 0,
            lng: 0,
          },
          latitude: 0,
          workTimeEnd: '17:30',
          workDaysBegin: '周一',
          workDaysEnd: '周五',
          longitude: 0,
        },
        idAuthenticationParam: null,
        realAuthentication: null,
        backgroundPicInformation: null,
        experience: null,
        introductionInformation: null,
      },
      location: {
        lat: 0,
        lng: 0,
        Q: 0,
        R: 0,
      },
    }
  },
  created () { },
  mounted () { },
  methods: {
    moment,
    onShow (form) {
      if (form.shopAddressInformation.auditState === 0) {
        return this.$message.info('信息审核中耐心等待')
      }
      let workTimeBegin = form.shopAddressInformation.workTimeBegin || '08:00'
      this.form.shopAddressInformation.workTimeBegin = moment(workTimeBegin, 'HH:mm')

      this.form.shopAddressInformation.shopAdressDetail = form.shopAddressInformation.shopAdressDetail || undefined
      try {
        this.form.shopAddressInformation.shopAdress = form.shopAddressInformation.shopAdress ? JSON.parse(form.shopAddressInformation.shopAdress) : { addr: '', lat: 0, lng: 0 }
      } catch (error) {
        console.log(error)
        this.form.shopAddressInformation.shopAdress.addr = form.shopAddressInformation.shopAdress
      }

      this.form.shopAddressInformation.latitude = form.shopAddressInformation.latitude || undefined
      let workTimeEnd = form.shopAddressInformation.workTimeEnd || '17:30'
      this.form.shopAddressInformation.workTimeEnd = moment(workTimeEnd, 'HH:mm')

      this.form.shopAddressInformation.workDaysBegin = form.shopAddressInformation.workDaysBegin || undefined
      this.form.shopAddressInformation.workDaysEnd = form.shopAddressInformation.workDaysEnd || undefined
      this.form.shopAddressInformation.longitude = form.shopAddressInformation.longitude || undefined
      this.visible = true
      this.location = {
        lat: this.form.shopAddressInformation.latitude || 31.25814757731578,
        lng: this.form.shopAddressInformation.longitude || 121.64380073547363,
        Q: this.form.shopAddressInformation.latitude,
        R: this.form.shopAddressInformation.longitude,
      }
      this.$nextTick(() => {
        this.$refs.aMapLocationPicker.setCenter([this.location.lng, this.location.lat], 2)
      })
    },
    changeOperatingState (form, value) {
      if (form.shopAddressInformation.auditState === 0) {
        return this.$message.info('信息审核中耐心等待')
      }
      this.form.shopAddressInformation = { ...form.shopAddressInformation }
      this.form.shopAddressInformation.operatingState = value
      this.$emit('submit', this.form)
    },
    handleOk () {
      this.confirmLoading = true
      let params = { ...this.form }
      params.shopAddressInformation.workTimeBegin = moment(params.shopAddressInformation.workTimeBegin).format('HH:mm')
      params.shopAddressInformation.workTimeEnd = moment(params.shopAddressInformation.workTimeEnd).format('HH:mm')
      params.shopAddressInformation.shopAdress = JSON.stringify(params.shopAddressInformation.shopAdress)
      this.$emit('submit', params, () => {
        this.confirmLoading = false
        this.handleCancel()
      })
    },
    handleCancel () {
      Object.assign(this.$data, this.$options.data.call(this))
      this.visible = false
    },
    uploadSuccess (e, type) {
      this.form.brandInformation[type] = e
    },
    // selectPosition(e) {
    //   console.log(e)
    //   this.form.shopAddressInformation.shopAdress.addr = e.address
    //   this.form.shopAddressInformation.shopAdress.lat = e.lat
    //   this.form.shopAddressInformation.shopAdress.lng = e.lng
    //   this.form.shopAddressInformation.longitude = e.lng
    //   this.form.shopAddressInformation.latitude = e.lat
    // },
    changeLocation () {
      this.$refs.aMapLocationPicker.getInputtips(this.form.shopAddressInformation.shopAdress.addr, (pois) => {
        this.pois = pois || []
        console.log(pois)
        if (pois && pois.length) {
          this.showPosBox = true
        } else {
          this.showPosBox = false
        }
      })
    },
    inputLocation (pois) {
      this.pois = pois
      if (pois.length) {
        this.showPosBox = true
      } else {
        this.showPosBox = false
      }
    },
    focus () {
      if (this.pois.length) {
        this.showPosBox = true
      }
    },
    blur () {
      setTimeout(() => {
        this.showPosBox = false
      }, 300)
    },
    selectPositionByUser (item) {
      console.log(item)
      let location = item.location.split(',')
      this.$refs.aMapLocationPicker.setCenter(item.location.split(','))
      // this.$refs.aMapLocationPicker.marker.setPosition(item.location.split(','))
      this.$refs.aMapLocationPicker.setZoom(18)
      this.form.shopAddressInformation.shopAdress.addr = item.district + item.name
      this.form.shopAddressInformation.shopAdress.lng = location[0]
      this.form.shopAddressInformation.shopAdress.lat = location[1]
      this.form.shopAddressInformation.longitude = location[0]
      this.form.shopAddressInformation.latitude = location[1]
      this.showPosBox = false
    },
    move (e) {
      console.log(e)
      this.form.shopAddressInformation.shopAdress.addr = e.address
      this.form.shopAddressInformation.shopAdress.lng = e.lng
      this.form.shopAddressInformation.shopAdress.lat = e.lat
      this.form.shopAddressInformation.longitude = e.lng
      this.form.shopAddressInformation.latitude = e.lat
    },
  },
}
</script>
<style>
.align-items {
  display: flex;
  align-items: center;
}
</style>
<style scoped lang="scss">
.timepicker {
  display: flex;
  align-items: center;
}

.positionBox {
  position: relative;

  .posBox {
    position: absolute;
    top: 42px;
    left: 0;
    z-index: 100;
    width: 100%;
    box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
    background-color: #ffffff;
    height: 300px;
    overflow: auto;
    cursor: pointer;

    .pos-item {
      padding: 0 20px;
    }

    .pos-item:hover {
      background-color: #ffffff;
    }
  }
}
</style>
