<!-- //
内容-视频发布-编辑页面(iframe嵌套其他服务的视频页面，其他需要同源处理)
@auth xuyd
@date 2023-02-22
//-->
<template>
  <div style="width: 100%; height: 100%">
    <iframe :src="src" class="link-iframe"></iframe>
  </div>
</template>
<script>
  import {getBusinessUser, getToken} from "@/utils/auth";
  export default {
    name: 'VideoEditIframe',
    components: {},
    data() {
      return {
        mode: null, // mode=[1(新增）|2（编辑）|3（详情）]
        id: null,
        src: null,
      }
    },
    created() {
      this.mode = this.$route.query.mode
      this.id = this.$route.query.id
      this.initUrl();
    },
    methods: {
      initUrl() {
        let url = `${process.env.VUE_APP_VIDEO_HOST}/auth-redirect/content/videoPublish/preface`
        let bs = getBusinessUser();
        let t = getToken()
        let queryStr = `mode=${this.mode}&businessUserId=${bs.businessUserId}&businessType=${bs.businessType}&ssoToken=${t}`
        this.src = url + '?' + queryStr;
      }
    }
  }
</script>

<style lang="scss" scoped>
  .link-iframe {
    width: 100%;
    height: 100%;
    border: none;
    background-color: #fff;
  }
</style>
