<!-- //
编辑岗位
@auth xuyd
@date 2022-11-01
//-->
<template>
  <div class="layout-vertical">
    <PageHeader :show-icon="showIcon"
                :show-button="true"
                :show-confirm="false"
                :button-list="buttonList"
                @publishClick="handleSave"
                @saveClick="handleCancel">
    </PageHeader>

    <div class="layout-result">
      <a-form-model ref="refForm" :model="form" :rules="rules" layout="vertical" class="layout-form p48">

        <a-form-model-item prop="positionName">
          <span slot="label" class="form-label">岗位名称</span>
          <a-input v-model="form.positionName" style="width: 250px;" placeholder="请输入"></a-input>
        </a-form-model-item>

        <a-form-model-item prop="menus">
          <span slot="label" class="form-label">添加权限</span>
          <a-tree
              v-model="form.menus"
              checkable
              :auto-expand-parent="autoExpandParent"
              :selected-keys="selectedKeys"
              :tree-data="menuList"
              :replaceFields="{children:'children', title:'menuName', key:'id'}"
          />
        </a-form-model-item>
      </a-form-model>
    </div>
  </div>
</template>

<script>

  import * as PostApi from '@/api/shop/post.api'
  import * as MenuApi from '@/api/sys/menu.api'
import {  getAllMenuRouteList } from '@/api/auth.api.js'
  export default {
    name: 'PostEdit',
    components: {},
    dictTypes: [],
    data() {
      return {
        showIcon: false, // 从列表进来true，菜单进来false
        mode: null, // mode=[1(新增）|2（编辑）|3（详情）]
        buttonList: [
          {label: '确认', type: "primary"},
          {label: '取消', type: ""}
        ],
        menuList: [],
        expandedKeys: [],
        autoExpandParent: true,
        selectedKeys: [],
        form: {
          positionId: null,
          positionName: null,
          menus: [],
        },
        rules: {
          positionName: [{required: true, message: '岗位名称不能为空', trigger: 'blur'}],
          menus: [{required: true, message: '权限不能为空', trigger: 'change'}],
        },
      };
    },
    props: {},
    computed: {
      isEditMode() {
        return this.mode == '2'
      },
    },
    created() {
      this.form.positionId = this.$route.query.id
      this.form.positionName = this.$route.query.name
      this.mode = this.$route.query.mode
      this.showIcon = (!this.mode || this.mode == '1') ? false : true;
      this.getInit()
      if (this.isEditMode) {
        this.getDetail()
      }
    },
    methods: {
      getInit() {
        getAllMenuRouteList({ moduleType: this.$store.getters.businessType, queryFlag: 'createRole' }).then(data => {
          data.forEach(item => item.menuName = item.meta.title)
          this.menuList = this.$util.listToTree(data)
          console.log("=========>", this.menuList)
        })
      },

      getDetail() {
        PostApi.get(this.form.positionId).then(data => {
          // console.log("================>", data)
          let f = data || {};
          f.menus = f.menus || [];
          this.form = f;
        })
      },

      handleSave() {
        this.$refs['refForm'].validate((valid) => {
          if (valid) {
            console.log("================>", this.form, this.menuList)
            PostApi.save(this.form).then(data => {
              this.$modal.msg("编辑成功")
              this.$router.replace({name: 'PostList', query: {}})
            })
          }
        })
      },

      handleCancel() {
        this.$router.back()
      },

      // onExpand(expandedKeys) {
      //   console.log('onExpand', expandedKeys);
      //   // if not set autoExpandParent to false, if children expanded, parent can not collapse.
      //   // or, you can remove all expanded children keys.
      //   this.expandedKeys = expandedKeys;
      //   this.autoExpandParent = false;
      // },
      // onCheck(checkedKeys) {
      //   console.log('onCheck', checkedKeys);
      //   this.form.menus = checkedKeys;
      // },
      // onSelect(selectedKeys, info) {
      //   console.log('onSelect',selectedKeys, info);
      //   // this.selectedKeys = selectedKeys;
      // },
    }
  }
</script>
<style lang="scss" scoped>
  .form-label {
    font-size: 16px;
    font-weight: 600;
  }
</style>
