<!--
@author: binchen
@desc：修改经历、获奖、展览信息
11-展览认证
12-经历认证
13-获奖认证
@date: 2023-04-29 12:17:35
-->
<template>
  <a-modal :title="`修改${title}信息`" width="716px" :visible="visible" :confirm-loading="confirmLoading" @ok="handleOk" @cancel="handleCancel">
    <a-form :form="form" :label-col="{ span: 4 }" :wrapper-col="{ span: 20 }" labelAlign="left">
      <div v-for="(item, index) in list" :key="index">
        <a-form-item :label="`${title}信息`">
          <div class="box" v-if="type === 12 || type === 13">
            <range-picker :mode="['year', 'year']" :format="['YYYY', 'YYYY']" style="width: 420px; margin-right: 20px" v-model="item.date" @panelChange="(e) => handlePanelChange(item, e)" @open="handleOpen" :placeholder="['选择开始年份', '选择结束年份']"></range-picker>
            <a-input :placeholder="`请输入${title}`" v-model="item.info1" />
          </div>
          <div class="box" v-else>
            <date-picker mode="year" style="width: 420px; margin-right: 20px" format="YYYY" v-model="item.date" @panelChange="(e) => handlePanelChange(item, e)" placeholder="选择年份"></date-picker>
            <a-input style="margin-right: 20px" :placeholder="`请输入展馆名称`" v-model="item.info1" />
            <a-input :placeholder="`请输入展览城市`" v-model="item.info2" />
          </div>
        </a-form-item>
        <a-form-item :colon="false">
          <div slot="label">
            <div>这段{{ title }}证明图：</div>
            <div style="text-align: center; font-size: 12px;font-weight:normal">(可上传3张)</div>
          </div>
          <Upload :max="3" :showBtn="item.imgs.length<3" class="shopEnvironment_modal" width="100%" height="100%" :ref="'Upload' + index" name="file" @remove="(e) => handleRemove(e, item, index)" :isMore="true" :multiple="true" :showUploadList="true" @success="(e) => uploadSuccess(e, index)">
            <div slot="content">点击上传图片</div>
          </Upload>
        </a-form-item>
      </div>
      <div class="addBtn" @click="add">新增{{ title }}</div>
    </a-form>
    <div class="footer" v-if="type === 12">提交经历信息，会在介绍页好看鸭认证中展示您的经历</div>
    <div class="footer" v-if="type === 11">提交展览信息，会在介绍页好看鸭认证中展示您的展览信息</div>
    <div class="footer" v-if="type === 13">提交获奖名称，会在介绍页好看鸭认证中展示您的获奖资料</div>
  </a-modal>
</template>

<script>
import moment from 'moment'
import { DatePicker } from 'ant-design-vue'
import Upload from '@/components/Upload'
export default {
  name: 'ExperienceModal',
  components: { Upload, DatePicker, RangePicker: DatePicker.RangePicker },
  data() {
    return {
      visible: false,
      confirmLoading: false,
      form: {
        homepageInformation: null,
        shopEnvironment: null,
        shopHistoryInformation: null,
        brandInformation: null,
        shopAddressInformation: null,
        idAuthenticationParam: null,
        realAuthentication: null,
        backgroundPicInformation: null,
        experience: {
          experienceContentList: [],
          type: 11,
        },
        introductionInformation: null,
      },
      list: [],
      type: 12,
    }
  },
  created() {},
  mounted() {},
  computed: {
    title: function () {
      if (this.type === 11) {
        return '展览'
      } else if (this.type === 12) {
        return '经历'
      } else {
        return '获奖'
      }
    },
  },
  methods: {
    moment,
    onShow(form, type, auditState) {
      if (auditState === 0) {
        return this.$message.info('信息审核中耐心等待')
      }
      this.type = type
      this.form.experience.type = type

      this.visible = true
      this.$nextTick(() => {
        if (type === 11) {
          this.form.experience.experienceContentList = form.experiencexhibition.experienceContentList || []
          this.list = this.form.experience.experienceContentList.map((item) => {
            return {
              id: item.id,
              date: item.timePeriod.replace(/年/g, ''),
              info1: item.exhibitionHall,
              info2: item.adress,
              imgs: item.pics.map((item) => {
                return this.ossUrl(item)
              }),
            }
          })
        } else if (type === 12) {
          this.form.experience.experienceContentList = form.experience.experienceContentList || []
          this.list = this.form.experience.experienceContentList.map((item) => {
            return {
              id: item.id,
              date: item.timePeriod.replace(/年/g, '').split('-'),
              info1: item.title,
              info2: null,
              imgs: item.pics.map((item) => {
                return this.ossUrl(item)
              }),
            }
          })
        } else {
          this.form.experience.experienceContentList = form.experienceAward.experienceContentList || []
          this.list = this.form.experience.experienceContentList.map((item) => {
            return {
              id: item.id,
              date: item.timePeriod.replace(/年/g, '').split('-'),
              info1: item.title,
              info2: null,
              imgs: item.pics.map((item) => {
                return this.ossUrl(item)
              }),
            }
          })
        }
        this.$nextTick(() => {
          this.list.forEach((item, index) => {
            console.log(this.$refs[`Upload${index}`][0].fileList, item.pics)
            this.$refs[`Upload${index}`][0].fileList = item.imgs.map((img) => {
              return {
                uid: Math.random() + index,
                name: img.split('.')[1],
                status: 'done',
                url: img,
              }
            })
          })
        })
      })
    },
    handleOk() {
      this.confirmLoading = true
      if (this.type === 11) {
        this.form.experience.experienceContentList = this.list.map((item) => {
          return {
            timePeriod: item.date?item.date + '年':"",
            adress: item.info2,
            exhibitionHall: item.info1,
            id: item.id,
            title: null,
            pics: item.imgs.map(item=>{
              return item.replace('/oss/', '')
            }),
          }
        })
      } else if (this.type === 12 || this.type === 13) {
        this.form.experience.experienceContentList = this.list.map((item) => {
          return {
            timePeriod: item.date?item.date[0] + '年-' + item.date[1] + '年':"",
            adress: null,
            exhibitionHall: null,
            id: item.id,
            title: item.info1,
            pics:  item.imgs.map(item=>{
              return item.replace('/oss/', '')
            }),
          }
        })
      }
      this.$emit('submit', this.form, () => {
        this.confirmLoading = false
        this.handleCancel()
      })
    },
    handleCancel() {
      Object.assign(this.$data, this.$options.data.call(this))
      this.visible = false
    },
    uploadSuccess(e, index) {
      this.$refs[`Upload${index}`][0].fileList.push({
        uid: Math.random(),
        name: e.split('.')[1],
        status: 'done',
        url: this.ossUrl(e),
      })
      this.list[index].imgs.push(e)
    },
    add() {
      this.list.push({
        id: null,
        date: null,
        info1: '',
        info2: '',
        imgs: [],
      })
    },
    handlePanelChange(item, e) {
      if (this.type === 12 || this.type === 13) {
        item.date = [e[0].startOf('year').format('YYYY'), e[1].startOf('year').format('YYYY')]
      } else if (this.type === 11) {
        item.date = moment(e).format('YYYY')
      }

      console.log(e, item)
    },

    handleOpen(open) {
      if (open) {
        this.$nextTick(() => {
          const yearPicker = document.querySelector('.ant-picker-year-panel')
          if (yearPicker) {
            yearPicker.parentNode.style.display = 'none'
          }
        })
      }
    },
    handleRemove(e, item, index) {
      item.imgs = item.imgs.filter((img) => img !== e.url)
    },
  },
}
</script>

<style scoped>
.box {
  display: flex;
  align-items: center;
}
.addBtn {
  width: 98px;
  height: 35px;
  line-height: 35px;
  text-align: center;
  border: 1px solid rgba(24, 144, 255, 1);
  color: rgba(24, 144, 255, 1);
  border-radius: 4px;
  cursor: pointer;
}
.footer{
  margin-top: 50px;
  text-align: center;
}
</style>
