<template>
  <div class="olMap-container">
    <div class="positionBox">
      <a-input :placeholder="'请输入' + label" v-model="searchValue" @input="changeLocation" @focus="focus" @blur="blur" />
      <div class="posBox" v-show="showPosBox">
        <div class="pos-item" v-for="(item, index) in pois" :key="index" @click="selectPositionByUser(item)">{{
        item.district + item.name }}</div>
      </div>
    </div>
    <olMap ref="aMapLocationPicker" :location="location" @move="move" />
  </div>
</template>
<script>
import olMap from './index.vue'
export default {
  props: {
    label: {
      type: String,
      default: '',
    },
  },
  components: {
    olMap,
  },
  data () {
    return {
      searchValue: '',
      location: {
        lat: 31.25814757731578,
        lng: 121.64380073547363,
      },
      pois: [],
      showPosBox: false,
    }
  },
  methods: {
    reset () {
      this.searchValue = ''
      this.location = {
        lat: 31.25814757731578,
        lng: 121.64380073547363,
      }
      this.pois = []
      this.showPosBox = false
    },
    move (e) {
      if (typeof e.address === 'string') {
        this.searchValue = e.address
      }

      this.$emit('move', e)
    },
    setCenter (lng, lat) {
      this.$refs.aMapLocationPicker.setCenter([lng, lat], 2)
    },
    changeLocation () {
      this.$refs.aMapLocationPicker.getInputtips(this.searchValue, (pois) => {
        this.pois = pois.filter((item) => item.location && typeof item.location === 'string') || []
        if (this.pois && this.pois.length) {
          this.showPosBox = true
        } else {
          this.showPosBox = false
        }
      })
    },
    selectPositionByUser (item) {
      console.log(item, typeof item.location)
      let location = []
      if (item.location && typeof item.location === 'string') {
        this.$refs.aMapLocationPicker.setCenter(item.location.split(','))
        location = item.location.split(',')
      }

      // this.$refs.aMapLocationPicker.marker.setPosition(item.location.split(','))
      this.$refs.aMapLocationPicker.setZoom(18)
      this.searchValue = item.district + item.name
      this.$emit('select', { adress: this.searchValue, lat: location[1], lng: location[0] })

      this.showPosBox = false
    },
    inputLocation (pois) {
      this.pois = pois
      if (pois.length) {
        this.showPosBox = true
      } else {
        this.showPosBox = false
      }
    },
    focus () {
      if (this.pois.length) {
        this.showPosBox = true
      }
    },
    blur () {
      setTimeout(() => {
        this.showPosBox = false
      }, 300)
    },
  },
}
</script>
<style lang="scss" scoped>
.positionBox {
  position: relative;

  .posBox {
    position: absolute;
    top: 42px;
    left: 0;
    z-index: 100;
    width: 100%;
    box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
    background-color: #ffffff;
    height: 300px;
    overflow: auto;
    cursor: pointer;

    .pos-item {
      padding: 0 20px;
    }

    .pos-item:hover {
      background-color: #ffffff;
    }
  }
}
</style>