<!-- //
文章作品编辑
@auth xuyd
@date 2022-11-01
//-->
<template>
  <div class="layout-vertical">
    <PageHeader :show-icon="showIcon || !!$route.query.contentType" :show-button="false"
      :showConfirm="showConfirm && mode != 999" :data="{ state: form.trendStatus }"></PageHeader>
    <div class="layout-result p24">
      <div style="padding: 0 24px">*请您务必确保所上传的内容，未涉及任何侵权及违反互联网监管协议；您将承担所有法律责任，与此同时平台将保留向您追诉的法律权利</div>
      <a-form-model ref="refForm" :model="form" :rules="rules" layout="vertical" class="layout-form p24">
        <a-form-model-item prop="uploadFileList" style="">
          <span slot="label" class="form-label">封面</span>
          <a-upload-dragger name="file" class="img-preview" accept=".jpeg,.jpg,.png,.gif,.bmp" :multiple="false"
            :show-upload-list="false" :file-list="form.uploadFileList" :before-upload="handleBeforeUpload"
            :remove="handleRemove" @change="handleChange">
            <div class="upload-img" v-if="form.coverUri">
              <img class="img" :src="ossUrl(form.coverUri)" />
            </div>
            <div class="upload-empty" v-else>
              <p class="ant-upload-drag-icon">
                <a-icon type="inbox" style="color: #000000a5" />
              </p>
              <p class="ant-upload-text">
                <span class="upload-text">上传不低于300kb的图片做封面</span>
              </p>
              <p class="ant-upload-hint">
                <span class="upload-hint">支持格式：.jpeg .jpg .png .gif .bmp</span>
              </p>
            </div>
          </a-upload-dragger>
        </a-form-model-item>

        <a-form-model-item prop="title">
          <span slot="label" class="form-label">标题</span>
          <a-input v-model="form.title" class="form-value" placeholder="请输入标题，简要突出内容重点" :maxLength="titleMaxLength"
            size="large">
            <span slot="suffix" style="text-align: right; width: 50px">{{ titleWordLimit }}</span>
          </a-input>
        </a-form-model-item>

        <a-form-model-item prop="content">
          <span slot="label" class="form-label">正文</span>
          <Editor v-model="form.content"></Editor>
        </a-form-model-item>

        <a-form-model-item prop="tabList">
          <span slot="label" class="form-label">
            添加标签
            <span class="tip">标签最多添加6个</span>
          </span>
          <TagSelector v-model="form.tabList" mode="multiple" :limit="6" @change="handleTabChange" class="form-value">
          </TagSelector>
        </a-form-model-item>
      </a-form-model>
    </div>
    <slot name="pageHeader">
      <PageHeader v-if="$route.query.isPreview != 1" :show-icon="showIcon_bottom" :show-button="true" :show-title="false"
        :data="{ state: form.trendStatus }" @publishClick="handlePublish" @saveClick="handleSave"></PageHeader>
    </slot>

    <!-- 选择登录平台dialog -->
    <a-modal v-model="showPlatformChoose" width="590px" :bodyStyle="{ height: '284px' }" :keyboard="false"
      :maskClosable="false" :destroyOnClose="true">
      <div slot="title" class="modal-title">
        <div class="title">发布动态</div>
        <div class="hint">选择您要发布的平台账号</div>
      </div>
      <a-spin wrapper-class-name="global-spin" :spinning="publishLoading">
        <PlatformChoose v-model="selectBusinessList" :data="platformData" />
      </a-spin>
      <div slot="cancelText">取消</div>
      <div slot="okText" @click="handlePlatformChoose" :loading="publishLoading">确定</div>
    </a-modal>
  </div>
</template>

<script>
import * as ArticleApi from '@/api/content/article.api.js'
import Editor from '@/components/Editor/index'
import TagSelector from '@/components/TagSelector/index'
import PlatformChoose from '@/components/PlatformChoose/index'
import * as OssClient from '@/components/AliOss/OssClient'
import { mapGetters } from 'vuex'
export default {
  name: 'ArticleCompEdit',

  components: { Editor, TagSelector, PlatformChoose },
  dictTypes: [
    { dictType: 'contentStatus', dataType: String },
    { dictType: 'contentType', dataType: Number },
    { dictType: 'contentEditCompType', dataType: Number },
    { dictType: 'contentListCompType', dataType: Number },
  ],
  data () {
    let checkContent = (rule, value, callback) => {
      if (this.$util.checkEmptyRichText(value)) {
        callback(new Error('请输入正文'))
      } else {
        callback()
      }
    }
    return {
      publishLoading: false,
      mode: null, // mode=[1(新增）|2（编辑）|3（详情）]
      showPlatformChoose: false, // 选择发布平台显示
      selectBusinessList: [], // 选择发布平台数据
      // selectPlatformData: {id:0},   // 选择发布平台的当前参数
      pageNum: '1', // 从列表进来，返回后保存页码
      showIcon: false, // 从列表进来true，菜单进来false
      showIcon_bottom: false,
      form: {
        id: null,
        contentType: this.contentType || 1, //内容业务分类 1动态2视频3作品4文章
        title: null, // 动态的标题
        trendStatus: 0, // 动态状态 0草稿 100未发布 200已发布
        content: undefined, // 动态内容
        coverUri: null, // 封面目录地址
        uploadFileList: [
          // 上传组件文件列表 { uid: '-1', name: 'image.png', status: 'done', url: '',},
          // {
          //   uid: '-1',
          //   status: 'done',
          //   name: '1664593344602_u20222.png',
          //   url: 'image/202210/1664593344602_u20222.png'
          // }
        ],
        fileList: [], // 接口返回的文件列表 视频文件 {uri,sort,type,appletComprImage，webComprImage, id(动态：内容id是1对多), flag :操作标记(1:新增 2:删除)
        tabList: [], // 动态的兴趣标签 {tabId, tabName}
        productIds: [], // 动态的关联商品
      },
      deleteList: [], // 存储删除的文件，最终和uploadFileList一样merge到fileList发送给后端
      fileUploadLimit: 9, // 文件上传限制个数
      titleMaxLength: 30, // title最大len

      rules: {
        title: [{ required: true, message: '标题不能为空', trigger: 'blur' }],
        content: [
          {
            required: true,
            validator: checkContent,
            trigger: 'blur',
          },
        ],
        uploadFileList: [{ required: true, message: '封面不能为空', trigger: 'change' }],
        // tabList: [{required: true, message: '标签不能为空', trigger: 'change'}],
      },
    }
  },
  props: {
    contentType: Number,
    showConfirm: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    ...mapGetters(['businessType']),
    isAddMode () {
      return this.mode == '1'
    },
    isEditMode () {
      return this.mode == '2' || this.mode == 'preview' || this.mode == '999'
    },
    isDetailMode () {
      return this.mode == '3'
    },
    titleWordLimit () {
      let len = this.form.title ? this.form.title.length : 0
      return len + '/' + this.titleMaxLength
    },
    platformData () {
      return this.form.id
    },
  },
  created () {
    this.form.id = this.$route.query.id
    this.pageNum = this.$route.query.pageNum
    this.mode = this.$route.query.mode
    this.showIcon = !this.mode || this.mode == '1' ? false : true
    this.showIcon_bottom = !this.mode || this.mode == '1' || this.mode == '999' ? false : true
  },
  mounted () {
    if (this.isEditMode) {
      this.getDetail()
    } else {
      setTimeout(() => {
        this.$refs.refForm.resetFields()
      }, 500)
    }
  },

  methods: {
    getDetail () {
      let id = this.form.id
      let params = {
        trendId: id,
        queryType: this.$route.query.queryType || 1,
      }
      ArticleApi.get(params).then((data) => {
        let f = { ...data, uploadFileList: [] }
        f.tabList = f.tabList || []
        f.id = f.id || id
        // 上传组件需要处理图片列表对象
        let uploadFileList = []
        uploadFileList.push(this.convertDbToUpload({ uri: f.coverUri }))
        f.uploadFileList = uploadFileList
        f.content = this.$util.convertToOssPrefixPath(f.content)
        this.form = f
        console.log(this.form)
      })
    },

    /**
     * 把接口返回结果对象转换成upload组件用的对象结构
     * @param dbFileResult api返回结构 {uri,sort:type, type, appletComprImage, webComprImage,id}
     * @return upload组件文件对象
     */
    convertDbToUpload (dbFileResult) {
      let uploadFileObj = {
        uid: dbFileResult.uri, // upload组件记录唯一区分
        url: this.ossUrl(dbFileResult.uri),
        status: 'done',
        name: dbFileResult.fileName ? dbFileResult.fileName : dbFileResult.uri, // 文件名

        uri: dbFileResult.uri,
        fileName: dbFileResult.fileName || dbFileResult.originalName || dbFileResult.uri,
        tempType: dbFileResult.type, // 临时转换存储用
        type: dbFileResult.type == '3' ? 'image/png' : '', // 分类 0其他1文本2视频3图片
        id: dbFileResult.id, // 修改时候的内容id
        flag: this.mode == '999' ? '1' : '3', // db获取出来的默认是3 // flag :操作标记(1:新增 2:删除 3:无需处理)
      }
      return uploadFileObj
    },

    /**
     * 把oss上传的结果对象转换成upload组件用的对象结构
     * @param ossFileResult oss返回结构 {fileName,name:相对路径, url, res:{}}
     */
    convertOssToUpload (ossFileResult) {
      let uploadResult = {
        uid: ossFileResult.path, // upload组件记录唯一区分
        // url: ossFileResult.url, // TODO 这里两种都可以，但是采用同源后，需要使用ossUrl处理下
        url: this.ossUrl(ossFileResult.path),
        status: 'done',
        name: ossFileResult.name, // 上传时候传入的fileName

        fileName: ossFileResult.name,
        uri: ossFileResult.path, // 上传之后的url的相对路径
        sort: 0, // 排序字段(默认就是数字下标排序)
        id: null, // id(动态：内容id是1对多),
        flag: 1, // flag :操作标记(1:新增 2:删除 3:无需处理)
      }
      return uploadResult
    },

    /**
     * 把upload组件用的对象结构转换成db结果对象
     * @param uploadFileResult
     */
    convertUploadToDb (uploadFileResult) {
      let dbResult = {
        id: uploadFileResult.id,
        uri: uploadFileResult.uri,
        originalName: uploadFileResult.fileName, // 上传时候传入的fileName
        fileName: uploadFileResult.fileName, // 上传时候传入的fileName
        sort: 0, // 排序字段(默认就是数字下标排序)
        flag: uploadFileResult.flag, // flag :操作标记(1:新增 2:删除 3:无需处理)
        type: 3, // 分类：0其他1文本2视频3图片
      }
      return dbResult
    },

    /**
     * 上传前处理
     * true =>file.status = 'uploading' 进入onchange
     * false =>file.status = undefined' 进入onchange，执行一次
     * reject() 则停止进入 onchange
     * resolve可以继续onchange
     */
    handleBeforeUpload (file) {
      console.log('handleBeforeUpload', file)
      let retResult = OssClient.validateUploadFile({
        file: file,
        bizType: 'image',
      })
      if (!retResult.state) {
        this.$modal.alertError('上传处理失败：' + retResult.message)
        return false
      }
      // 开始设置上传文件
      let fileName = file.name
      this.form.uploadFileList = [file]
      console.log('【发动态】1.上传图片开始...', file)
      OssClient.uploadFile({
        bizType: 'image',
        type: 'file',
        fileName: fileName,
        file: file,
      })
        .then((result) => {
          console.log('【发动态】2.上传图片完成...', result)
          let fileResult = this.convertOssToUpload(result)
          this.form.uploadFileList = [fileResult]
          this.form.coverUri = fileResult.uri
          console.log('【发动态】2.上传组件图片墙=====>', this.form)
        })
        .catch((err) => {
          console.log('上传处理失败：', err)
          this.$modal.alertError('上传处理失败：' + err)
        })
      return false
    },

    /**
     * 移除文件
     */
    handleRemove (file) {
      console.log('handleRemove', file)
      let index = this.form.uploadFileList.indexOf(file)
      let newFileList = this.form.uploadFileList.slice()
      let removedFileList = newFileList.splice(index, 1)
      this.form.uploadFileList = newFileList
      if (this.$util.isNotEmptyArray(removedFileList)) {
        removedFileList.forEach((item) => {
          if (item.flag == 3) {
            // 只有服务端返回的记录才去删除
            item.flag = 2
            this.deleteList.push(item)
          } else if (item.flag === 1) {
            // 调用oss接口直接删除原始文件
            console.log('删除图片：', item)
            this.$modal.msg('删除图片：' + item.fileName)
          }
        })
      }
    },

    /**
     * 文件变更时间，进行上传处理
     */
    async handleChange ({ file, uploadFileList }) {
      if (!file.status) {
        return
      }
      if (file.status === 'uploading') {
        this.$message.success(`${file.name} 正在上传`)
      } else if (file.status === 'done') {
        this.$message.success(`${file.name} 上传成功`)
      } else if (file.status === 'error') {
        this.$message.error(`${file.name} 文件上传失败`)
      }
    },

    /**
     * tab选择
     */
    handleTabChange (items) {
      console.log('=====>tagSelector', items)
      this.form.tabList = items
    },

    handlePublish () {
      this.$refs['refForm'].validate((valid) => {
        if (valid) {
          if (this.$store.getters.userSession.userPlatType == 9 || this.businessType == 5) {
            // 员工时候不用选择平台直接发布
            this.selectBusinessList = [this.$store.getters.userSession.businessUserId]
            this.handlePlatformChoose()
          } else {
            if (this.mode == 999) {
              this.selectBusinessList = [this.$store.getters.userSession.businessUserId]
              this.handlePlatformChoose()
            } else {
              // 打开dialog
              if (this.$store.getters.userSession.businessList.length > 1) {
                this.showPlatformChoose = true
              } else {
                this.selectBusinessList = [this.$store.getters.userSession.businessList[0].businessUserId]
                this.handlePlatformChoose()
              }
            }
          }
        } else {
          this.$modal.msgError('请补全信息')
        }
      })
    },

    handleSave (data, callback) {
      this.form.trendStatus = 0
      this.handleSaveContent()
        .then((data) => {
          this.$modal.msg('保存草稿成功')
          let compName = this.dict.label.contentListCompType[this.contentType]
          this.$router.replace({
            name: compName,
            query: { pageNum: this.pageNum },
          })
        })
        .catch((err) => {
          if (!this.$util.isValidFalse(err)) {
            callback && callback()
          }
        })
    },

    // 弹框显示选择平台
    handlePlatformChoose () {
      if (!this.selectBusinessList || this.selectBusinessList.length <= 0) {
        this.$modal.msgError('请选择一个平台')
        return
      }

      if (this.publishLoading) return
      this.publishLoading = true
      this.form.businessIds = this.selectBusinessList
      this.form.trendStatus = 200
      this.handleSaveContent()
        .then((data) => {
          this.$modal.msg('发布成功')
          this.publishLoading = false
          this.showPlatformChoose = false
          let compName = this.dict.label.contentListCompType[this.contentType]
          this.$router.replace({
            name: compName,
            query: { pageNum: this.pageNum },
          })
        })
        .catch((err) => {
          this.publishLoading = false
        })
    },

    /**
     * 保存到处理
     */
    handleSaveContent () {
      // debugger
      return new Promise((resolve, reject) => {
        let jsonData = { ...this.form }
        if (this.$util.isNotEmptyArray(jsonData.uploadFileList)) {
          // uploadFileList对象转成fileList
          jsonData.fileList = jsonData.uploadFileList.map((item) => this.convertUploadToDb(item))
          delete jsonData.uploadFileList
          // 设置封面，默认第一张
          jsonData.coverUri = jsonData.fileList[0].uri
        }
        if (this.$util.isNotEmptyArray(this.deleteList)) {
          // 处理删除的记录
          jsonData.fileList = jsonData.fileList.concat(this.deleteList.map((item) => this.convertUploadToDb(item)))
        }

        console.log('表单参数：', jsonData)
        // 转成全部oss全地址
        jsonData.content = this.$util.convertToOssFullUrl(jsonData.content)
        ArticleApi.save(jsonData)
          .then((data) => {
            data && (this.form.id = data)
            resolve(data)
          })
          .catch((err) => {
            reject(err)
          })
      })
    },
  },
}
</script>

<style lang="scss">
.ant-form-vertical .ant-form-item {
  /*padding-bottom: 0;*/
}

.img-preview {
  .ant-upload.ant-upload-drag .ant-upload {
    padding: 0;
    margin: 0;
  }

  .ant-upload.ant-upload-drag {
    background-color: #ffffff;
  }
}
</style>
<style lang="scss" scoped>
.form-label {
  font-size: 16px;
  font-weight: 600;
  color: #333333;
  text-align: left;
  line-height: 24px;

  .tip {
    font-size: 12px;
    font-weight: 300;
  }
}

.form-value {
  min-width: 375px;
  /*max-width: 700px;*/
}

.layout-form {
  max-width: 700px;

  .form-left {
    width: 375px;
  }

  .img-preview {
    width: 235px;
    height: 294px;
    padding: 0;
    margin: 0;
    display: inline-block;
    text-align: center;
    background: #ffffff;

    .upload-img {
      /*width: 100%;*/
      /*height: 100%;*/
      width: 235px;
      height: 294px;
      background-color: #404040;

      .img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }

    .upload-empty {
      width: 100%;
      height: 100%;
      display: inline;
      vertical-align: middle;
    }

    .upload-text {
      font-size: 12px;
      font-weight: 400;
      color: #000000a5;
    }

    .upload-hint {
      font-size: 12px;
      font-weight: 400;
      color: #00000072;
    }
  }

  .form-right {
    flex: 1;
    margin-left: 48px;
  }
}

.modal-title {
  display: flex;

  .title {
    font-weight: 600;
    font-size: 14px;
  }

  .hint {
    margin-left: 10px;
    font-weight: 400;
    font-size: 12px;
    color: #0000006d;
  }
}
</style>
