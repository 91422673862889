<!--
@author: binchen
@desc：修改简介
@date: 2023-04-25 21:56:35
-->
<template>
  <a-modal title="修改简介" :visible="visible" :confirm-loading="confirmLoading" @ok="handleOk" @cancel="handleCancel">
    <a-form :form="form" :label-col="{ span: 2 }" :wrapper-col="{ span: 22 }" labelAlign="left">
      <a-form-item label="简介">
        <div class="textareaBox">
          <textarea v-model="form.introductionInformation.introduction" placeholder="请输入" id="myTextarea" maxlength="100" rows="4" @input="limitInput"></textarea>
          <div class="tip">{{ form.introductionInformation.introduction.length }} / 100</div>
        </div>
      </a-form-item>
    </a-form>
    <div class="footer">简介不能超过4行，字数不能超过100字</div>
  </a-modal>
</template>

<script>
import * as ShopApi from '@/api/shop/shop.api'
export default {
  name: 'ModifyIntroductionModal',
  components: {},
  props: {
    allowAuditState: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      visible: false,
      confirmLoading: false,
      form: {
        homepageInformation: null,
        shopEnvironment: null,
        shopHistoryInformation: null,
        brandInformation: null,
        shopAddressInformation: null,
        idAuthenticationParam: null,
        realAuthentication: null,
        backgroundPicInformation: null,
        experience: null,
        introductionInformation: {
          introduction: '',
        },
      },
    }
  },
  created() {},
  mounted() {},
  methods: {
    onShow(form) {
      if (form.introductionInformation.auditState === 0 && this.allowAuditState) {
        return this.$message.info('信息审核中耐心等待')
      }
      if (form.introductionInformation.introduction.length > 100) {
        this.form.introductionInformation.introduction = form.introductionInformation.introduction.substring(0, 100)
      } else {
        this.form.introductionInformation.introduction = form.introductionInformation.introduction || ''
      }

      this.visible = true
    },
    handleOk() {
      if (this.form.introductionInformation.introduction.length > 100) {
        return this.$message.info('简介不能超过100字！')
      }
      this.confirmLoading = true
      this.$emit('submit', this.form, () => {
        this.confirmLoading = false
        this.visible = false
      })
    },
    handleCancel() {
      this.visible = false
    },
    uploadSuccess(e, type) {
      this.form.brandInformation[type] = e
    },
    limitInput(textarea) {
      // 限制字符数为 100
      const maxLength = 100
      if (textarea.target.value.length > maxLength) {
        textarea.value = textarea.target.value.slice(0, maxLength)
      }

      // 限制行数为 4
      const maxRows = 4
      const rows = textarea.target.value.split('\n').length
      if (rows > maxRows) {
        const lastIndex = textarea.target.value.lastIndexOf('\n')
        textarea.target.value = textarea.target.value.slice(0, lastIndex)
      }
    },
  },
}
</script>
<style>
.align-items {
  display: flex;
  align-items: center;
}
</style>
<style scoped lang="scss">
#myTextarea {
  width: 100%;
  padding-bottom: 10px;
}
.textareaBox {
  position: relative;
  .tip {
    position: absolute;
    bottom: 5px;
    right: 15px;
  }
}
.footer {
  width: 100%;
  text-align: center;
}
</style>
