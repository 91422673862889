<template>
  <div class="card-container">
    <span class="text"><slot name="text">{{text}}</slot></span>
    <span class="hint"><slot name="hint">{{hint}}</slot></span>
  </div>
</template>

<script>
  export default {
    name: 'SimpleCard',
    data() {
      return {
      }
    },

    computed: {
    },
    props: {
      text: String,
      hint: String,
    },
  }
</script>

<style lang="scss" scoped>
  .card-container {
    /*width: 362px;*/
    width: 100%;
    height: 177px;
    display: flex;
    flex-direction: column;
    text-align: center;
    border-radius: 2px;
    border: 1px solid #d9d9d9;

    .text {
      color: #000000;
      font-size: 18px;
      font-weight: 600;
      margin-top: 58px;
    }

    .hint {
      color: #000000;
      font-size: 16px;
      margin-top: 25px;
    }
  }
</style>
