<template>
  <div class="store-manage-container">
    <PageHeader :show-button="false"></PageHeader>
    <div class="content">
      <a-card style="margin-bottom: 20px">

        <a-form-model ref="form" class="ak-query-filter myform" :model="searchForm" :colon="false">
          <a-row :gutter="20">
            <a-col v-bind="$wrapperThreeCol">
              <a-form-model-item label="店铺名称">
                <a-input v-model="searchForm.shopName" placeholder="请输入" />
              </a-form-model-item>
            </a-col>
            <a-col v-bind="$wrapperThreeCol">
              <a-form-model-item label="手机号">
                <a-input v-model="searchForm.phone" placeholder="请输入" />
              </a-form-model-item>
            </a-col>

            <a-col v-bind="$wrapperThreeCol">
              <a-form-model-item label="门店类型">
                <a-select v-model="searchForm.storesType" placeholder="请选择">
                  <a-select-option :value="item.dictValue" v-for="item in $dictMap.STORETYPE" :key="item.dictValue">
                    {{ item.dictText }}
                  </a-select-option>
                </a-select>
              </a-form-model-item>
            </a-col>

          </a-row>
          <a-row class="mt20" :gutter="20">
            <a-col v-bind="$wrapperThreeCol">
              <a-form-model-item label="状态" prop="state">
                <a-select v-model="searchForm.state" placeholder="请选择">
                  <a-select-option :value="item.dictValue" v-for="item in $dictMap.APPLYSTATE" :key="item.dictValue">
                    {{ item.dictText }}
                  </a-select-option>
                </a-select>
              </a-form-model-item>
            </a-col>
            <a-col v-bind="$wrapperThreeCol">
              <a-form-model-item label="入驻时间" prop="date">
                <a-range-picker v-model="date" show-time style="width: 100%" :placeholder="['开始日期', '结束日期']" format="YYYY-MM-DD" valueFormat="YYYY-MM-DD" @change="changeDate" />
              </a-form-model-item>
            </a-col>
            <a-col v-bind="$wrapperThreeCol">
              <a-form-model-item>
                <a-button type="primary" @click="handleSearch">查询</a-button>
                <a-button class="ml20" @click="onResetForm">重置</a-button>
              </a-form-model-item>
            </a-col>
          </a-row>

        </a-form-model>
        <a-button type="primary" class="btn-default w112 mt20" @click="handleAddStore">新增门店</a-button>
      </a-card>

      <a-table :pagination="{
          total: total,
          current: searchForm.page,
          pageSize: searchForm.pageSize, // 每页中显示10条数据
          showSizeChanger: true,
          pageSizeOptions: ['10', '20', '50', '100'], // 每页中显示的数据
          showTotal: (total) => `共有 ${total} 条数据`, // 分页中显示总的数据
        }" :columns="columns" :data-source="tableData" :rowKey="(record) => record.id" @change="changePage" :loading="loading" :scroll="{ x: `calc(100% - 40px)` }">
        <template #logo="text, record">
          <img class="logo_img" :src="ossUrl(record.logo)" />
        </template>
        <template #storesType="text, record">
          <dict-tag :data="$dictMap.STORETYPE" :options="{ valueField: 'dictValue', labelField: 'dictText' }" :value="record.storesType" />
        </template>
        <template #state="text, record">
          <dict-tag :data="$dictMap.APPLYSTATE" :options="{ valueField: 'dictValue', labelField: 'dictText' }" :value="record.state" :style="{color:record.state==0?'red':''}" />
        </template>
        <template #count="text,record">
          <span>{{ text && text>0 && record.state==1?text:"-" }}</span>
        </template>

        <template #personalResp="text, record">
          <div class="personalResp">
            <img class="logo_img" :src="ossUrl(record.personalResp.headImage)" />
            <div class="right">
              <div class="name">{{ record.personalResp.name }}</div>
              <div class="phone">{{ record.personalResp.phone }}</div>
            </div>
          </div>
        </template>
        <template #action="text, record">
          <!-- <router-link class="mr10" :to="`/brandManage/detail?businessUserId=${record.businessUserId}`">详情</router-link> -->
          <a-button v-if="record.state!=0" type="link" @click="handleDetail(record)">详情</a-button>
          <a-button v-if="record.state == 2" type="link" @click="handleApply(record)">重新申请</a-button>
        </template>
      </a-table>
    </div>
    <create-store ref="createStore" @success="handleSearch"></create-store>

  </div>
</template>
<script>
import CreateStore from './createStore.vue'
import StoreModel from '@/api/brandManage/store'

const columns = [
  {
    title: '店铺LOGO',
    dataIndex: 'logo',
    scopedSlots: { customRender: 'logo' },
    width: 100,
  },
  {
    title: '店铺名称',
    dataIndex: 'shopName',
    width: 180,
    ellipsis: true,
  },
  {
    title: '绑定运营人',
    dataIndex: 'personalResp',
    scopedSlots: { customRender: 'personalResp' },
    align: 'center',
    width: 200,
  },
  {
    title: '门店类型',
    dataIndex: 'storesType',
    scopedSlots: { customRender: 'storesType' },
    align: 'center',
    width: 100,
  },
  {
    title: '门店浏览次数',
    dataIndex: 'browseCount',
    width: 120,
    scopedSlots: { customRender: 'count' },
  },
  {
    title: '门店分享次数',
    dataIndex: 'shareCount',
    width: 120,
    scopedSlots: { customRender: 'count' },
  },
  {
    title: '点击联系次数',
    dataIndex: 'contactCount',
    scopedSlots: { customRender: 'count' },
    width: 120,
  },
  {
    title: '得到联系方式次数',
    dataIndex: 'contactSuccessCount',
    scopedSlots: { customRender: 'count' },
    width: 160,
  },
  {
    title: '状态',
    dataIndex: 'state',
    scopedSlots: { customRender: 'state' },
    width: 80,
  },
  {
    title: '绑定时间',
    dataIndex: 'bindTime',
    width: 180,
  },
  {
    title: '操作',
    dataIndex: 'action',
    fixed: 'right',
    scopedSlots: { customRender: 'action' },
    width: 240,
  },
]
export default {
  components: { CreateStore },
  data() {
    return {
      loading: false,
      date: [],
      // 查询参数
      searchForm: {
        page: 1,
        pageSize: 10,
        phone: '',
        shopName: '',
        storesType: undefined,
        state: undefined,
        // userId: this.$store.state.user.userSession.userId,
      },
      columns,

      tableData: [],
      total: 0,
    }
  },
  mounted() {
    this.initData()
  },
  methods: {
    // 表格分页条件、筛选条件、排序条件发生变化后刷新表格数据
    changePage(pagination, filters, sorter) {
      this.searchForm.page = pagination.current
      this.searchForm.pageSize = pagination.pageSize
      this.initData()
    },
    // 加载数据方法
    initData() {
      StoreModel.list(this.searchForm).then((res) => {
        this.tableData = res.list
        this.total = res.total
      })
    },
    handleSearch() {
      this.searchForm.page = 1
      this.searchForm.pageSize = 10
      this.initData()
    },
    handleDetail(record) {
      this.$router.push({
        path: '/brandManage/detail',
        query: record,
      })
    },
    // 重置
    onResetForm() {
      this.$refs.form.resetFields()
      this.date = []
      this.searchForm = this.$options.data.call(this).searchForm
      this.initData()
    },
    handleApply(record) {
      this.$confirm({
        title: '确认重新通知门店绑定?',
        content: '等待门店审核通过，审核通过后即可查看门店数据，发送素材通知门店领取。',
        okText: '确定',
        cancelText: '取消',
        onOk: () => {
          StoreModel.apply(record.id).then((res) => {
            this.$message.success('操作成功')
            this.initData()
          })
        },
        onCancel() {},
      })
    },
    handleAddStore() {
      this.$refs.createStore.onShow()
    },
    changeDate(e) {
      if (e) {
        this.searchForm.createdTimeStart = e[0]
        this.searchForm.createdTimeEnd = e[1]
      } else {
        this.searchForm.createdTimeStart = ''
        this.searchForm.createdTimeEnd = ''
      }
    },
  },
}
</script>
<style lang="scss" scoped>
.store-manage-container {
  .content {
    padding: 20px;
  }
  .logo_img {
    width: 60px;
    height: 60px;
  }
  .personalResp {
    display: flex;
    align-items: center;
    text-align: right;
    justify-content: center;
    .right {
      margin: 10px;
    }
  }
}
// .ant-form-item {
//   width: 100%;
//   display: flex;
// }
// .ant-form-inline .ant-form-item > .ant-form-item-control-wrapper {
//   flex: 1;
// }
</style>
