<!-- //
操作日志
@auth xuyd
@date 2022-11-01
//-->
<template>
  <div class="layout-vertical" style="font-size: 14px; font-weight: 300;">
    <PageHeader :show-button="false"></PageHeader>
    <div class="layout-search p24-bottom-half">
      <div style="border: 1px solid #efefef" class="">
        <a-form-model ref="refForm"
                      :model="queryParams"
                      layout="inline"
                      class="layout-form p24" :colon="false">
          <a-row class="form-row">
            <a-form-model-item label="员工姓名" prop="userName">
              <a-input v-model="queryParams.userName" style="width: 250px;" :maxLength="20"></a-input>
            </a-form-model-item>
            <a-form-model-item label="联系方式" prop="phone">
              <a-input v-model="queryParams.phone" style="width: 250px;" :maxLength="11"></a-input>
            </a-form-model-item>
            <a-form-model-item label="操作模块" prop="operatorModule" v-if="false">
              <a-select v-model="queryParams.operatorModule" style="width: 250px;">
                <a-select-option v-for="(item,index) in moduleList" :value="item.moduleId" :key="index">
                  {{item.moduleName}}
                </a-select-option>
              </a-select>
            </a-form-model-item>
          </a-row>
          <a-row class="form-row-end">
            <a-form-model-item label="操作时间" prop="dateRange">
              <a-range-picker v-model="queryParams.dateRange" format="YYYY-MM-DD" valueFormat="YYYY-MM-DD"/>
            </a-form-model-item>
            <a-form-model-item>
              <a-button type="primary" :ghost="true" @click="handleQuery">查询</a-button>
              <a-button style="margin-left: 20px" @click="handleReset">重置</a-button>
            </a-form-model-item>
          </a-row>
        </a-form-model>
      </div>
    </div>

    <div class="layout-result p24-top-half">
      <a-table :pagination='false'
               :columns="columns"
               :data-source="list"
               :scroll="{ x: '1000px' }"
               :rowKey="(record) => record.id"
      >
        <span slot="operatorTime" slot-scope="text">
          <span class="table-normal">{{$util.parseTime(text, '{y}-{m}-{d} {h}:{i}')}}</span>
        </span>
      </a-table>
      <pagination :total="total" :pageNum.sync="queryParams.page" :pageSize.sync="queryParams.pageSize" @pagination="getList"/>

    </div>
    <BackTop/>
  </div>
</template>
<script>
  import * as LoggerApi from '@/api/logger/logger.api'

  const columns = [
    {
      title: '操作时间', dataIndex: 'operatorTime', width: 150, align: 'center',
      scopedSlots: {customRender: 'operatorTime'},
      sorter1: (a, b) => {
        let ta = (a.operatorTime && new Date(a.operatorTime).getTime()) || 0
        let tb = (b.operatorTime && new Date(b.operatorTime).getTime()) || 0
        let ret = ta - tb
        return ret
      },
    },
    {title: '员工姓名', dataIndex: 'userName', width: 110, align: 'center',},
    {title: '员工角色', dataIndex: 'role', width: 110, align: 'center',},
    {title: '联系方式', dataIndex: 'phone', width: 130, align: 'center',},
    // {title: '操作模块', dataIndex: 'operatorModule', width: 200, align: 'center',},
    {title: '操作模块', dataIndex: 'modules', width: 200, align: 'center',},
    // {title: '操作记录', dataIndex: 'operatorModule', width: 200, align: 'center',},
    {title: '操作记录', dataIndex: 'opeAction', width: 200, align: 'center',},
  ]

  export default {
    name: 'OptLogList',
    components: {},
    dictTypes: [
      {dictType: 'module', dataType: Number},
    ],
    data() {
      return {
        loading: false,
        queryParams: {
          userName: null,
          phone: null,
          operatorModule: null,
          dateRange: [], //['2022-01-09','2022-11-03'],
          operatorStart: null,
          operatorEnd: null,
          page: 1,
          pageSize: 10,
        },
        moduleList: [],

        columns: columns,
        list: [],
        total: 0,
      };
    },
    created() {
      // this.getInit();
      this.getList();
    },
    methods: {
      getInit() {
        LoggerApi.selectModuleList().then(data => {
          this.moduleList = data?.list
        })
      },

      // 列表API获取
      getList() {
        this.queryParams.operatorStart = this.queryParams.dateRange[0] || null
        this.queryParams.operatorEnd = this.queryParams.dateRange[1] || null
        LoggerApi.search(this.queryParams).then(data => {
          // console.log(data)
          this.list = data.list || []
          this.total = data.total || 0
          this.loading = false
        }).catch(err => {
          this.loading = false
        })
      },

      /**
       * 搜索查询
       */
      handleQuery() {
        this.loading = true
        this.queryParams.page = 1;
        this.getList()
      },

      handleReset() {
        this.$refs['refForm'].resetFields()
      },

    }
  }
</script>

<style lang="scss" scoped>

  .form-row {
    margin-bottom: 15px;
  }

  .form-row-end {
    margin-bottom: 0;
  }

  .favorite {
    i {
      font-size: 18px;
      width: 18px;
      height: 18px;
    }

    .text {
      margin-left: 5px;
    }
  }

  .action {
    margin: 0 5px;
  }

  .modal-title {
    display: flex;

    .title {
      font-weight: 600;
      font-size: 14px;
    }

    .hint {
      margin-left: 10px;
      font-weight: 400;
      font-size: 12px;
      color: #0000006d;
    }
  }
</style>
