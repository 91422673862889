<!-- //
动态管理
@auth xuyd
@date 2022-11-01
//-->
<template>
  <div class="layout-vertical" style="font-size: 14px; font-weight: 300">
    <PageHeader :show-button="false"></PageHeader>
    <div class="layout-search p24">
      <div style="height: 135px; border: 1px solid #efefef" class="p24">
        <a-row :gutter="20">
          <a-col :span="12">
            <a-button type="primary" @click="handleAdd" class="btn-default w112">发{{ dict.label.contentType[queryParams.contentType] }}</a-button>
          </a-col>
          <a-col :span="12">
            <a-input-search v-model="queryParams.keyword" placeholder="请输入标题/标签" class="input-search" @search="handleSearch">
              <a-button slot="enterButton" type="primary" icon="search" class="btn-default w96" :loading="loading">查询</a-button>
            </a-input-search>
          </a-col>
        </a-row>
        <a-row style="margin-top: 20px">
          <a-col :span="12">
            <a-radio-group v-model="wrapStatus" @change="handleQuickSearch">
              <a-radio-button value="A">全部</a-radio-button>
              <a-radio-button value="B">已发布</a-radio-button>
              <!-- <a-radio-button value="C">未发布</a-radio-button> -->
              <a-radio-button value="D">草稿箱</a-radio-button>
            </a-radio-group>
          </a-col>
          <a-col></a-col>
        </a-row>
      </div>
    </div>

    <div class="layout-result p24">
      <a-table :pagination="false" :columns="columns" :data-source="list" :scroll="{ x: '1000px' }" :rowKey="(record) => record.id" @change="handleTableChange">
        <span slot="coverUri" slot-scope="text, record">
          <a-avatar slot="avatar" size="large" shape="square" :src="getThumbUrl(record)" style="width: 98px; height: 98px" />
        </span>
        <span slot="trendTitle" slot-scope="text">
          <span class="table-title">{{ text }}</span>
        </span>
        <span slot="tabNames" slot-scope="text">
          <div style="text-align: left" class="table-normal">
            <li v-for="(item, index) in text && text.split(',')" :key="index" style="margin: 5px">
              {{ item }}
            </li>
          </div>
        </span>
        <template slot="products" slot-scope="text">
          <div style="text-align: left" class="table-normal">
            <a-tag v-for="(item, index) in text" :key="index" style="margin: 5px">{{ item }}</a-tag>
          </div>
        </template>
        <span slot="pubPlatformList" slot-scope="text, record">
          <template v-if="text && text.length > 0">
            <div v-for="(item, index) in text" v-bind:key="index" class="table-row">
              <dict-tag :data="dict.type.contentStatus" :options="{}" :value="item.status" style="min-width: 60px" />
              <span style="margin-left: 10px">{{ item.shopName + `(${dict.label.platformType[item.businessType]})` }}</span>
            </div>
          </template>
          <template v-else>
            <dict-tag :data="dict.type.contentStatus" :options="{}" :value="record.status" style="min-width: 60px" />
          </template>
        </span>
        <span slot="favoriteData" slot-scope="text, record" class="favorite" style="color: #000000d8">
          <div class="table-normal">
            <a-row :gutter="20">
              <a-col span="12">
                <a-icon type="heart" />
                <span class="text">{{ record.like }}</span>
              </a-col>
              <a-col span="12">
                <a-icon type="star" />
                <span class="text">{{ record.favorite }}</span>
              </a-col>
            </a-row>
            <a-row :gutter="20">
              <a-col span="12">
                <a-icon type="eye" />
                <span class="text">{{ record.browse }}</span>
              </a-col>
              <a-col span="12">
                <a-icon type="share-alt" />
                <span class="text">{{ record.share }}</span>
              </a-col>
            </a-row>
          </div>
        </span>
        <span slot="createTime" slot-scope="text">
          <span class="table-normal">{{ $util.parseTime(text, '{y}-{m}-{d} {h}:{i}') }}</span>
        </span>
        <span slot="action" slot-scope="text, record">
          <template>
            <a v-if="isShowAction(record, 'edit')" @click="handleEdit(record)" class="action">编辑</a>
            <a v-if="isShowAction(record, 'publish')" @click="handlePublish(record)" class="action">发布</a>
            <a v-if="isShowAction(record, 'delete')" @click="handleDelete(record)" class="action">删除</a>
          </template>
        </span>
      </a-table>
      <pagination :total="total" :pageNum.sync="queryParams.page" :pageSize.sync="queryParams.pageSize" @pagination="getList" />
    </div>
    <BackTop />

    <!-- 选择登录平台dialog -->
    <a-modal v-model="showPlatformChoose" width="590px" :bodyStyle="{ height: '284px' }" :keyboard="false" :maskClosable="false" :destroyOnClose="true">
      <div slot="title" class="modal-title">
        <div class="title">发布动态</div>
        <div class="hint">选择您要发布的平台账号</div>
      </div>
      <a-spin wrapper-class-name="global-spin" :spinning="publishLoading">
        <PlatformChoose v-model="selectBusinessList" :data="platformData" />
      </a-spin>
      <div slot="cancelText">取消</div>
      <div slot="okText" @click="handlePlatformChoose">确定</div>
    </a-modal>
  </div>
</template>
<script>
import * as TrendApi from '@/api/content/trend.api.js'
import PlatformChoose from '@/components/PlatformChoose/index'
import { mapGetters } from 'vuex'
const columns = [
  {
    title: '封面',
    dataIndex: 'coverUri',
    width: 120,
    scopedSlots: { customRender: 'coverUri' },
  },
  {
    title: '标题',
    dataIndex: 'title',
    width: 200,
    scopedSlots: { customRender: 'trendTitle' },
  },
  {
    title: '标签',
    dataIndex: 'tabNames',
    width: 150,
    scopedSlots: { customRender: 'tabNames' },
  },
  // {title: '关联商品', dataIndex: 'products', width: 350, align: 'center', scopedSlots: {customRender: 'products'}},
  // {status, statusName, shopName}
  {
    title: '发布记录',
    dataIndex: 'pulishedShopLst',
    width: 200,
    align: 'center',
    scopedSlots: { customRender: 'pubPlatformList' },
  },
  {
    title: '喜欢/收藏/预览/分享',
    dataIndex: 'favoriteData',
    width: 170,
    align: 'center',
    scopedSlots: { customRender: 'favoriteData' },
  },
  {
    title: '创建时间',
    dataIndex: 'createTime',
    width: 150,
    align: 'center',
    scopedSlots: { customRender: 'createTime' },
    sorter: (a, b) => {
      let ta = (a.createTime && new Date(a.createTime).getTime()) || 0
      let tb = (b.createTime && new Date(b.createTime).getTime()) || 0
      let ret = ta - tb
      return ret
    },
  },
  {
    title: '操作',
    dataIndex: 'action',
    width: 150,
    align: 'center',
    fixed: 'right',
    scopedSlots: { customRender: 'action' },
  },
]

const statusCheckMap = {
  D: { status: 0 },
  C: { status: 100 },
  B: { status: 200 },
  A: { status: null },
}

const actionShow = {
  delete: [0, 200],
  publish: [0, 100],
  edit: [0, 100, 200],
}

export default {
  name: 'CompList',
  components: { PlatformChoose },
  dictTypes: [
    { dictType: 'contentStatus', dataType: Number },
    { dictType: 'contentType', dataType: Number },
    { dictType: 'contentEditCompType', dataType: Number },
    { dictType: 'contentListCompType', dataType: Number },
    { dictType: 'platformType', dataType: String }, // 平台类型
  ],
  data() {
    return {
      loading: false,
      publishLoading: false,
      queryParams: {
        contentType: this.contentType || 1, //内容业务分类 1动态2视频3作品4文章
        keyword: null, // 动态条件
        status: null, // 全部，0-草稿，1-未发布，2-已发布
        page: 1,
        pageSize: 5,
      },
      showPlatformChoose: false, // 选择发布平台显示
      selectBusinessList: [], // 选择发布平台数据
      platformData: null, // 选择发布平台数据
      wrapStatus: 'A', // status包装类
      columns: columns,
      list: [],
      sortedInfo: {},
      total: 0,
    }
  },
  props: {
    contentType: Number,
  },
  computed: {
    ...mapGetters(['businessType']),
  },
  mounted() {
    this.queryParams.page = +this.$route.query.pageNum || 1
    this.getList()
  },
  methods: {
    // 列表API获取
    getList() {
      TrendApi.search(this.queryParams)
        .then((data) => {
          this.list = data.list || []
          this.total = data.total || 0
          this.loading = false
        })
        .catch((err) => {
          this.loading = false
        })
    },
    handleTableChange(pagination, filters, sorter) {
      // console.log('Various parameters', pagination, filters, sorter);
      this.sortedInfo = sorter
    },

    // 处理image地址
    getThumbUrl(record) {
      return this.ossUrl(record.coverUri)
    },

    // 根据状态显示操作按钮
    isShowAction(record, action) {
      return actionShow[action].includes(record.status)
    },

    /**
     * 搜索查询
     */
    handleSearch() {
      this.loading = true
      this.queryParams.page = 1
      this.getList()
    },
    handleQuickSearch() {
      this.queryParams.page = 1
      this.queryParams.status = statusCheckMap[this.wrapStatus].status
      this.getList()
    },

    /**
     * 新增 mode=[1(新增）|2（编辑）|3（详情）]
     */
    handleAdd() {
      let compName = this.dict.label.contentEditCompType[this.contentType]
      if (this.contentType == 2) {
        compName = 'VideoPreface'
      }
      this.$router.push({ name: compName, query: { mode: '1', id: '' } })
    },
    // 编辑
    handleEdit(record) {
      let compName = this.dict.label.contentEditCompType[this.contentType]
      this.$router.push({
        name: compName,
        query: { mode: '2', id: record.id, pageNum: this.queryParams.page },
      })
    },
    // 删除
    handleDelete(record) {
      this.$modal.confirm(`确定要删除${record.title}的记录吗？`).then(() => {
        TrendApi.del(record.id)
          .then((data) => {
            this.$modal.msg('删除成功')
            this.getList()
          })
          .catch((err) => {
            console.log(err)
          })
      })
    },

    // 弹框显示选择平台
    handlePlatformChoose() {
      if (this.publishLoading) return
      this.publishLoading = true
      let params = {
        trendId: this.platformData,
        businessIds: this.selectBusinessList,
      }
      TrendApi.publish(params)
        .then((data) => {
          this.$modal.msg('发布成功')
          this.showPlatformChoose = false
          this.publishLoading = false
          this.getList()
        })
        .catch((err) => {
          this.publishLoading = false
          console.log(err)
        })
    },

    // 发布
    handlePublish(record) {
      // 设置参数
      this.platformData = record.id
      if (this.$store.getters.userSession.userPlatType == 9 || this.businessType == 5) {
        // 员工时候不用选择平台直接发布
        this.selectBusinessList = [this.$store.getters.userSession.businessUserId]
        this.handlePlatformChoose()
      } else {
        // 打开dialog
        if (this.$store.getters.userSession.businessList.length > 1) {
          this.showPlatformChoose = true
        } else {
          this.selectBusinessList = [this.$store.getters.userSession.businessList[0].businessUserId]
          this.handlePlatformChoose()
        }
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.table-row {
  display: flex;
}

.favorite {
  i {
    font-size: 18px;
    width: 18px;
    height: 18px;
  }

  .text {
    margin-left: 5px;
  }
}

.action {
  margin: 0 5px;
}

.modal-title {
  display: flex;

  .title {
    font-weight: 600;
    font-size: 14px;
  }

  .hint {
    margin-left: 10px;
    font-weight: 400;
    font-size: 12px;
    color: #0000006d;
  }
}
</style>
