<template>
  <div style="border: 1px solid #ccc;z-index: 9">
    <Toolbar
        style="border-bottom: 1px solid #ccc"
        :editor="editor"
        :defaultConfig="toolbarConfig"
        :mode="mode"
    />
    <Editor
        ref="refEditor"
        class="container-editor"
        v-model="html"
        :defaultConfig="editorConfig"
        :mode="mode"
        @onBlur="onBlur"
        @onCreated="onCreated"
    />
  </div>
</template>
<script>
  import Vue from 'vue'
  import '@wangeditor/editor/dist/css/style.css'
  import {Editor, Toolbar} from '@wangeditor/editor-for-vue'
  import * as OssClient from "@/components/AliOss/OssClient";

  export default Vue.extend({
    name: 'RichEditor',
    components: {Editor, Toolbar},
    data() {
      return {
        editor: null,
        toolbar: null,
        html: this.value,
        toolbarConfig: {
          excludeKeys: [
            // 'fullScreen',
            'group-video',
            // 'undo',
            // 'redo',
            'fontFamily',
            'codeBlock',
            'emotion',
          ]
        },
        editorConfig: {
          placeholder: '请输入内容...',
          MENU_CONF: {
            fontFamily: {
              fontFamilyList: [
                {name: '思源黑体', value: 'Source Han Sans CN'}
              ]
            },
            emotion: {
              emotions: '😀 😃 😄 😁 😆 😅 😂 🤣 😊 😇 🙂 🙃 😉'.split(' ') // 数组
            },
            uploadImage: {
              customUpload(file, insertFn) {
                let fileName = file.name
                console.log('富文本开始上传图片=====>', fileName);
                OssClient.uploadFile({
                  bizType: 'image',
                  type: 'file',
                  fileName: fileName,
                  file: file
                }).then(result => {
                  console.log('富文本上传图片完成=====>', result);
                  let {name, url, path} = result
                  let alt = name
                  // 解决同源问题，去除域名前缀
                  // 在Nginx配置当前/oss/前缀的不走同源策略
                  url = OssClient.ossUrl(path)
                  insertFn(url, alt, null)
                })
              }
            }
          },
        },
        mode: 'default', // or 'simple'
      }
    },
    model: {
      prop: 'value',
      event: 'blur',
    },
    props: {
      value: String,
    },
    watch: {
      value: {
        handler(val) {
          console.log('==========watch value======', val)
          this.html = val
        }
      },
      html:{
        handler(val) {
          // 如果是指靠blur事件，当直接点击按钮时候，数据不会绑定到外部对象上，导致没有更新到。
          // 所以这里需要监听内部变化，来再次实时触发外部变更，即使直接点击按钮，此时数据已经变化可以放心使用。
          console.log('==========watch html======', val)
          this.$emit('blur', val)
        }
      }
    },
    methods: {
      onCreated(editor) {
        this.editor = Object.seal(editor) // 一定要用 Object.seal() ，否则会报错
        // this.toolbar = DomEditor.getToolbar(this.editor)
        // console.log("===>", this.editor.getAllMenuKeys())
        // console.log("==toolbar==>", toolbar.getConfig())
      },
      onBlur(editor) {
        // console.log('==========html======', this.html)
        this.$emit('blur', this.html)
      },
    },
    created() {
      this.$nextTick(() => {
        let elEditor = this.$refs.refEditor;
        let h = elEditor.offsetHeight;
        console.log("-------editor-------->",elEditor, h)
      })
    },
    beforeDestroy() {
      const editor = this.editor
      if (editor == null) return
      editor.destroy() // 组件销毁时，及时销毁编辑器
    }
  })
</script>

<style lang="scss" scoped>
  .container-editor {
    min-height: 400px;
    overflow-x: hidden;
    overflow-y: auto;
  }
</style>

<style lang="scss">
  .container-editor {
    .w-e-text-container {
      min-height: 400px;
      .w-e-scroll{
        min-height: 400px;
        #w-e-textarea-1 {
          min-height: 400px;
        }
      }
    }
  }
</style>
