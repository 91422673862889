<template>
  <div class="dlg-platform-container">
    <a-row class="row" :gutter="24">
      <a-col :span="12" v-for="(item, index) in businessList" :key="index">
        <a-card hoverable class="card" :class="selectBusinessList && selectBusinessList.includes(item.businessUserId) ? 'active' : ''"
                @click="handleSelectPlatform(item)">
          <a-card-meta :title="item.shopName">
            <span slot="description">
              <div style="display: flex;">
                <DictTag :data="dict.type.publishStatus"
                         :value="checkStatusByPlatform(item.businessUserId)"
                         :options="{suffix: `(${dict.label.platformType[item.businessType]})`}"></DictTag>
              </div>
            </span>
            <a-avatar slot="avatar" :size="40" style="background-color:#F0F0F0">
              <a-icon type="shop" style="font-size:27px; color: #1E1E1E"/>
            </a-avatar>
          </a-card-meta>
        </a-card>
      </a-col>
    </a-row>
  </div>
</template>

<script>
  import {queryPublishedPlatform} from '@/api/content/trend.api'

  import {mapGetters} from 'vuex'

  export default {
    name: 'PlatformChoose',
    dictTypes: [
      {dictType: 'publishStatus', dataType: Number},
      {dictType: 'platformType', dataType: String}, // 平台类型
    ],
    data() {
      return {
        // {"companyName": "向上一区","shopName": "xuyd创作者平台","businessUserId": "202200100000411","pulishStatus": 0},
        publishBusinessList: [], // 发布的平台
      };
    },
    props: {
      data: [String, Number],
      mode: {
        type: String,
        default: 'multiple', // multiple|single
      },
      selectBusinessList: {
        type: Array,
        default: () => {
          return []
        }
      },
    },
    model: {
      prop: 'selectBusinessList',
      event: 'change',
    },
    computed: {
      ...mapGetters([
        'businessList'
      ]),
    },
    mounted() {
      this.getInit()
    },

    methods: {
      getInit() {
        if (!this.data) {
          this.publishBusinessList = []
        } else {
          queryPublishedPlatform(this.data).then(data => {
            this.publishBusinessList = data;
          })
        }
      },

      /**
       * 判断是否check(如果使用登录时候平台选择，则需要使用二者merge判断，如果都使用该接口，服务端已经merge（但是不足）则直接判断即可。）
       */
      checkStatusByPlatform(bizUserId) {
        if (this.publishBusinessList && this.publishBusinessList.length > 0) {
          let r = this.publishBusinessList.some(item => {
            return (item.businessUserId == bizUserId && item.pulishStatus == 1)
          })
          return r ? 1 : 0
        }
        return 0;
      },

      // 如果单选：则数组中只能放一个，再次点击不取消
      // 如果多选，则数组中放多个，如果再次点击相同则取消
      handleSelectPlatform(item) {
        let index = this.selectBusinessList.indexOf(item.businessUserId);
        if (index < 0) {
          this.selectBusinessList.push(item.businessUserId);
        } else {
          this.selectBusinessList.splice(index, 1)
        }
        this.$emit('change', this.selectBusinessList)
      },

      /**
       * 确定选择
       */
      handleSubmitForm() {
        if (!this.businessUserId) {
          this.$modal.msgError("选择您需切换的平台")
        } else {
          // 调用接口
          // this.$store.dispatch('doGetUserSession').then(() => {
          this.$emit('handleOk', {name: this.businessUserId})
          // });
        }
      },
    },
  };
</script>

<style>
  .dlg-platform-container .ant-card-meta-title {
    font-size: 12px;
    font-weight: 600;
  }

  .dlg-platform-container .ant-card-meta-description {
    color: #1E1E1E;
    font-size: 12px;
    font-weight: 400;
  }

</style>
<style scoped lang="scss">
  .dlg-platform-container {
    height: 100%;
    padding: 10px 15px;
    overflow: auto;

    .row {
      .card {
        margin: 10px 0;
        height: 85px;
        border: 1px solid #F5F5F5;
        box-shadow: 0px 2px 9px rgba(184, 184, 184, 0.12);
        border-radius: 8px;
      }

      .active {
        background: #FFFFFF;
        border: 0.83px solid #1E1E1E;
        box-shadow: 0px 4px 12px 2px rgba(143, 143, 143, 0.21);
        border-radius: 8px;
      }
    }
  }

</style>
