<template>
  <div class="createStore">
    <a-modal v-model="visible" :footer="false" class="mymodal" :width="590">
      <div class="title" slot="title">
        <h4>新增门店</h4>
        <span>搜索门店手机号</span>
      </div>
      <div class="content">
        <a-input v-model="phone" style="margin-top: 50px;width: 442px;height: 50px" placeholder="请输入门店管理员手机号" />
        <a-button style="margin: 80px 0 50px 0;width: 250px" class="mt20" block type="primary" @click="handleSearch">查询</a-button>
      </div>
    </a-modal>
    <store-info-sure ref="storeInfoSure" @success="handleSuccess"></store-info-sure>
    <add-store ref="addStore" @success="handleSuccess"></add-store>
  </div>
</template>
<script>
import StoreModel from '@/api/brandManage/store'
import StoreInfoSure from './storeInfoSure.vue'
import AddStore from './addStore.vue'
export default {
  components: { StoreInfoSure, AddStore },
  data() {
    return {
      visible: false,
      phone: '',
      sureVisible: false,
    }
  },
  methods: {
    onShow() {
      this.phone = ''
      this.visible = true
    },
    handleSearch() {
      const regex = /^1[3-9]\d{9}$/
      if (!regex.test(this.phone)) {
        this.$warning({
          title: '您的手机号格式输入错误！',
          content: '请重新输入',
        })
      } else {
        StoreModel.queryByPhone({ phone: this.phone }).then((data) => {
          if (data.length) {
            this.$refs.storeInfoSure.onShow(this.phone)
          } else {
            this.$refs.addStore.onShow(this.phone)
          }
          this.visible = false
        })
      }
    },
    handleSuccess() {
      this.$emit('success')
    },
  },
}
</script>
<style lang="scss" scoped>
.title {
  display: flex;
  align-items: center;
  h4 {
    font-weight: bold;
    margin: 0 20px 0 0;
  }
  span {
    font-size: 12px;
  }
}
.content {
  text-align: center;
}
</style>
