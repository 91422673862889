<template>
  <div class="material-publishVideo-container">
    <video-preface ref="trendPublish" @toPush="push" pushType="brand"></video-preface>
  </div>
</template>
<script>
import VideoPreface from '@/views/content/video/video-preface.vue'
export default {
  components: { VideoPreface },
  data() {
    return {}
  },
  methods: {
    push(jsonData) {
      this.$router.push({ name: 'MaterialPublishVideo_push', query: jsonData })
    },
  },
}
</script>
<style lang="scss" scoped>
.material-publishVideo-container {
  height: 100%;
}
.action {
  width: 96px;
  height: 40px;
  font-size: 16px;
}
</style>