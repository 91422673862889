<template>
  <div class="dlg-platform-container">
    <div class="header">
      <div class="title">选择平台</div>
      <div class="desc">选择您需切换的平台将跳转至平台主页</div>
    </div>
    <div class="body">
      <a-row class="row" :gutter="24">
        <a-col :span="12" v-for="(item, index) in businessList" :key="index">
          <a-card hoverable class="card" :class="activeKey == item.businessUserId ? 'active' : ''" @click="handleSelectPlatform(item)">
            <a-card-meta :title="item.shopName" :description="dict.label.businessType[item.businessType]">
              <a-avatar slot="avatar" :size="56" style="background-color:#F0F0F0">
                <a-icon type="shop" style="font-size:27px; color: #1E1E1E"/>
              </a-avatar>
            </a-card-meta>
          </a-card>
        </a-col>
      </a-row>
    </div>
    <div class="footer">
      <a-button type="primary" class="btn-ok" @click="handleSubmitForm()">确认</a-button>
    </div>
  </div>
</template>

<script>
  import {setBusinessUser} from '@/utils/auth.js'

  import {mapGetters} from 'vuex'

  export default {
    name: 'PlatformChoose',
    dictTypes: [
      {dictType: 'businessTag', dataType: String}, // 平台标记
      {dictType: 'businessType', dataType: String}, // 平台类型
    ],
    data() {
      return {
        activeKey: null,
        businessType: null,
      };
    },
    computed: {
      ...mapGetters([
        'businessList'
      ]),
      // businessList() {
      //   // return this.$store.getters.businessList.concat(this.$store.getters.businessList)
        // return this.$store.getters.businessList
      // }
    },
    mounted() {
    },
    methods: {
      handleSelectPlatform(item) {
        this.activeKey = item.businessUserId;
        this.businessType = item.businessType;
        console.log("选择平台：" + this.activeKey)
      },

      /**
       * 确定选择
       */
      handleSubmitForm() {
        if (!this.activeKey) {
          this.$modal.msgError("选择您需切换的平台")
        } else {
          // 选择好平台，保存businessUserId到cookie中，用于路由跳转判断
          setBusinessUser({businessUserId: this.activeKey, businessType: this.businessType})
          // 调用接口
          // this.$store.dispatch('doGetUserSession').then(() => {
          this.$emit('handleOk', {name: this.activeKey})
          // });
        }
      },
    },
  };
</script>

<style>
  .ant-card-meta-title {
    font-size: 18px;
    font-weight: 700;
  }

  .ant-card-meta-description {
    color: #1E1E1E;
    font-size: 14px;
    font-weight: 400;
  }

</style>
<style scoped lang="scss">
  .dlg-platform-container {
    height: 100%;

    .header {
      text-align: center;
      padding: 16px 0;

      .title {
        font-size: 18px;
        font-weight: 700;
        color: #1E1E1E;
      }

      .desc {
        color: #8C8C8C;
        font-size: 16px;
        margin-top: 6px;
      }
    }

    .body {
      height: 360px;
      padding: 30px 20px;
      overflow: auto;
      .row {
        /*padding: 10px 0;*/
      }

      .card {
        margin: 10px 0;
        height: 126px;
        border: 1px solid #F5F5F5;
        box-shadow: 0px 2px 9px rgba(184, 184, 184, 0.12);
        border-radius: 8px;
      }

      .active {
        background: #FFFFFF;
        border: 0.83px solid #1E1E1E;
        box-shadow: 0px 4px 12px 2px rgba(143, 143, 143, 0.21);
        border-radius: 8px;
      }
    }

    .footer {
      text-align: center;
      margin-top: 10px;

      .btn-ok {
        width: 263px;
        height: 39px;
        border-color: #1E1E1E;
        border-radius: 2px;
        background-color: #1E1E1E;
      }
    }
  }
</style>
