<!--
@author: binchen
@desc：修改品牌信息
@date: 2023-04-25 21:56:35
-->
<template>
  <a-modal title="修改品牌信息" :visible="visible" :confirm-loading="confirmLoading" @ok="handleOk" @cancel="handleCancel">
    <a-form :form="form" :label-col="{ span: 5 }" :wrapper-col="{ span: 12 }" labelAlign="left">
      <a-form-item label="品牌LOGO" class="align-items">
        <Upload ref="brandLogoPic" isRound width="66px" height="66px"
          @success="(e) => uploadSuccess(e, 'brandLogoPic')" />
      </a-form-item>
      <a-form-item label="品牌名">
        <a-input v-model="form.brandInformation.brandName" placeholder="请输入" />
      </a-form-item>
      <a-form-item label="商标注册证">
        <Upload ref="brandRegistPic" width="250px" height="150px"
          @success="(e) => uploadSuccess(e, 'brandRegistPic')" />
      </a-form-item>
      <a-form-item label="商标授权书">
        <Upload ref="brandAuthorizePic" width="250px" height="150px"
          @success="(e) => uploadSuccess(e, 'brandAuthorizePic')" />
      </a-form-item>
    </a-form>
    <div class="footer">没有可以不填，具体情况以客服审核为准</div>
  </a-modal>
</template>

<script>
import Upload from '@/components/Upload'
export default {
  name: 'modifyLogoModal',
  components: { Upload },
  props: {
    allowAuditState: {
      type: Boolean,
      default: true,
    },
  },
  data () {
    return {
      visible: false,
      confirmLoading: false,
      form: {
        homepageInformation: null,
        shopEnvironment: null,
        shopHistoryInformation: null,
        brandInformation: {
          brandName: null,
          brandAuthorizePic: null,
          brandLogoPic: null,
          brandRegistPic: null,
        },
        shopAddressInformation: null,
        idAuthenticationParam: null,
        realAuthentication: null,
        backgroundPicInformation: null,
        experience: null,
        introductionInformation: null,
      },
    }
  },
  created () { },
  mounted () { },
  methods: {
    onShow (form) {
      if (form.brandInformation.auditState === 0 && this.allowAuditState) {
        return this.$message.info('信息审核中耐心等待')
      }
      console.log(form)
      this.form.brandInformation.brandName = form.brandInformation.brandName
      this.form.brandInformation.brandAuthorizePic = form.brandInformation.brandAuthorizePic
      this.form.brandInformation.brandLogoPic = form.brandInformation.brandLogoPic
      this.form.brandInformation.brandRegistPic = form.brandInformation.brandRegistPic
      this.visible = true
      this.$nextTick(function () {
        this.$refs.brandAuthorizePic.imageUrl = this.form.brandInformation.brandAuthorizePic
        this.$refs.brandLogoPic.imageUrl = this.form.brandInformation.brandLogoPic
        this.$refs.brandRegistPic.imageUrl = this.form.brandInformation.brandRegistPic
        if (this.form.brandInformation.brandLogoPic) {
          this.$refs.brandLogoPic.fileList = [
            {
              uid: 1,
              name: this.form.brandInformation.brandLogoPic.split('.')[1],
              status: 'done',
              url: this.form.brandInformation.brandLogoPic,
            },
          ]
        }
        if (this.form.brandInformation.brandAuthorizePic) {
          this.$refs.brandAuthorizePic.fileList = [
            {
              uid: 2,
              name: this.form.brandInformation.brandAuthorizePic.split('.')[1],
              status: 'done',
              url: this.form.brandInformation.brandAuthorizePic,
            },
          ]
        }
        if (this.form.brandInformation.brandRegistPic) {
          this.$refs.brandRegistPic.fileList = [
            {
              uid: 3,
              name: this.form.brandInformation.brandRegistPic.split('.')[1],
              status: 'done',
              url: this.form.brandInformation.brandRegistPic,
            },
          ]
        }


        console.log(this.$refs.brandLogoPic.fileList)
      })
    },
    handleOk () {
      this.confirmLoading = true
      let params = { ...this.form }
      params.brandInformation.brandAuthorizePic = params.brandInformation?.brandAuthorizePic?.replace('/oss/', '')
      params.brandInformation.brandLogoPic = params.brandInformation?.brandLogoPic?.replace('/oss/', '')
      params.brandInformation.brandRegistPic = params.brandInformation?.brandRegistPic?.replace('/oss/', '')
      this.$emit('submit', this.form, () => {
        this.confirmLoading = false
        this.handleCancel()
      })
    },
    handleCancel () {
      Object.assign(this.$data, this.$options.data.call(this))
      this.visible = false
    },
    uploadSuccess (e, type) {
      this.form.brandInformation[type] = e
    },
  },
}
</script>
<style>
.align-items {
  display: flex;
  align-items: center;
}
</style>
<style scoped lang="scss">
.footer {
  text-align: center;
}
</style>
