<template>
  <a-modal v-model="visible"
           width="574px"
           :bodyStyle="{'height': '326px'}"
           :keyboard="false"
           :footer="null"
           :maskClosable="false"
           :destroyOnClose="true">
    <div slot="title" class="modal-title">
      <div class="title">修改密码</div>
    </div>
    <a-spin wrapper-class-name="global-spin" :spinning="loading">
      <div class="dlg-platform-container">

        <a-form-model ref="refForm" :model="form" :rules="rules" v-bind="formLayout" :colon="false">
          <a-row class="form-row">
            <a-form-model-item label="原密码" prop="oldPassword">
              <a-input v-model="form.oldPassword" type="password" class="input" placeholder="请输入"></a-input>
            </a-form-model-item>
          </a-row>
          <a-row class="form-row">
            <a-form-model-item label="新密码" prop="newPassword">
              <a-input v-model="form.newPassword" type="password" class="input" placeholder="请输入"></a-input>
            </a-form-model-item>
          </a-row>
          <a-row class="form-row">
            <a-form-model-item label="确认密码" prop="confirmPassword">
              <a-input v-model="form.confirmPassword" type="password" class="input" placeholder="请输入"></a-input>
            </a-form-model-item>
          </a-row>
        </a-form-model>
        <a-row :span="24" class="button-item" style="height: 50px;">
          <a-button type="primary" class="button" @click="handleConfirm">确认</a-button>
          <a-button class="button2" style="margin-left: 20px" @click="handleCancel">取消</a-button>
        </a-row>


      </div>
    </a-spin>
  </a-modal>
</template>

<script>
  import * as AuthApi from '@/api/auth.api'
  import {validatePass, validateNewPass} from "@/views/login/loginUtil";
  import * as Encryptor from '@/utils/encryptor'
  import * as util from "@/utils/util";

  export default {
    name: 'EmployeeSearchComp',
    data() {
      return {
        visible: false,
        formLayout: {
          labelCol: {span: 8},
          wrapperCol: {span: 14},
        },
        loading: false,
        form: {
          oldPassword: null,
          newPassword: null,
          confirmPassword: null,
        },
        rules: {
          oldPassword: [
            {required: true, validator: validatePass, trigger: 'blur'}
          ],
          newPassword: [
            {required: true, validator: validateNewPass, trigger: 'blur'}
          ],
          confirmPassword: [
            {required: true, validator: this.validateCheckPass, trigger: 'blur'}
          ],
        }
      }
    },

    methods: {
      showModal() {
        this.form = {}
        this.visible = true;
      },
      hideModal() {
        this.visible = false;
      },

      /**
       * 用户确认密码校验
       */
      validateCheckPass(rule, value, callback) {
        if (util.isEmpty(value)) {
          callback(new Error('请输入确认密码'));
        }
        if (value !== this.form.newPassword) {
          return callback(new Error('确认密码必须和新密码一致'))
        } else {
          callback();
        }
      },

      /**
       * 取消按钮
       */
      handleCancel() {
        this.visible = false
      },

      /**
       * 确认按钮
       */
      handleConfirm() {
        this.$refs['refForm'].validate((valid) => {
          if (valid) {
            if (this.loading) return
            this.loading = true
            this.form.oldPassword = Encryptor.encrypt(this.form.oldPassword);
            this.form.newPassword = Encryptor.encrypt(this.form.newPassword);
            this.form.confirmPassword = Encryptor.encrypt(this.form.confirmPassword);
            AuthApi.modifyPassword(this.form).then(data => {
              this.loading = false
              this.$emit('handleOk', data)
              this.visible = false
            }).catch(err => {
              this.loading = false
            })
          }
        })
      }
    },
  };
</script>

<style scoped lang="scss">

  .modal-title {
    display: flex;

    .title {
      font-weight: 600;
      font-size: 14px;
    }

    .hint {
      margin-left: 10px;
      font-weight: 400;
      font-size: 12px;
      color: #0000006d;
    }
  }

  .dlg-platform-container {
    height: 100%;
    padding: 10px 15px;
    overflow: auto;


    .input-item {
      margin-top: 30px;
      margin-left: 40px;
    }

    .input {
      font-size: 14px;
      width: 250px;
      height: 40px;
      line-height: 40px;
    }

    .button-item {
      text-align: center;
    }

    .button {
      font-size: 16px;
      width: 250px;
      height: 40px;
      line-height: 40px;
    }

    .button2 {
      font-size: 16px;
      width: 100px;
      height: 40px;
      line-height: 40px;
    }
  }

</style>
