var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"layout-vertical"},[_vm._m(0),_c('div',{staticClass:"layout-function"}),_c('div',{staticClass:"layout-result"},[_vm._m(1),_vm._m(2),_vm._m(3),_vm._m(4),_vm._m(5),_vm._m(6),_c('a-button',{attrs:{"type":"primary"},on:{"click":_vm.handleBack}},[_vm._v("用户数据详情查看")])],1)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"layout-search"},[_c('div',[_c('br')]),_c('div',[_c('br')]),_c('div',[_c('br')]),_c('div',[_c('br')]),_c('div',[_c('br')]),_c('div',[_c('br')])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('br')])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('br')])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('br')])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('br')])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('br')])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('br')])
}]

export { render, staticRenderFns }