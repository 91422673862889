<!--
@author: binchen
@desc：ContentItem
@date: 2023-04-25 20:09:18
-->
<template>
  <div class="contentItem-box">
    <div class="contentItem" :style="{ 'align-items': alignItems ? 'center' : 'initial' }">
      <div class="title" :class="{lineheight: !content}">
        <slot name="title">
          {{ title }}
        </slot>
      </div>
      <div class="placeholderCoontent">
        <div class="placeholder" v-if="!content">
          <slot name="placeholder">
            {{ placeholder }}
          </slot>
        </div>
        <div class="content" v-if="content">
          <slot name="content">
            {{ content }}
          </slot>
        </div>
      </div>
      <div class="btn" v-if="showBtn">
        <slot name="btn">
          <a-button type="link" @click="onEdit">{{ buttonText }}</a-button>
        </slot>
      </div>
      
    </div>
  </div>
</template>

<script>
export default {
  name: 'ContentItem',
  props: {
    title: String,
    content: {
      type: [String, Number, Object, Array, Boolean],
    },
    placeholder: String,
    buttonText: {
      type: String,
      default: '修改',
    },
    alignItems: {
      // content top还是垂直居中 top(默认),center
      type: Boolean,
      default: true,
    },
    showBtn: {
      type: Boolean,
      default: true,
    },
    showLine: {
      type: Boolean,
      default: true,
    },
    width: {
      type: String,
      default: '90px',
    },
  },
  data() {
    return {}
  },
  created() {},
  mounted() {},
  methods: {
    onEdit() {
      this.$emit('onEdit')
    },
  },
}
</script>

<style scoped lang="scss">
.contentItem {
  display: flex;
  justify-content: space-around;
  min-height: 96px;
  border-bottom: 1px solid #e8e8e8;
  margin-bottom: 10px;
  .title {
    width: 100px;
    font-weight: 600;
    font-size: 14px;
    // line-height: 96px;
  }
  .placeholderCoontent {
    flex: 1;
    .placeholder {
      color: #333333;
      line-height: 96px;
    }
  }
  .lineheight{
    line-height: 96px;
  }
  .btn {
    min-width: 80px;
    max-width: 150px;
    display: flex;
    justify-content: right;
  }
}
</style>
