<!-- //
内容-视频发布/编辑页面
@auth xuyd
@date 2022-09-10
//-->
<template>
  <div class="layout-vertical video-publish">
    <PageHeader :show-icon="showIcon || !!$route.query.contentType" :showConfirm="showConfirm && mode != 999"
      :show-button="false"></PageHeader>
    <div class="layout-result">
      <a-form-model ref="refForm" :model="form" :rules="rules" layout="vertical" class="layout-form p48">
        <a-spin :spinning="uploading" :tip="uploadingText">
          <a-form-model-item prop="uploadFileList">
            <span slot="label" class="form-label">上传视频</span>
            <div class="upload-video">
              <a-upload name="file" accept="video/*" :multiple="false" :show-upload-list="false"
                :uploadFileList="form.uploadFileList" :before-upload="handleBeforeUpload" @change="handleChange">
                <div style="display: flex">
                  <a-input v-model="form.fileName" class="upload-name" read-only style="flex: 1" placeholder="请上传视频"
                    size="large" />
                  <a-button size="large">重新上传视频</a-button>
                </div>
              </a-upload>
            </div>
          </a-form-model-item>
        </a-spin>
        <a-form-model-item v-show="true">
          <span slot="label" class="form-label">视频预览</span>
          <video ref="refVideo" width="320" height="240" controls autoplay muted crossOrigin="anonymous" v-if="true">
            <source :src="form.videoUrl" type="video/mp4" />
            您的浏览器不支持 HTML5 video 标签。
          </video>
        </a-form-model-item>
        <a-form-model-item v-show="false">
          <span slot="label" class="form-label">短视频预览</span>
          <video ref="refShortVideo" width="320" height="240" controls autoplay muted crossOrigin="anonymous"
            v-if="true">
            <source :src="ossUrl(form.publicityVideo)" type="video/mp4" />
            您的浏览器不支持 HTML5 video 标签。
          </video>
        </a-form-model-item>

        <a-form-model-item prop="coverUri">
          <span slot="label" class="form-label">
            <span>封面</span>
            <a-popover placement="topLeft" v-if="true">
              <template slot="content">
                <p>
                  重新获取第
                  <a-input-number id="inputNumber" style="margin: 0 3px" v-model="videoTime" :min="0" :max="10"
                    @change="handleChangeCover" />
                  秒视频帧（截取视频帧，最大10秒）
                </p>
              </template>
              <a-button type="link">更换封面</a-button>
            </a-popover>
          </span>
          <!-- <EmptyImg class="preview-img" :src="ossUrl(form.coverUri)">
            <p slot="text">视频封面</p>
          </EmptyImg> -->
          <a-upload name="avatar" list-type="picture-card" class="avatar-uploader" :show-upload-list="false"
            :before-upload="handleBeforeUploadCover">
            <img class="uploader-cover" v-if="form.coverUri" :src="ossUrl(form.coverUri)" alt="avatar" />
            <div v-else>
              <a-icon :type="coverLoading ? 'loading' : 'plus'" />
              <div class="ant-upload-text">点击上传封面</div>
            </div>
          </a-upload>
        </a-form-model-item>
        <a-form-model-item prop="title">
          <span slot="label" class="form-label">标题</span>
          <a-input v-model="form.title" class="form-value" placeholder="请输入标题，简要突出内容重点" :maxLength="titleMaxLength"
            :suffix="titleWordLimit" size="large"></a-input>
        </a-form-model-item>
        <a-form-model-item prop="content">
          <span slot="label" class="form-label">描述</span>
          <TextAreaEx v-model="form.content" class="form-value" style="height: 250px"
            :placeholder="`描述不超过${contentMaxLength}字，含标点和空格`" :maxLength="contentMaxLength" />
        </a-form-model-item>
        <a-form-model-item prop="tabList">
          <span slot="label" class="form-label">
            添加标签
            <span class="tip">标签最多添加6个</span>
          </span>
          <TagSelector v-model="form.tabList" mode="multiple" :limit="6" @change="handleTabChange" class="form-value">
          </TagSelector>
        </a-form-model-item>
      </a-form-model>
    </div>
    <slot name="pageHeader">
      <PageHeader v-if="$route.query.isPreview != 1" :show-icon="showIcon_bottom" :show-button="true"
        :show-title="false" @publishClick="handlePublish" @saveClick="handleSave"></PageHeader>
    </slot>

    <!-- 选择登录平台dialog -->
    <a-modal v-model="showPlatformChoose" width="590px" :bodyStyle="{ height: '284px' }" :keyboard="false"
      :maskClosable="false" :destroyOnClose="true">
      <div slot="title" class="modal-title">
        <div class="title">发布视频</div>
        <div class="hint">选择您要发布的平台账号</div>
      </div>

      <a-spin wrapper-class-name="global-spin" :spinning="publishLoading">
        <PlatformChoose v-model="selectBusinessList" :data="platformData" />
      </a-spin>
      <div slot="cancelText">取消</div>
      <div slot="okText" @click="handlePlatformChoose" :loading="publishLoading">确定</div>
    </a-modal>
  </div>
</template>

<script>
import * as VideoApi from '@/api/content/video.api.js'
// import EmptyImg from '@/components/Empty'
import TagSelector from '@/components/TagSelector'
import * as OssClient from '@/components/AliOss/OssClient.js'
import PlatformChoose from '@/components/PlatformChoose/index'
import * as VideoUtil from '@/utils/VideoUtil'
import { mapGetters } from 'vuex'
/**
 * 获取文件字节码
 *
 * @param file
 * @returns {Promise<unknown>}
 */
function getBase64 (file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => resolve(reader.result)
    reader.onerror = (error) => reject(error)
  })
}

export default {
  name: 'VideoEdit',
  components: { TagSelector, PlatformChoose },
  props: {
    showConfirm: {
      type: Boolean,
      default: true,
    },
  },
  data () {
    return {
      coverLoading: false,
      uploading: false,
      uploadingText: null,
      publishLoading: false,
      mode: null, // mode=[1(新增）|2（编辑）|3（详情）]
      showPlatformChoose: false, // 选择发布平台显示
      selectBusinessList: [], // 选择发布平台数据
      trendId: 0, // 选择发布平台的当前参数
      showIcon: false, // 从列表进来true，菜单进来false
      showIcon_bottom: false,
      // TODO 无法分开两个服务，则在app启动加载
      ffmpeg: this.$ffmpeg,
      // ffmpeg: this.VideoUtil.initFFmpeg(),
      ffmpegLoading: false,
      videoTime: 0,
      form: {
        id: null,
        contentType: 2, // 动态类型 1动态 2视频 3作品 4文章
        trendStatus: 0, // 动态状态 0草稿 100未发布 200已发布
        title: null, // 动态的标题
        content: null, // 动态内容

        fileName: null, // 视频名称
        coverUri: null, // 封面目录地址
        videoUrl: null, // 视频地址
        shortVideoUrl: null, // 短视频地址
        publicityVideo: null, // 宣传视频地址
        uploadFileList: [
          // 上传组件文件列表 { uid: '-1', name: 'image.png', status: 'done', url: '',},
          // {
          //   uid: '-1',
          //   status: 'done',
          //   name: '1664593344602_u20222.png',
          //   url: 'http://dongfang-yz-shanghai.oss-cn-shanghai.aliyuncs.com/image/202210/1664593344602_u20222.png'
          // }
        ],
        fileList: [], // 接口返回的文件列表 视频文件 {fileName, uri,sort,type,appletComprImage，webComprImage, id(动态：内容id是1对多), flag :操作标记(1:新增 2:删除)
        tabList: [], // 动态的兴趣标签 {tabId, tabName}
        productIds: [], // 动态的关联商品
      },
      deleteList: [], // 存储删除的文件，最终和uploadFileList一样merge到fileList发送给后端
      previewVisible: false, // 切换显示empty
      previewImage: '', // 预览图片内容

      fileUploadLimit: 1, // 文件上传限制个数
      titleMaxLength: 30, // title最大len
      contentMaxLength: 100, // 描述最大len
      rules: {
        fileName: [{ required: true, message: '视频不能为空', trigger: 'change' }],
        uploadFileList: [{ required: true, message: '视频不能为空', trigger: 'change' }],
        coverUri: [{ required: true, message: '封面不能为空', trigger: 'blur' }],
        // title: [{required: true, message: '标题不能为空', trigger: 'blur'}],
        content: [{ required: true, message: '描述不能为空', trigger: 'blur' }],
      },
      canvasList: [],
      imgList: [],
    }
  },
  computed: {
    ...mapGetters(['businessType']),
    isAddMode () {
      return this.mode == '1'
    },
    isEditMode () {
      return this.mode == '2' || this.mode == 'preview' || this.mode == '999'
    },
    isDetailMode () {
      return this.mode == '3'
    },
    titleWordLimit () {
      let len = this.form.title ? this.form.title.length : 0
      return len + '/' + this.titleMaxLength
    },
    platformData () {
      return this.form.id
    },
  },
  created () {
    this.mode = this.$route.query.mode
    this.showIcon = !this.mode || this.mode == '1' ? false : true
    this.form.id = this.$route.query.id
    this.showIcon_bottom = !this.mode || this.mode == '1' || this.mode == '999' ? false : true
    VideoUtil.setVideoDataToUpload(this.form, this.$route.query)

    console.log('初始化form数据', this.form)
    console.log('init', this.form)
    if (this.isEditMode) {
      this.getDetail()
    }

    // 加载ffmpeg
    // this.loadFFmpeg()
  },
  methods: {
    /**
     * 详情获取
     */
    getDetail () {
      let id = this.form.id
      let params = {
        trendId: id,
        queryType: this.$route.query.queryType || 1,
      }
      VideoApi.get(params).then((data) => {
        this.form = { ...data, uploadFileList: [] }
        this.form.tabList = this.form.tabList || []
        this.form.id = this.form.id || id

        // 上传组件需要处理图片列表对象
        let uploadFileList = []
        if (this.$util.isNotEmptyArray(this.form.fileList)) {
          let item = this.form.fileList[0]
          if (item.uri) {
            let obj = VideoUtil.convertDbToUpload(item)
            if (this.$route.query.mode == 999) {
              obj.flag = '1'
            }
            // 处理服务端返回空值的数组
            uploadFileList.push(obj)
            this.form.fileName = item.fileName || item.originalName // 文件名
            this.form.videoUrl = this.ossUrl(item.uri)
            this.form.shortVideoUrl = this.ossUrl(item.publicityVideo)
            this.$refs['refShortVideo'].src = this.form.shortVideoUrl
            this.$refs['refVideo'].src = this.form.videoUrl
          }
          this.form.uploadFileList = uploadFileList
        }
        console.log('详情获取后form数据', this.form)
      })
    },

    async loadFFmpeg () {
      if (!this.ffmpeg.isLoaded()) {
        this.ffmpegLoading = true
        console.log('加载ffmpeg开始~')
        await this.ffmpeg.load()
        console.log('加载ffmpeg完成~')
        this.ffmpegLoading = false
      }
    },

    /**
     * 切换封面图
     */
    handleChangeCover () {
      // await this.ffmpeg.run('-i', `${this.testVideo}`, '-y', '-f', 'image2', '-ss', '时间', '-t', '0.001', '新命名.png');
      VideoUtil.handleCoverByOss(this.form.videoUrl, {
        time: this.videoTime * 1000,
        width: 470,
      }).then((coverResult) => {
        console.log('切换封面图', this.videoTime, coverResult)
        this.form.coverUri = coverResult.path
      })
    },
    /**
     * 上传前处理
     * true =>file.status = 'uploading' 进入onchange
     * false =>file.status = undefined' 进入onchange，执行一次
     * reject() 则停止进入 onchange
     * resolve可以继续onchange
     */
    handleBeforeUploadCover (file) {
      console.log('handleBeforeUploadCover', file)
      let retResult = OssClient.validateUploadFile({
        file: file,
        bizType: 'image',
      })
      if (!retResult.state) {
        this.$modal.alertError('上传处理失败：' + retResult.message)
        return false
      }
      // 开始设置上传文件
      let fileName = file.name
      console.log('【发动态】1.上传图片开始...', file)
      OssClient.uploadFile({
        bizType: 'image',
        type: 'file',
        fileName: fileName,
        file: file,
      })
        .then((result) => {
          console.log('【发动态】2.上传图片完成...', result)
          this.form.coverUri = result.path
        })
        .catch((err) => {
          console.log('上传处理失败：', err)
          this.$modal.alertError('上传处理失败：' + err)
        })
      return false
    },
    /**
     * 点击预览
     * @param file
     * @returns {Promise<void>}
     */
    async handlePreview (file) {
      if (!file.url && !file.preview) {
        file.preview = await getBase64(file.originFileObj)
      }
      this.previewImage = file.url || file.preview
      this.previewVisible = true
    },

    /**
     * 上传前处理
     * true =>file.status = 'uploading' 进入onchange
     * false =>file.status = undefined' 进入onchange，执行一次
     * reject() 则停止进入 onchange
     * resolve可以继续onchange
     */
    handleBeforeUpload (file) {
      console.log('handleBeforeUpload', file)
      // if (this.ffmpegLoading) {
      if (!this.ffmpeg.isLoaded()) {
        this.$modal.alertWarning('资源文件加载中...')
        return false
      }
      let retResult = OssClient.validateUploadFile({
        file: file,
        bizType: 'video',
      })
      if (!retResult.state) {
        this.$modal.alertError('上传处理失败：' + retResult.message)
        return false
      }
      // 需要替换的文件记录
      let replacedFile = null
      if (this.$util.isNotEmptyArray(this.form.uploadFileList)) {
        replacedFile = this.form.uploadFileList[0]
      }

      this.form.coverUri = null
      this.form.publicityVideo = null
      this.$refs['refShortVideo'].src = this.form.publicityVideo
      this.form.videoUrl = null
      this.form.shortVideoUrl = null

      // before方法中一定要设置，否则不会change验证
      this.form.uploadFileList = [file]
      this.form.fileName = file.name

      this.uploading = true
      VideoUtil.handleVideo(this.ffmpeg, file)
        .then((jsonData) => {
          this.uploading = false
          // 转成到页面组件存储
          this.handleRemove(replacedFile)
          VideoUtil.setVideoDataToUpload(this.form, jsonData)
          console.log('上传视频后form数据', this.form)
          // 播放短视频
          this.$refs['refShortVideo'].src = this.form.shortVideoUrl
          this.$refs['refVideo'].src = this.form.videoUrl
        })
        .catch((err) => {
          this.uploading = false
          if (err) {
            this.$modal.alertError('上传处理失败：' + err)
          } else {
            this.$modal.alertError('上传处理失败')
          }
        })

      return false
    },

    /**
     * 文件上传处理
     */
    handleChange ({ file, fileList }) {
      console.log('上传处理handleChange===>', file)
      if (!file.status) {
        return
      }
      if (file.status === 'uploading') {
        this.$message.success(`${file.name} 正在上传`)
      } else if (file.status === 'done') {
        this.$message.success(`${file.name} 上传成功`)
      } else if (file.status === 'error') {
        this.$message.error(`${file.name} 文件上传失败`)
      }
    },

    /**
     * 替换文件
     * @param replacedFile
     */
    handleRemove (replacedFile) {
      console.log('handleRemove', replacedFile)
      if (!replacedFile) {
        return
      }
      if (replacedFile.flag == 3) {
        // 只有服务端返回的记录才标记删除
        replacedFile.flag = 2
        this.deleteList.push(replacedFile)
      } else if (replacedFile.flag === 1) {
        // 调用oss接口直接删除原始文件
        this.$modal.msg('更换视频：' + replacedFile.fileName)
      }
    },

    /**
     * tab选择
     */
    handleTabChange (items) {
      this.form.tabList = items
    },

    handlePublish () {
      this.$refs['refForm'].validate((valid) => {
        if (valid) {
          if (this.$store.getters.userSession.userPlatType == 9 || this.businessType == 5) {
            // 员工时候不用选择平台直接发布
            this.selectBusinessList = [this.$store.getters.userSession.businessUserId]
            this.handlePlatformChoose()
          } else {
            if (this.mode == 999) {
              this.selectBusinessList = [this.$store.getters.userSession.businessUserId]
              this.handlePlatformChoose()
            } else {
              // 打开dialog
              if (this.$store.getters.userSession.businessList.length > 1) {
                this.showPlatformChoose = true
              } else {
                this.selectBusinessList = [this.$store.getters.userSession.businessList[0].businessUserId]
                this.handlePlatformChoose()
              }
            }
          }
        } else {
          this.$modal.msgError('请补全信息')
        }
      })
    },

    // 弹框显示选择平台
    handlePlatformChoose () {
      if (!this.selectBusinessList || this.selectBusinessList.length <= 0) {
        this.$modal.msgError('请选择一个平台')
        return
      }

      if (this.publishLoading) return
      this.publishLoading = true
      this.form.businessIds = this.selectBusinessList
      this.form.trendStatus = 200
      this.handleSaveContent()
        .then((data) => {
          this.$modal.msg('发布成功')
          this.publishLoading = false
          this.showPlatformChoose = false
          this.$router.replace({
            name: 'VideoList',
            query: { pageNum: this.pageNum },
          })
        })
        .catch((err) => {
          this.publishLoading = false
        })
    },

    handleSave (data, callback) {
      this.form.trendStatus = 0
      this.handleSaveContent()
        .then((data) => {
          this.$modal.msg('保存草稿成功')
          this.$router.replace({
            name: 'VideoList',
            query: { pageNum: this.pageNum },
          })
        })
        .catch((err) => {
          if (!this.$util.isValidFalse(err)) {
            callback && callback()
          }
        })
    },

    /**
     * 保存到处理
     */
    handleSaveContent () {
      return new Promise((resolve, reject) => {
        let jsonData = { ...this.form }
        if (this.$util.isNotEmptyArray(jsonData.uploadFileList)) {
          // uploadFileList对象转成fileList
          jsonData.fileList = jsonData.uploadFileList.map((item) => VideoUtil.convertUploadToDb(item))
          delete jsonData.uploadFileList
        }
        if (this.$util.isNotEmptyArray(this.deleteList)) {
          // 处理删除的记录
          jsonData.fileList = jsonData.fileList.concat(this.deleteList.map((item) => VideoUtil.convertUploadToDb(item)))
        }

        console.log('表单参数：', jsonData, this.form)
        VideoApi.save(jsonData)
          .then((data) => {
            data && (this.form.id = data)
            resolve(data)
          })
          .catch((err) => {
            reject(err)
          })
      })
    },
  },
}
</script>
<style>
.video-publish .ant-upload.ant-upload-select-picture-card {
  width: 235px;
  height: 294px;
  background-color: #404040;
}

.video-publish .ant-upload.ant-upload-select-picture-card>.ant-upload {
  padding: 0;
}
</style>
<style lang="scss" scoped>
.video-publish {
  .uploader-cover {
    // width: 320px;
    // height: 240px;
    max-width: 235px;
    max-height: 294px;
  }

  .layout-form {
    /*max-width: 700px;*/
    display: flex;
    flex-direction: column;

    .form-label {
      font-size: 16px;
      font-weight: 600;

      .tip {
        font-size: 12px;
        font-weight: 300;
      }
    }

    .form-value {
      min-width: 375px;
    }

    .preview-img {
      width: 235px;
      height: 294px;
    }

    .upload-video {
      margin-top: 8px;

      .upload-name {
        /*max-width: 700px;*/
        height: 40px;
        margin-right: 10px;
      }
    }
  }

  .modal-title {
    display: flex;

    .title {
      font-weight: 600;
      font-size: 14px;
    }

    .hint {
      margin-left: 10px;
      font-weight: 400;
      font-size: 12px;
      color: #0000006d;
    }
  }
}
</style>
