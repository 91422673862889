<!--
@author: binchen
@修改资质实力
@date: 2023-04-29 12:17:35
-->
<template>
  <a-modal title="修改资质实力" width="808px" :visible="visible" :confirm-loading="confirmLoading" @ok="handleOk" @cancel="handleCancel">
    <a-form :form="form" :label-col="{ span: 3 }" :wrapper-col="{ span: 20 }" labelAlign="left">
      <a-form-item label="资质实力图" class="">
        <Upload class="abilityCertification_modal" width="100%" height="100%" ref="Upload" name="file" :isMore="true" :multiple="true" :showUploadList="true" @success="uploadSuccess" @remove="handleRemove">
          <div slot="content">点击上传图片</div>
        </Upload>
      </a-form-item>
    </a-form>
    <div style="text-align: center">不限制资质实力图数量，可以上传多张</div>
  </a-modal>
</template>

<script>
import Upload from '@/components/Upload'
export default {
  name: 'AbilityCertificationModal',
  components: { Upload },
  data() {
    return {
      visible: false,
      confirmLoading: false,
      form: {
        abilityCertification: [],
        homepageInformation: null,
        shopEnvironment: [],
        shopHistoryInformation: null,
        brandInformation: null,
        shopAddressInformation: null,
        idAuthenticationParam: null,
        realAuthentication: null,
        backgroundPicInformation: null,
        experience: null,
        introductionInformation: null,
      },
    }
  },
  created() {},
  mounted() {},
  methods: {
    onShow(form) {
      this.form.abilityCertification = form.abilityCertification || []

      this.visible = true
      this.$nextTick(() => {
        console.log(this.$refs.Upload)
        this.$refs.Upload.fileList = this.form.abilityCertification.map((item) => {
          return {
            uid: Math.random(),
            name: item.split('.')[1],
            status: 'done',
            url: this.ossUrl(item),
          }
        })
      })
    },
    handleOk() {
      this.form.abilityCertification = this.$refs.Upload.fileList.map((item) => {
        return item.url.replace('/oss/', '')
      })
      this.confirmLoading = true
      this.$emit('submit', this.form, () => {
        this.confirmLoading = false
        this.handleCancel()
      })
    },
    handleCancel() {
      Object.assign(this.$data, this.$options.data.call(this))
      this.visible = false
    },
    uploadSuccess(e) {
      console.log(e)
      this.$refs.Upload.fileList.push({
        uid: Math.random(),
        name: e.split('.')[1],
        status: 'done',
        url: this.ossUrl(e),
      })
    },
    handleRemove(e) {
      console.log(e)
    },
  },
}
</script>

<style>
.abilityCertification_modal .ant-upload-list-picture-card .ant-upload-list-item,
.abilityCertification_modal .ant-upload-list-picture-card-container {
  width: 177px;
  height: 169px;
}
.abilityCertification_modal .ant-upload.ant-upload-select-picture-card {
  width: 177px;
  height: 169px;
}
</style>
