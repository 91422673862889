<!--
@author: binchen
@desc：修改主页名称
@date: 2023-04-25 21:56:35
-->
<template>
  <a-modal title="修改主页名称" :visible="visible" :confirm-loading="confirmLoading" @ok="handleOk" @cancel="handleCancel">
    <a-form :form="form" :label-col="{ span: 5 }" :wrapper-col="{ span: 12 }" labelAlign="left">
      <a-form-item label="主页名称">
        <a-input v-model="form.homepageInformation.homepageName" placeholder="请输入主页名称" />
      </a-form-item>
    </a-form>
  </a-modal>
</template>

<script>
import * as ShopApi from '@/api/shop/shop.api'
export default {
  name: 'ModifyHomeGageModal',
  data() {
    return {
      visible: false,
      confirmLoading: false,
      form: {
        homepageInformation: {
          homepageName: null,
        },
        shopEnvironment: null,
        shopHistoryInformation: null,
        brandInformation: null,
        shopAddressInformation: null,
        idAuthenticationParam: null,
        realAuthentication: null,
        backgroundPicInformation: null,
        experience: null,
        introductionInformation: null,
      },
    }
  },
  created() {},
  mounted() {},
  methods: {
    onShow(form) {
      if (form.homepageInformation.auditState === 0) {
        return this.$message.info('信息审核中耐心等待')
      }
      this.form.homepageInformation.homepageName = form.homepageInformation.homepageName
      this.visible = true
    },
    handleOk() {
      this.confirmLoading = true
      this.$emit('submit', this.form, () => {
        this.confirmLoading = false
        this.handleCancel()
      })
    },
    handleCancel() {
      Object.assign(this.$data, this.$options.data.call(this))
      this.visible = false
    },
  },
}
</script>

<style scoped lang="scss"></style>
