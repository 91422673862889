<template>
  <a-modal v-model="visible" okText="绑定门店" class="mymodal" @ok="handleBind" width="1000px">
    <div class="title" slot="title">
      <h4>新增门店</h4>
      <span>确认门店信息</span>
    </div>
    <a-table :pagination="false" :columns="columns" :data-source="tableData" :rowKey="(record, index) => index" @change="changePage" :loading="loading" :row-selection="{ type: 'radio', selectedRowKeys: selectedRowKeys, onChange: onSelectChange }" :customRow="customRow">
      <template #brandLogoPic="text, record">
        <img class="logo_img" :src="ossUrl(record.logo)" />
      </template>
      <template #personalResp="text, record">
        <div class="personalResp">
          <img class="logo_img" :src="ossUrl(record.personalResp.headImage)" />
          <div class="right">
            <div class="name">{{ record.personalResp.name }}</div>
            <div class="phone">{{ record.personalResp.phone }}</div>
          </div>
        </div>
      </template>
    </a-table>
    <div class="title" style="margin-top: 20px">
      <span class="mr10">选择门店类型</span>
      <a-select v-model="form.storesType" style="width: 300px" placeholder="请选择">
        <a-select-option :value="item.dictValue" v-for="item in $dictMap.STORETYPE" :key="item.dictValue">
          {{ item.dictText }}
        </a-select-option>
      </a-select>
    </div>
  </a-modal>
</template>
<script>
const columns = [
  {
    title: '店铺LOGO',
    dataIndex: 'brandLogoPic',
    scopedSlots: { customRender: 'brandLogoPic' },
  },
  {
    title: '店铺名称',
    dataIndex: 'shopName',
  },
  {
    title: '绑定运营人',
    dataIndex: 'personalResp',
    scopedSlots: { customRender: 'personalResp' },
    align: 'center',
  },
  {
    title: '入驻时间',
    dataIndex: 'applyTime',
    scopedSlots: { customRender: 'applyTime' },
    align: 'center',
  },
]
import StoreModel from '@/api/brandManage/store'
export default {
  data() {
    return {
      visible: false,
      columns,
      tableData: [],
      loading: false,
      phone: '',
      pagination: {
        total: 0,
        pageSize: 10, // 每页中显示10条数据
        showSizeChanger: true,
        pageSizeOptions: ['10', '20', '50', '100'], // 每页中显示的数据
        showTotal: (total) => `共有 ${total} 条数据`, // 分页中显示总的数据
      },
      selectedRowKeys: [],
      form: {
        page: 1,
        pageSize: 10,
        storesType: undefined,
      },
    }
  },
  computed: {
    // rowSelection() {
    //   return {
    //     type: 'radio',
    //     selectedRowKeys: this.selectedRowKeys,
    //     onChange: (selectedRowKeys, selectedRows) => {
    //       this.selectedRowKeys = selectedRowKeys
    //     },
    //     getCheckboxProps: record => ({
    //       props: {
    //         disabled: record.name === 'Disabled User', // Column configuration not to be checked
    //       },
    //     }),
    //   }
    // },
  },
  methods: {
    onShow(params) {
      this.form.phone = params
      this.visible = true
      this.initData()
    },
    initData() {
      this.loading = true
      StoreModel.queryByPhone(this.form)
        .then((data) => {
          this.tableData = data
          this.pagination.total = data.length
          this.loading = false
        })
        .catch(() => {
          this.loading = false
        })
    },
    handleBind() {
      if (!this.selectedRowKeys.length) {
        return this.$message.info('请选择一个门店！')
      }
      if (!this.form.storesType) {
        return this.$message.info('请选择一个门店类型！')
      }
      let params = {
        storesType: this.form.storesType,
        storesUserId: this.tableData[this.selectedRowKeys[0]].userId,
      }
      StoreModel.invate(params).then((res) => {
        this.$message.success('新增成功！')
        this.visible = false
        this.$emit('success')
        this.$warning({
          title: '已通知门店品牌需要绑定！',
          content: '等待门店审核通过，审核通过后即可查看门店数据，发送素材通知门店领取。',
        })
      })
    },
    changePage(pagination) {
      this.form.page = pagination.current
      this.form.pageSize = pagination.pageSize
      this.initData()
    },
    onSelectChange(selectedRowKeys) {
      console.log(selectedRowKeys)
      this.selectedRowKeys = selectedRowKeys
    },
    customRow(record, index) {
      return {
        on: {
          // 事件
          click: () => {
            if (this.selectedRowKeys.includes(index)) {
              this.selectedRowKeys = []
            } else {
              this.selectedRowKeys = [index]
            }
          }, // 点击行
        },
      }
    },
    onRowClick(record, index) {
      console.log(record, index)
      this.onSelectChange([index], [record])
    },
  },
}
</script>
<style lang="scss" scoped>
.title {
  display: flex;
  align-items: center;
  h4 {
    font-weight: bold;
    margin: 0 20px 0 0;
  }
  span {
    font-size: 12px;
  }
}
.content {
  text-align: center;
}
.logo_img {
  width: 60px;
  height: 60px;
}
.personalResp {
  display: flex;
  align-items: center;
  text-align: right;
  justify-content: center;
  .right {
    margin: 10px;
  }
}
</style>
