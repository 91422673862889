<template>
  <div class="material-publishVideo-container">
    <video-publish ref="trendPublish" :showConfirm="false">
      <PageHeader v-if="businessType === 5" slot="pageHeader" :show-title="false" :show-icon="false" :show-button="true">
        <div slot="content">
          <a-button type="primary" class="action" @click="handlePublish">发布</a-button>
        </div>
      </PageHeader>
    </video-publish>
    <select-store ref="selectStore"></select-store>
  </div>
</template>
<script>
import VideoPublish from '@/views/content/video/video-publish.vue'
import SelectStore from '../store/selectStore.vue'
import * as VideoUtil from '@/utils/VideoUtil'
import { mapGetters } from 'vuex'
export default {
  components: { VideoPublish, SelectStore },
  data () {
    return {}
  },
  computed: {
    ...mapGetters(['businessType']),
  },
  methods: {
    handlePublish (e) {
      this.$refs.trendPublish.$refs['refForm'].validate((valid) => {
        if (valid) {
          let obj = this.$refs.trendPublish.form
          let params = {
            content: obj.content,
            contentType: obj.contentType,
            coverUri: obj.coverUri,
            fileList: obj.uploadFileList.map((item) => VideoUtil.convertUploadToDb(item)),
            productIds: obj.productIds,
            fileName: obj.fileName,
            tabList: obj.tabList,
            title: obj.title,
            trendStatus: 200,
            videoUrl: obj.videoUrl,
            publicityVideo: obj.publicityVideo,
            shortVideoUrl: obj.shortVideoUrl,
            storesType: obj.storesType,
          }
          if (obj.id) {
            params.id = obj.id
          }
          this.$refs.selectStore.onShow(params)
        }
      })
    },
  },
}
</script>
<style lang="scss" scoped>
.material-publishVideo-container {
  height: 100%;
}

.action {
  width: 96px;
  height: 40px;
  font-size: 16px;
}
</style>