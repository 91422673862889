<template>
  <a-modal v-model="visible" title="查看共享门店" :footer="null" width="1200px">
    <a-form-model ref="form" layout="inline" :model="searchForm">
      <a-form-model-item label="店铺名称" prop="shopName">
        <a-input v-model="searchForm.shopName" placeholder="请输入" />
      </a-form-model-item>
      <a-form-model-item label="手机号" prop="phone">
        <a-input v-model="searchForm.phone" placeholder="请输入" />
      </a-form-model-item>
      <a-form-model-item>
        <a-button type="primary" @click="handleSearch">查询</a-button>
      </a-form-model-item>
    </a-form-model>
    <a-table class="mt20" :pagination="{ pageSize: searchForm.pageSize, current: searchForm.page, total }" :columns="columns" :data-source="tableData" :rowKey="(record, index) => index" @change="changePage" :loading="loading">
      <template #logo="text, record">
        <img class="logo_img" :src="ossUrl(record.logo)" />
      </template>
      <template #storesType="text, record">
        <dict-tag :data="$dictMap.STORETYPE" :options="{ valueField: 'dictValue', labelField: 'dictText' }" :value="record.storesType" />
      </template>
      <template #bindPersonalResp="text, record">
        <div class="personalResp">
          <img class="logo_img" :src="ossUrl(record.bindPersonalResp.headImage)" />
          <div class="right">
            <div class="name">{{ record.bindPersonalResp.name }}</div>
            <div class="phone">{{ record.bindPersonalResp.phone }}</div>
          </div>
        </div>
      </template>
    </a-table>
  </a-modal>
</template>
<script>
const columns = [
  {
    title: '品牌LOGO',
    dataIndex: 'logo',
    scopedSlots: { customRender: 'logo' },
  },
  {
    title: '店铺名称',
    dataIndex: 'shopName',
    ellipsis: true,
  },
  {
    title: '绑定运营人',
    dataIndex: 'bindPersonalResp',
    scopedSlots: { customRender: 'bindPersonalResp' },
    align: 'center',
  },
  {
    title: '门店类型',
    dataIndex: 'storesType',
    scopedSlots: { customRender: 'storesType' },
    ellipsis: true,
    align: 'center',
  },
  {
    title: '是否转发素材',
    dataIndex: 'isTransMaterial',
    scopedSlots: { customRender: 'isTransMaterial' },
    align: 'center',
  },
]
import materialModel from '@/api/brandManage/material'
export default {
  data() {
    return {
      visible: false,
      columns,
      tableData: [],
      loading: false,
      total: 120,
      searchForm: {
        page: 1,
        pageSize: 10,
        phone: '',
        shopName: '',
        materialId: null,
      },
    }
  },
  methods: {
    onShow(params) {
      this.searchForm.userId = this.$store.state.user.userSession.userId
      this.searchForm.materialId = params.id
      this.visible = true
      this.initData()
    },
    initData() {
      this.loading = true
      materialModel
        .shareBrandStoresPage(this.searchForm)
        .then((res) => {
          console.log(res)
          this.tableData = res.list
          this.total = res.total
          this.loading = false
        })
        .catch((error) => {
          this.loading = false
        })
    },
    handleSearch() {
      this.searchForm.page = 1
      this.searchForm.pageSize = 10
      this.initData()
    },
    changePage(e) {
      this.searchForm.page = e.current
      this.searchForm.pageSize = e.pageSize
      this.initData()
    },
  },
}
</script>
<style lang="scss" scoped>
.title {
  display: flex;
  align-items: center;
  h4 {
    font-weight: bold;
    margin: 0 20px 0 0;
  }
  span {
    font-size: 12px;
  }
}
.content {
  text-align: center;
}

.logo_img {
  width: 60px;
  height: 60px;
}
.personalResp {
  display: flex;
  align-items: center;
  text-align: right;
  .right {
    margin: 10px;
  }
}
</style>
